import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Form, Button } from 'antd';
import { GetUserWithdrawLog } from "../../action/authAction";


const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps} >
                {
                    children
                }
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class UserWithdrawLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '提现人名称',
                dataIndex: 'user_name',
                width: '10%',
                align: "center",
            },
            {
                title: '提现金额',
                dataIndex: 'money',
                width: '10%',
                align: "center",
            },
            {
                title: '银行名称',
                dataIndex: 'account_name',
                width: '10%',
                align: "center",
            },
            {
                title: '银行账号',
                dataIndex: 'account',
                width: '10%',
                align: "center",
            },

            {
                title: 'bsb',
                dataIndex: 'bsb',
                width: '10%',
                align: "center",
            },


            {
                title: '处理结果',
                dataIndex: 'sort',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            {
                                record.is_finish === 1 ?
                                    <span style={{ color: "green" }}>已处理</span> :
                                    <div>
                                        {record.is_finish === 3 ? <span style={{ color: "#ff0000" }}>拒绝</span> : <span style={{ color: "blue" }}>审核中</span>}
                                    </div>
                            }
                        </div>

                    );
                },
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {

                    return (
                        <div>
                            <Button onClick={() => this.props.history.push("/userwithdrawLogDetail/" + record.id)}>
                                查看
                            </Button>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        let res = await GetUserWithdrawLog();
        this.setState({ data: res.data.data })
    }




    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <Table
                    rowKey='id'
                    components={components}
                    // bordered
                    dataSource={this.state.data}
                    columns={columns}
                    className="waperBox"
                    rowClassName="editable-row"
                    pagination={{
                        // onChange: this.cancel,
                        hideOnSinglePage: true
                    }}
                />
            </EditableContext.Provider>
        );
    }
}

export default withRouter(Form.create()(UserWithdrawLog))

