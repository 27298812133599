import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, Form, Switch, Button, Modal, Select } from 'antd';
import { getLanguageList, AddLang, deleteLanguage, editLang } from "../../action/authAction";
import { getRegionList } from '../../action/shopAction';

class LangList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            RegionData: "",
            row: '',
            visible: false,
        };
    }

    async componentDidMount() {
        let res = await getRegionList();
        this.setState({ RegionData: res.data.data })
        this.getData()
    }

    getData = () => {
        getLanguageList().then(res => {
            this.setState({ data: res.data.data })
        })

    }


    edit(record) {
        this.setState({ visible: true, row: record });
    }

    async handleDelete(data) {
        await deleteLanguage(data.id);
        this.getData();
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { row } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (row && row.id) {
                    //修改-注意参数
                    editLang(row.id, values).then(res => {
                        this.getData()
                        this.setState({
                            visible: false,
                            row: ''
                        });
                    })
                } else {
                    //新增
                    AddLang(values).then(res => {
                        this.getData()
                        this.setState({
                            visible: false,
                            row: ''
                        });
                    })
                }
            }
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            row: ''
        });
    };


    render() {
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                align: "center",
            },
            {
                title: '语言名称',
                dataIndex: 'name',
                align: "center",
            },
            {
                title: '语言简称',
                dataIndex: 'show_symbol',
                align: "center",
            },
            // {
            //     title: '国家',
            //     dataIndex: ["region", "name"],
            //     align: "center",
            // },
            {
                title: '开启',
                align: "center",
                render: (record) => {
                    return (
                        <Switch
                            disabled
                            checked={record.is_use === 1}
                            onChange={(e) => {
                                editLang(record.id, { "is_use": e ? "1" : "0" }).then(res => {
                                    this.getData()
                                })
                            }}
                        />
                    )
                }
            },

            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     align: "center",
            //     width: "25%",
            //     render: (text, record) => {
            //         return <>
            //             <Button onClick={() => this.edit(record)} style={{ marginRight: "10px" }}>
            //                 编辑
            //             </Button>
            //             <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
            //                 <Button type="danger" ghost>
            //                     删除
            //                 </Button>
            //             </Popconfirm>
            //         </>
            //     },
            // },

        ];

        const { row, RegionData } = this.state;
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };

        return (
            <div>
                {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.setState({ visible: true })}
                    >
                        添加
                    </Button>
                </div> */}
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={columns}
                    className="waperBox"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
                <Modal
                    destroyOnClose
                    title={row.id ? '编辑语言' : '新建语言'}
                    visible={this.state.visible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="waperBox FormView" {...formItemLayout}>
                        {
                            row ? <Form.Item
                                label="选择国家"
                            >
                                {getFieldDecorator('region_id', {
                                    rules: [{ required: true, message: '请选择国家!' }],
                                    initialValue: row && row.region_id
                                })(
                                    <Select>
                                        {RegionData && RegionData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item> : <div>
                                <Form.Item
                                    label="选择国家"
                                >
                                    {getFieldDecorator('region_id', {
                                        rules: [{ required: true, message: '请选择国家!' }],
                                    })(
                                        <Select placeholder="选择国家">
                                            {RegionData && RegionData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>
                        }


                        <Form.Item
                            label="语言名称"
                        >
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: '请输入语言名称!' }],
                                initialValue: row && row.name
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="语言简称"
                        >
                            {getFieldDecorator('symbol', {
                                rules: [{ required: true, message: '请输入语言简称!' }],
                                initialValue: row && row.symbol
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(LangList))

