import React from "react";
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment-timezone';
import { Table, Icon, Form, Button, Switch, Select, Modal, Input, InputNumber, message, Tooltip    } from 'antd';
import { getCouponList, putCoupon } from "../../action/goodAction";
import { getShopList, getShopDetail } from "../../action/shopAction"
import {getLanguageList } from "../../action/authAction";
const { Option } = Select;
class PromotionalGoodsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shopList: "",
            meta: "",
            url: "",
            partner_id: "",
            visible: false,
            sortVisible: false,
            sortValue: "",
            itemData: "",
            closed: "",
            page: 1,
            is_eatmoon_show: false,
            LanguageData: "",
            language_id: 1, 

             url_1:"", 
             url_2:"",
             url_3:"",
             url_4:"", 
             url_5:"",


        };
        this.columns = [
            {
                title: "id",
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            {
                title: "店铺",
                dataIndex: ['partner', 'title'],
                width: '5%',
                align: "center",
            },
            {
                title: "优惠券",
                width: '8%',
                align: "center",
                render: (record) => {
                    return <div style={{ display: "flex", alignItems: "center" }}>

                        { 
                             record.permanent_state === 1 &&  
                             <Icon type="stop" theme="filled" style={{ marginRight: "5px",color:"#ff5722" }} /> 

                        }


                        {
                            record.spu_id === 0 &&
                            <Icon type="shop" style={{ marginRight: "5px" }} />
                        }
                        <p style={{ margin: "0px" }}>{record.name}</p>
                    </div>
                }

            },


            {
                title: "营销类型",
                width: '8%',
                render: (record) => {
                    let cardtype;

                    if (record.type ==  1) {
                        cardtype = "N"

                    } else if (record.type ==  2)  {
                        cardtype = "S"

                    }else if (record.type ==  3) {

                         cardtype = "R"

                    }else if(record.type ==  4) {

                         cardtype = "SSR"
                    }

                    return cardtype;
                } 
            },



            {
                title: "Discount off",
                // dataIndex: 'discount_on_fixed',
                width: '4%',
                align: "center",
                render: (record) => {
                    return record.discount_type === "fixed" ? "$" + record.discount_on_fixed : parseFloat(record.discount_on_percentage) * 100 + "%" + " off"
                }
            },
            {
                title: "库存",
                //dataIndex: 'stock',
                width: '4%',
                align: "center",

                render: (record) => { 
                    let isRed =  ( record.stock === 0) ? true : false ; 
                    let stock = record.stock 
                    return <div style={{ display: "flex", flexDirection: "column" }}> 
                        <span style={{ marginBottom: "5px" ,color: isRed ? "#ff0000" : "" }}>{stock}</span> 
                    </div>
                }


            },
            {
                title: "折扣后价格",
                dataIndex: ["spu", "discount_price"],
                width: '6%',
                align: "center",
                render: (record) => {
                    return record && <span>${record}</span>
                }
            },

            {
                title: "显示概率",
                dataIndex: "show_probability",
                width: '6%',
                align: "center",
                
            },


            {
                title: "活动期间",
                width: '6%',
                align: "center",
                render: (record) => {


                    //let start_at = record.start_at
                    //let end_at = record.end_at

                    let start_at = record.start_at
                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate




                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{start_at.substring(0, start_at.length - 8)}</span>
                        <span>~</span>
                        <span style={{ color: isRed ? "#ff0000" : "" }} >{end_at.substring(0, end_at.length - 8)}</span>
                    </div>
                }
            },



            // {
            //     title: "活动状态",
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
            //                     record.permanent_state === 1 ?
            //                         <span style={{ color: "#ff0000" }}>已终止</span> :
            //                         record.end_status ?
            //                             <span style={{ color: "#ff0000" }}>已结束</span> :
            //                             <span style={{ color: "green" }}>正常</span>
            //                 }
            //             </div>

            //         )
            //     }
            // },


            {
                title: "上架",
                width: '5%',
                align: "center",
                render: (record) => {

                    if(record.permanent_state != 1){
                        let end_at = record.end_at
                        let end_at_year = end_at.split(" ")
                        let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                        let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                        let isRed = end_at_time < curDate
                        return (
                            <Switch
                                //disabled={record.permanent_state === 1 || (isRed && record.state === 0)} 
                                checked={record.state === 1}
                                onChange={() => this.onChange(record)} />
                        )

                    }
                    
                }
            },


            




            {
                title: "平台显示",
                width: '5%',
                align: "center",
                render: (record) => {



                    if(record.permanent_state != 1){

                            let checked = false; 
                            if(record.is_eatmoon_show === 1){
                                checked = true;
                            }


                            if(record.type != 1){ 
                                checked = false; 
                                return "";
                            }else{
                                return  <Switch disabled={  (record.type != 1)} 
                                checked={checked} 
                                onChange={() => this.onEatMoonChange(record)} />

                            }

                    }

                    



                    {/* !record.partner.is_eatmoon_show  || */}
                    
                    
                }
            },




            {
                title: "小月亮显示",
                width: '5%',
                align: "center",
                render: (record) => {


                     if(record.permanent_state != 1){
                            let disabled = false;
                            let checked = false;
                            
                            if(record.is_moon_show === 1){
                                checked = true;
                            }

                            
                            if(record.type == 1){
                                disabled = true;
                                checked = false;
                                return "";
                            }else{
                                 return <Switch 
                                disabled={disabled} 

                                checked={checked} 
                                onChange={() => this.onMoonChange(record)} />

                            } 
                     }
                    

                   
                }
            },





            /*
                title: "是否锁定",
                width: '5%',
                align: "center",
                render: (record) => {
                    return <Switch checked={record.is_lock === 1} onChange={() => this.onLockChange(record)} />
                }
            */

            {
                title: "平台精选",
                width: '4%',
                align: "center",
                render: (record) => {
                   
                        if(record.type == 1 && record.permanent_state != 1 ){
                            return   <div style={{ marginTop: "41px", display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                <span>{record.system_sort}</span>
                                <Button type="link" style={{ padding: "0px", marginTop: "10px" }}
                                    onClick={() => {
                                        this.setState({
                                            sortVisible: true,
                                            itemData: record,
                                            sortValue: record.system_sort
                                        })
                                    }}
                                >编辑</Button>
                            </div>

                    }

                    
                }
            },

            {
                title: "操作",
                width: '4%',
                align: "center",
                render: (record) => {
                    // return record.end_status ?
                    //     null :

                     if( record.permanent_state != 1){   
                        return <div style={{ display: "flex", justifyContent: "center" }}> 
                                <Button style={{ margin: "5px" }}
                                    onClick={() => {
                                        this.setState({
                                            visible: true,
                                            itemData: record
                                        })
                                    }} >
                                    终止
                                </Button> 
                        </div>
                    }else{ 
                         return   <span style={{ color: "red" }}>已终止</span> 
                    }
                    
                },
            },
        ];
    }


    async componentDidMount() {


        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })



        let resp = await getShopList("", "")
        let data = resp.data.data
        console.log(data)
        this.setState({
            shopList: data,
            partner_id: "",//data[0].id //默认显示全部店铺
        }, () => {
            //this.HandleGetData()
        })
    }

    async HandleGetData() {


        let { page, partner_id, url ,language_id} = this.state; 
        let res = await getCouponList(page, partner_id, url || "" ,language_id)


        let shopData = await getShopDetail(partner_id, 1)
        this.setState({
            data: res.data.data,
            is_eatmoon_show: shopData.data.is_eatmoon_show === 1,
            meta: res.data.meta.pagination
        })
    }


    onChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.state = item.state === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    onShowChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_show = item.is_show === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }


    handleOverOff = () => {
        let item = this.state.itemData
        let values = {}
        values.spu_id = item.spu_id
        values.permanent_state = "1"
        putCoupon(values, item.id).then(res => {
            this.setState({
                visible: false,
                closed: ""
            })
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }


    onMoonChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_moon_show = item.is_moon_show === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }



    onEatMoonChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_eatmoon_show = item.is_eatmoon_show === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    onLockChange = (item) => {
        let values = {}
        // values.spu_id = item.spu_id
        values.is_lock = item.is_lock === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }

    render() {
        let { LanguageData,shopList, meta, url, closed, partner_id, sortVisible, sortValue , url_1,url_2,url_3,url_4,url_5} = this.state
        return (
            <div>
                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: 'none', marginBottom: "10px" }}>
                    <div>
                        

                        {
                            LanguageData ? <Select
                                style={{ width: "100px" }}
                                defaultValue={LanguageData[1].name}
                                onChange={(e) => {

                                    console.log("e=========",e); 
                                    this.setState({  language_id:e}, 
                                          () => {  this.HandleGetData(e)    } 
                                    )  
                                    
                                }}
                            >
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select> : <div />
                        }




                        {
                            shopList &&
                            <Select showSearch
                                placeholder="请选择店铺"  
                                style={{ width: 300, marginLeft: "10px", marginRight: "10px" }}
                                onChange={(e, value) => {
                                    this.setState({
                                        partner_id: e,
                                        url: "",
                                        url_1:"",
                                        url_2:"",
                                        url_3:"",
                                        url_4:"",
                                        url_5:"",
                                        page:1,
                                    }, () => this.HandleGetData())

                                }}
                                /*value={partner_id}*/ 
                            >
                                {
                                    shopList.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.title}</Option>
                                    })
                                }
                            </Select>

                        }
                        
                         <Tooltip title={'请选择店铺'}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>


                    </div>
                    <Button type="primary" onClick={() => this.props.history.push("/userCoupon")} style={{ marginRight: "30px", marginLeft: '30px' }}>
                        优惠券日志
                    </Button>
                </div>






                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                       <Button
                            style={{ marginRight: "5px", backgroundColor:  url === "" ? "#1DA57A" : "", color:   url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "",
                                    url_1:"",
                                    url_2:"",
                                    url_3:"",
                                    url_4:"",
                                    url_5:"",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            全部
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&state=1" ? "#1DA57A" : "", color: url_1 === "&state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&state=1" + url_2 +  url_3 + url_4 + url_5 ,
                                   url_1: "&state=1",
                                   page:1,
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            已上架
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&state=0" ? "#1DA57A" : "", color: url_1 === "&state=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                   url: "&state=0"  + url_2 +  url_3 + url_4 + url_5 ,
                                   url_1:"&state=0", 
                                   page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            已下架
                        </Button>

                         

                        

                </div>




                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "10px"}}>

                     <Button
                        style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                        onClick={() => {
                            this.setState({
                                url: ""
                            }, () => {
                                //this.HandleGetData()
                            })
                        }}>
                        平台
                    </Button>


                      

                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_2 === "&is_eatmoon_show=1" ? "#1DA57A" : "", color: url_2 === "&is_eatmoon_show=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                url: "&is_eatmoon_show=1" + url_1 +  url_3 + url_4 + url_5 ,
                                url_2:"&is_eatmoon_show=1",
                                page:1
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        平台显示
                    </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&is_moon_show=1" ? "#1DA57A" : "", color: url_2 === "&is_moon_show=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&is_moon_show=1" + url_1 +  url_3 + url_4 + url_5 ,
                                    url_2:"&is_moon_show=1",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            小月亮显示
                        </Button>







                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&is_system_sort=1" ? "#1DA57A" : "", color: url_2 === "&is_system_sort=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&is_system_sort=1" + url_1 +  url_3 + url_4 + url_5 ,
                                    url_2:"&is_system_sort=1",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            平台精选
                        </Button>


                    





                </div>





                 <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                        
                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A" , color:   "#fff"  }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "",
                                    page:1
                                }, () => {
                                    //this.HandleGetData()
                                })
                            }}>
                            行为
                        </Button>

                        {/*
                         <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&end_status=0" ? "#1DA57A" : "", color: url === "&end_status=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&end_status=0",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            正常
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&end_status=1" ? "#1DA57A" : "", color: url === "&end_status=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&end_status=1",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            已结束
                        </Button>
                        */}



                         <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&is_expire=1" ? "#1DA57A" : "", color: url_3 === "&is_expire=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&is_expire=1" + url_1 +  url_2 + url_4 + url_5 ,
                                    url_3:"&is_expire=1",
                                    page:1

                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已过期
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&is_stock_empty=1" ? "#1DA57A" : "", color: url_3 === "&is_stock_empty=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&is_stock_empty=1" + url_1 +  url_2 + url_4 + url_5 ,
                                    url_3:"&is_stock_empty=1",
                                    page:1
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已领完
                        </Button>


 

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&permanent_state=1" ? "#1DA57A" : "", color: url_3 === "&permanent_state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&permanent_state=1"  + url_1 +  url_2 + url_4 + url_5 ,
                                    url_3:"&permanent_state=1",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            已终止
                        </Button>


                        

                </div>




                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                        
                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A" , color:   "#fff"  }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "",
                                    page:1
                                }, () => {
                                    //this.HandleGetData()
                                })
                            }}>
                            营销类型
                        </Button>




                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&type=1" ? "#1DA57A" : "", color: url_4 === "&type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                   url: "&type=1"  + url_1 +  url_2 + url_3 + url_5 ,
                                   url_4:"&type=1",
                                   page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            N
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&type=2" ? "#1DA57A" : "", color: url_4 === "&type=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&type=2"  + url_1 +  url_2 + url_3 + url_5 ,
                                   url_4:"&type=2",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            R
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&type=3" ? "#1DA57A" : "", color: url_4 === "&type=3" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&type=3"  + url_1 +  url_2 + url_3 + url_5 ,
                                    url_4:"&type=3",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            SR
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&type=4" ? "#1DA57A" : "", color: url_4 === "&type=4" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&type=4"  + url_1 +  url_2 + url_3 + url_5 ,
                                    url_4:"&type=4",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            SSR
                        </Button>



                </div>


                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "15px" }}>
                    
                        
                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:  "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "",
                                    page:1
                                }, () => {
                                    //this.HandleGetData()
                                })
                            }}>
                            排序
                        </Button>

 
                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=1" ? "#1DA57A" : "", color: url_5 === "&orderby=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                   url: "&orderby=1"  + url_1 +  url_2 + url_3 + url_4 ,
                                   url_5:"&orderby=1",
                                   page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            创建时间
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=2" ? "#1DA57A" : "", color: url_5 === "&orderby=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&orderby=2"  + url_1 +  url_2 + url_3 + url_4 ,
                                    url_5:"&orderby=2",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            开始时间
                        </Button>

                </div>






                <div  className="waperBox">
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.setState({
                            page: e.current
                        }, () => {
                            this.HandleGetData()
                        })

                    }}
                />
                </div>

                
                <Modal
                    title="确认终止"
                    visible={this.state.visible}
                    onOk={() => {
                        if (closed === "confirm") {
                            this.handleOverOff()
                        } else {
                            message.error("输入错误！")
                        }
                    }}
                    onCancel={() => this.setState({ visible: false, closed: "" })}
                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ marginBottom: "15px" }}>请在输入框中输入"confirm"确认</span>
                        <Input
                            style={{ width: "200px", textAlign: 'center' }}
                            value={closed}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    closed: e.target.value.toLowerCase()
                                })
                            }} />
                    </div>

                </Modal>

                <Modal
                    title="编辑排序"
                    visible={this.state.sortVisible}
                    onCancel={() => this.setState({ sortVisible: false, itemData: "" })}
                    style={{ marginTop: "100px" }}
                    onOk={() => {
                        let { itemData, sortValue } = this.state
                        let data = { "system_sort": sortValue }
                        putCoupon(data, itemData.id).then(res => {
                            this.HandleGetData()
                            this.setState({ sortVisible: false, itemData: "" })
                        })
                    }}
                >
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        <span>排序：</span>
                        <InputNumber
                            style={{ width: "300px", textAlign: 'center' }}
                            value={sortValue}
                            onChange={(e) => {
                                this.setState({
                                    sortValue: e
                                })
                            }} />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(PromotionalGoodsList))

