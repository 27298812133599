import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, InputNumber, Select } from 'antd';
import { addPlugIn, getPlugInDeatil, UpdatePlugIn } from "../../action/authAction";

const { Option } = Select;

class PlugInEdit extends React.Component {
    componentDidMount() {
        const id = this.props.location.search.substring(1)
        getPlugInDeatil(id).then(data => {
            this.props.form.setFieldsValue({
                ...data.data
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const id = this.props.location.search.substring(1)
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (values.name) {
                if (id) {
                    UpdatePlugIn(values, id)
                } else {
                    addPlugIn(values)
                }

            }


        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>
                <Form.Item
                    label="插件名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请输入插件名称', whitespace: true }],
                    })(<Input />)}
                </Form.Item>


                {/*<Form.Item
                    label="适用客户端"
                >
                    {getFieldDecorator('client', {
                        rules: [{ required: true }],
                    })(<Select>
                        <Option key="h5">h5</Option>
                        <Option key="weapp">weapp</Option>
                    </Select>)}
                </Form.Item>
                <Form.Item label="识别路由">
                    {getFieldDecorator('route', {})(
                        <Input />)}
                </Form.Item>
                */}

                 <Form.Item label="识别KEY">
                    {getFieldDecorator('plugin_key', {})(
                        <Input />)}
                </Form.Item>   


                <Form.Item
                    label="每月价格"
                >
                    {getFieldDecorator('price', {
                        rules: [{ required: true, message: '请输入每月的价格', whitespace: true }],
                    })(<Input prefix="$" />)}
                </Form.Item>


                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<InputNumber min={0} style={{ width: '100%' }} />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(PlugInEdit))
