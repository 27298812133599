import React from "react";


class leetCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let nums = [1, 2, 3, 4, 5, 6, 7, 8, 9]
        let target = 10
        this.twoSum(nums, target)
    }

    twoSum = (nums, target) => {
        let len = nums.length
        for (let i = 0; i < len - 1; i++) {
            for (let j = i + 1; j < len; j++) {
                if (nums[i] === target - nums[j]) {
                    console.log([nums[i], nums[j]], "22222")
                }

            }
        }
    }

    render() {
        return (
            <div>
                Hello World!
            </div>
        );
    }
}

export default leetCode

