import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Radio, Switch } from 'antd';
import { GetConfigSet, UpdateConfigSet } from "../../action/authAction";

class Behavior extends React.Component {

    state = {
        data: "",
        is_charge_packing_fee: false,
        is_open_prize: false,
        is_open_spu_many_help: false,
        is_moon_enable: false,
        is_in_store: true,
        is_on_delivery_express: true,
        is_on_delivery: true,//配送
        is_delivery_foot: true,//配送
        eat_in: true,
        take: true,
        stripe: true,
        paypal: true,
        royal_pay: true

    };

    async componentDidMount() {
        let res = await GetConfigSet()
        let allData = res.data.data
        let data = []
        data.min_pay_fee_on_online = allData.find(el => el.key === "min_pay_fee_on_online").value
        // data.min_fee_by_offline_pay = allData.find(el => el.key === "min_fee_by_offline_pay").value
        data.user_single_refund_money = allData.find(el => el.key === "user_single_refund_money").value
        // data.payment_type = allData.find(el => el.key === "payment_type").value
        data.payment_method = allData.find(el => el.key === "payment_method").value
        data.is_charge_packing_fee = allData.find(el => el.key === "is_charge_packing_fee").value
        data.is_open_prize = allData.find(el => el.key === "is_open_prize").value
        data.is_open_spu_many_help = allData.find(el => el.key === "is_open_spu_many_help").value
        data.is_moon_enable = allData.find(el => el.key === "is_moon_enable").value
        data.is_in_store = allData.find(el => el.key === "is_in_store").value
        data.is_on_delivery_express = allData.find(el => el.key === "is_on_delivery_express").value
        data.is_delivery_foot = allData.find(el => el.key === "is_delivery_foot").value
        data.is_on_delivery = allData.find(el => el.key === "is_on_delivery").value
        data.in_store_type = allData.find(el => el.key === "in_store_type").value
        data.user_unpay_wait_time = allData.find(el => el.key === "user_unpay_wait_time").value
        data.user_refund_money_week_count = allData.find(el => el.key === "user_refund_money_week_count").value
        data.user_refund_money_month_count = allData.find(el => el.key === "user_refund_money_month_count").value
        data.min_use_user_money = allData.find(el => el.key === "min_use_user_money").value
        this.setState({
            data: data,
            is_charge_packing_fee: data.is_charge_packing_fee == 1 ? true : false,
            is_open_prize: data.is_open_prize == 1 ? true : false,
            is_open_spu_many_help: data.is_open_spu_many_help === "1" ? true : false,
            is_moon_enable: data.is_moon_enable === "1" ? true : false,
            is_in_store: data.is_in_store == 1 ? true : false,
            is_on_delivery_express: data.is_on_delivery_express == 1 ? true : false,
            is_delivery_foot: data.is_delivery_foot == 1 ? true : false,
            is_on_delivery: data.is_on_delivery == 1 ? true : false,
            eat_in: data.in_store_type.eat_in == 1 ? true : false,
            take: data.in_store_type.take == 1 ? true : false,
            // stripe: data.payment_type.stripe === 1 ? true : false,
            // paypal: data.payment_type.paypal === 1 ? true : false,
            // royal_pay: data.payment_type.royal_pay === 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let { eat_in, take, is_charge_packing_fee, is_in_store,is_on_delivery_express, is_delivery_foot,is_on_delivery,stripe, paypal, royal_pay, is_open_prize, is_open_spu_many_help,is_moon_enable } = this.state
                let data = []
                for (let key in values) {
                    data.push({
                        "key": key,
                        "value": values[key],
                    })
                }
                data.push({ "key": "in_store_type", "value": { "eat_in": eat_in ? 1 : "0", "take": take ? 1 : "0" } })
                data.push({ "key": "is_charge_packing_fee", "value": is_charge_packing_fee ? "1" : "0" })
                data.push({ "key": "is_open_prize", "value": is_open_prize ? "1" : "0" })
                data.push({ "key": "is_open_spu_many_help", "value": is_open_spu_many_help ? "1" : "0" })
                data.push({ "key": "is_moon_enable", "value": is_moon_enable ? "1" : "0" })
                data.push({ "key": "is_in_store", "value": is_in_store ? "1" : "0" })
                data.push({ "key": "is_on_delivery_express", "value": is_on_delivery_express ? "1" : "0" })
                data.push({ "key": "is_delivery_foot", "value": is_delivery_foot ? "1" : "0" })
                data.push({ "key": "is_on_delivery", "value": is_on_delivery ? "1" : "0" })
                // data.push({ "key": "payment_type", "value": { "stripe": stripe ? 1 : "0", "paypal": paypal ? 1 : "0", "royal_pay": royal_pay ? 1 : "0" } })
                UpdateConfigSet(data)
            }
        });
    };


    render() {
        const { data, is_charge_packing_fee,  is_on_delivery, eat_in, take, is_on_delivery_express,is_in_store,is_delivery_foot, stripe, paypal, royal_pay, is_open_prize, is_open_spu_many_help,is_moon_enable } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ maxWidth: "1000px", marginLeft: "100px" }}>


                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>插件价格设置</div>
                    <Form.Item
                        label="附加商品"
                    >
                        {getFieldDecorator('min_pay_fee_on_online', {
                            rules: [{ required: false }],
                            initialValue: "1.00"
                        })(<Input prefix="$"   />)}
                    </Form.Item>


                    <Form.Item
                        label="桌号"
                    >
                        {getFieldDecorator('user_single_refund_money', {
                            rules: [{ required: false }],
                            initialValue: "1.00"
                        })(<Input prefix="$"   />)}
                    </Form.Item>


                    <Form.Item
                        label="招牌菜"
                    >
                        {getFieldDecorator('min_use_user_money', {
                            rules: [{ required: false }],
                            initialValue: "1.00"
                        })(<Input prefix="$"   />)}
                    </Form.Item>


                    <Form.Item
                        label="打包费"
                    >
                        {getFieldDecorator('min_use_user_money', {
                            rules: [{ required: false }],
                            initialValue: "1.00"
                        })(<Input prefix="$"   />)}
                    </Form.Item>



                     
                </Card>


                
                
  

                 
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
