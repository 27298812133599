import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Button } from 'antd';
import { QuerySubscrption } from "../../action/authAction";

const serviceStatus = {
    'setup': '开通服务',
    'year': '年服务',
    'month': '月服务'
}

class MySubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };

        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: 'partner_id',
                dataIndex: 'partner_id',
                width: '9%',
                align: "center",
            },
            {
                title: '价格',
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                width: '7%',
                align: "center",
                render: text => <span>{text === 0 ? '已过期或终止' : text === 1 ? '服务中' : ''}</span>
            },
            {
                title: '类型',
                dataIndex: 'prod_type',
                width: '6%',
                align: "center",
            },
            {
                title: '服务类型',
                dataIndex: 'service_type',
                width: '7%',
                render: (text, record) => {
                    return <span>{serviceStatus[text]}</span>
                }
            },
            {
                title: '服务开始时间',
                dataIndex: 'service_start_at',
                width: '7%',
                align: "center",
            }, {
                title: '服务终止时间',
                dataIndex: 'service_end_at',
                width: '7%',
                align: "center",
            },
            {
                title: '订单是否过期',
                dataIndex: 'is_expire',
                width: '8%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_expire === 1 ? "green" : "#ff0000" }}>{record.is_expire === 1 ? "过期" : "未过期"}</span>
                    )
                }
            },
            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Button onClick={() => {
                            this.props.history.push("/MySubscriptionDetail?" + record.id)
                        }}>查看</Button>
                    )
                }
            },
        ];
    }

    async componentDidMount() {
        QuerySubscrption().then(data => {
            if (data && data.data) {
                this.setState({
                    data: data.data.data
                })
            }
        })
    }

    render() {

        return (
            <div className="waperBox">
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(MySubscription))

