import React, { Component } from 'react'
import { Menu, Icon } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import './index.less'
import { data } from "../../util/data";
import { connect } from 'react-redux'
import { SetMenuName } from "../../action/setMenuName";
import Logo from "../../img/logo.png"
const SubMenu = Menu.SubMenu
class NavLeft extends Component {
    rootSubmenuKeys = [];
    state = {
        openKeys: [],
    }

    handleMenuClick = ({ item }) => {
        this.props.dispatchMenu(item.props.title)
        sessionStorage.setItem('breadcrumb', item.props.data)
    }

    onOpenChange = (openKeys) => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            let openData = [latestOpenKey]
            if (latestOpenKey === "sub1" || "sub2") {
                openData.push("sub3")
            }
            this.setState({
                openKeys: latestOpenKey ? openData : [],
            });
        }
    }

    notOneLevel = (menuData, menu) => {
        for (let i = 0; i < menuData.length; i++) {
            if (menuData[i].path === this.props.location.pathname) {
                this.props.dispatchMenu(menuData[i].name)
            }
            if (menuData[i].children) {
                //二三级菜单合并成一级
                const newArr = [
                    {
                        name: menuData[i].name,
                        path: null,
                    },
                    ...menuData[i].children
                ];
                for (let j = 0; j < newArr.length; j++) {

                    if (newArr[j].path === this.props.location.pathname) {
                        this.props.dispatchMenu(newArr[j].name)
                    }
                    if (!newArr[j].path) {
                        menu.push(
                            <h3 key={newArr[j].name} className='disabledMenu' disabled>
                                <span>{newArr[j].name}</span>
                            </h3>
                        )
                    } else {
                        menu.push(
                            <Menu.Item key={newArr[j].path} className="other-level" title={newArr[j].name} data={newArr[j].title}>
                                <Link to={newArr[j].path}>
                                    <span>{newArr[j].name}</span>
                                </Link>
                            </Menu.Item>
                        )
                    }
                }

            } else {
                menu.push(
                    <Menu.Item key={menuData[i].path} className="other-level" title={menuData[i].name} data={menuData[i].title}>
                        <Link to={menuData[i].path}>
                            <span>{menuData[i].name}</span>
                        </Link>
                    </Menu.Item>
                )
            }

        }
    }


    createMenu = ((menuData) => {  //创建菜单
        //let itemIndex = 0; //累计的每一项索引
        let submenuIndex = 0; //累计的每一项展开菜单索引
        let menu = [];
        let username = localStorage.getItem("username")
        if (username !== "admin") {

            for (let i in menuData) {
                if (menuData[i].name === "商家") {
                    menuData.splice(i, 1)
                }
                if (menuData[i].name === "套餐管理") {
                    menuData.splice(i, 1)
                }
                if (menuData[i].name === "设置") {
                    menuData.splice(i, 1)
                }
            }
        }
        const create = (menuData, el) => {
            for (let i = 0; i < menuData.length; i++) {
                if (menuData[i].path === this.props.location.pathname) {
                    this.props.dispatchMenu(menuData[i].name)
                }
                if (menuData[i].children) {  //如果有子级菜单
                    let children = [];
                    this.notOneLevel(menuData[i].children, children);
                    submenuIndex++;
                    this.rootSubmenuKeys.push(`sub${submenuIndex}`)
                    el.push(
                        <SubMenu
                            key={`sub${submenuIndex}`}
                            title={(
                                <span className='subMenuSpan'>
                                    <Icon type={menuData[i].icon} />
                                    {menuData[i].name}
                                </span>
                            )}
                        >
                            {children}
                        </SubMenu>
                    )
                } else {   //如果没有子级菜单
                    //itemIndex++;
                    el.push(
                        <Menu.Item key={menuData[i].path} title={menuData[i].name} data={menuData[i].title}>
                            <Link to={menuData[i].path}>
                                <Icon type={menuData[i].icon} />
                                <span>{menuData[i].name}</span>
                            </Link>
                        </Menu.Item>
                    )
                }
            }
        };
        create(menuData, menu);
        return menu;

    });




    render() {
        const { history } = this.props
        return (
            <div className='menu'>
                <div className='logo'>
                    {/* <h3><Icon type="slack" className="lg-icon" /></h3> */}
                    <img src={Logo} alt="" style={{ width: "70px", height: "70px" }} />
                </div>
                <Menu
                    selectedKeys={[history.location.pathname]}
                    defaultSelectedKeys={['1']}
                    mode="vertical"
                    theme='dark'
                    // openKeys={this.state.openKeys}
                    // onOpenChange={this.onOpenChange}
                    style={{ minHeight: "900px" }}
                    onClick={this.handleMenuClick}
                >
                    {this.createMenu(data())}
                </Menu>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        dispatchMenu(title) {
            dispatch(SetMenuName(title))
        }
    }
}

export default connect(null, mapDispatchToProps)(withRouter(NavLeft))
