import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, DatePicker } from 'antd';
import { getPackageList, getLanguageList } from "../../../action/authAction";
import { AddPackageLog, getShopList } from "../../../action/shopAction";
import moment from 'moment';
class NewPackageLog extends React.Component {

    state = {
        packageList: "",
        eventList: ["created", "published", "modified", "end"],
        shopData: "",
        record_at: "",
        implement_at: "",
        partner_id: ""
    };

    async componentDidMount() {
        let partner_id = this.props.location.pathname.substring(15)
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        let res = await getPackageList(LanguageData[1].id)
        let data = await getShopList()
        this.setState({
            packageList: res.data.data,
            shopData: data.data.data,
            partner_id
        })
    }

    handleSubmit = (e) => {
        let { record_at, implement_at, partner_id } = this.state
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.record_at = record_at
                values.implement_at = implement_at
                values.partner_id = partner_id
                AddPackageLog(values).then(res => {
                    let partner_id = this.props.location.pathname.substring(15)
                    this.props.history.push("/PackageLogList/" + partner_id)
                })
            }
        });
    };


    render() {
        const { shopData, packageList, eventList } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>
                {/* <Form.Item
                    label="选择商家"
                >
                    {getFieldDecorator('partner_id', {
                        rules: [{ required: true, message: 'Please select package!' }],
                    })(
                        <Select placeholder="选择商家">
                            {shopData && shopData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item> */}
                <Form.Item
                    label="套餐名称"
                >
                    {getFieldDecorator('package_id', {
                        rules: [{ required: true, message: '请选择套餐!' }],
                    })(
                        <Select placeholder="选择套餐">
                            {packageList && packageList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="定额订单服务费"
                >
                    {getFieldDecorator('order_fixed_fee', {
                        rules: [{ required: false }],
                    })(<Input min={0} prefix="$" />)}
                </Form.Item>
                <Form.Item
                    label="商品订单服务费"
                >
                    {getFieldDecorator('rate', {
                        rules: [{ required: false }],
                    })(<Input min={0} suffix="%" />)}
                </Form.Item>
                <Form.Item
                    label="活动订单服务费"
                >
                    {getFieldDecorator('activity_service_rate', {
                        rules: [{ required: false }],
                    })(<Input min={0} suffix="%" />)}
                </Form.Item>

                <Form.Item
                    label="修改原因"
                >
                    {getFieldDecorator('event', {
                        rules: [{ required: true, message: '请选择修改原因!' }],
                    })(
                        <Select placeholder="选择修改原因">
                            {eventList && eventList.map(el => <Select.Option key={el} value={el}>{el}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    label="备案时间"
                >
                    <DatePicker onChange={(e) => {
                        this.setState({
                            record_at: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                        })
                    }} />
                </Form.Item>

                <Form.Item
                    label="执行时间"
                >
                    <DatePicker onChange={(e) => {
                        this.setState({
                            implement_at: moment(e).format("YYYY-MM-DD HH:mm:ss"),
                        })
                    }} />
                </Form.Item>

                <Form.Item
                    label="简介"
                >
                    {getFieldDecorator('remarks', {
                        rules: [{ required: false }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewPackageLog))
