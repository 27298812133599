import React from 'react'
import { withRouter } from 'react-router-dom'

import { Form, Input, Button, Select, Upload, Icon, message, Descriptions, Divider } from 'antd';
import { GetUserWithdrawDetail, DisposeUserWithdraw } from "../../action/authAction";


function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

class UserWithdrawLogDetail extends React.Component {

    state = {
        data: "",
        imageUrl: "",
        goodImage: "",
        loading: false,
        dispose: [{
            id: 1,
            name: "同意"
        }, {
            id: 3,
            name: "拒绝"
        }],
        chooseDispose: "",
        orderData: ""
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(18)
        let res = await GetUserWithdrawDetail(id)
        this.setState({
            data: res.data,
            orderData: res.data.order,
            imageUrl: res.data.ticket_image
        })
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }



        });
    };

    async SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        }
        const id = this.props.location.pathname.substring(18)
        if (this.state.goodImage) {
            formData.append("ticket_file", this.state.goodImage)
        }
        DisposeUserWithdraw(formData, id).then(res => {
            this.props.history.push("/userRefund")
        })

    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    goodImage: img
                }),
            );
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        const { money, is_finish, user_name, account_name, account, abn, bsb, dispose_message } = this.state.data;
        const { imageUrl, dispose, loading, orderData } = this.state
        const { Option } = Select;
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                <Form.Item
                    label="提现人名称"
                >
                    <span style={{ marginLeft: "10px" }}>{user_name}</span>
                </Form.Item>


                <Form.Item
                    label="银行名称"
                >
                    <span style={{ marginLeft: "10px" }}>{account_name}</span>
                </Form.Item>


                <Form.Item label="银行帐号">
                    <span style={{ marginLeft: "10px" }}>{account}</span>
                </Form.Item>

                <Form.Item
                    label={<span>abn</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{abn}</span>
                </Form.Item>


                <Form.Item
                    label={<span>bsb</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{bsb}</span>
                </Form.Item>

                <Form.Item
                    label="提现总额"
                >
                    <span style={{ marginLeft: "10px" }}>${money}</span>
                </Form.Item>

                <Form.Item
                    label="处理留言"
                >
                    {getFieldDecorator('dispose_message', {
                        initialValue: dispose_message
                    })(<Input style={{ maxWidth: "300px" }} />)}
                </Form.Item>
                <Form.Item
                    label="处理类型"
                >
                    {getFieldDecorator('is_finish', {
                        rules: [{ required: true, message: '请选择处理类型!' }],
                        initialValue: is_finish === 0 ? "" : is_finish
                    })(
                        <Select
                            style={{ maxWidth: "300px" }}
                            disabled={is_finish !== 0}
                            onChange={(e) => {
                                this.setState({
                                    chooseDispose: e
                                })
                                this.props.form.resetFields(['img_file']);
                            }}
                        >
                            {
                                dispose.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label={<span>票据图片</span>}
                >
                    {getFieldDecorator('img_file', {
                        // rules: [{ required: chooseDispose === 1 ? true : false, message: '请上传票据图片!' }],
                        rules: [{ required: false, }],
                        initialValue: imageUrl
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={this.handleUpload}
                        >
                            {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={<span>订单信息</span>}
                >
                    <Descriptions>
                        <Descriptions.Item label="订单号">{orderData && orderData.order_sn}</Descriptions.Item>
                        <Descriptions.Item label="下单时间">{orderData && orderData.created_at && orderData.created_at.date.substring(0, 19)}</Descriptions.Item>
                        <Descriptions.Item label="支付状态">{orderData && orderData.is_pay === 0 ? "未付款" : "已付款"}</Descriptions.Item>
                        <Descriptions.Item label="使用状态">{orderData && orderData.is_finish === 0 ? "未使用" : "已使用"}</Descriptions.Item>
                        {/* <Descriptions.Item label="确认订单时间">{orderData && orderData.service_at && orderData.service_at.date.substring(0, 19)}</Descriptions.Item>
                                    <Descriptions.Item label="确认管理员">{orderData && orderData.service_user_name}</Descriptions.Item> */}

                        <Descriptions.Item label="收款方">{orderData && orderData.payee && orderData.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>
                        <Descriptions.Item label="付款时间">{orderData && orderData.pay_at && orderData.pay_at.date.substring(0, 19)}</Descriptions.Item>
                        {
                            orderData?.order_status === "refunded" &&
                            <Descriptions.Item label="退款到">
                                {
                                    orderData?.is_refunded === 1 ? "银行卡" : orderData?.is_pay === 2 ? "钱包" : ""
                                }
                            </Descriptions.Item>
                        }
                        <Descriptions.Item label="备注">{orderData && orderData.remark}</Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions title="费用详情">
                        <Descriptions.Item label="商品小计">${orderData && orderData.spu_discount_price}</Descriptions.Item>
                        <Descriptions.Item label="钱包抵扣">${orderData && orderData.use_user_money}</Descriptions.Item>
                        <Descriptions.Item label="订单合计">${orderData && (parseFloat(orderData.price) + parseFloat(orderData.use_user_money)).toFixed(2)}</Descriptions.Item>
                        <Descriptions.Item label="支付通道手续费">${orderData && orderData.transaction_fee}</Descriptions.Item>
                        <Descriptions.Item label="实际付款金额">${orderData && orderData.pay_total_price}</Descriptions.Item>
                        <Divider />
                        {/* <Descriptions.Item label={orderData.spu_discount_id > 0 ? "活动订单服务费百分比" : "订单服务费百分比"}>{orderData && orderData.orderPartnerMoney && orderData.orderPartnerMoney.partner_charge_rate}%</Descriptions.Item>
                        <Descriptions.Item label={orderData.spu_discount_id > 0 ? "活动订单固定抽成" : "订单固定抽成"}>${orderData && orderData.orderPartnerMoney && orderData.orderPartnerMoney.partner_fixed_fee || 0}</Descriptions.Item> */}
                    </Descriptions>
                    <Divider />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>



            </Form>
        );
    }
}

export default withRouter(Form.create()(UserWithdrawLogDetail))
