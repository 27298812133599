import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Button } from 'antd';
import { QueryOrders } from "../../action/authAction";


class MyOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };

        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '订单编号',
                dataIndex: 'order_sn',
                width: '9%',
                align: "center",
            },
            {
                title: '价格',
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '实际支付',
                dataIndex: 'real_price',
                width: '7%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '使用余额',
                dataIndex: 'use_money',
                width: '6%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '订单状态',
                dataIndex: "order_status",
                width: '6%',
                align: "center",
                render: (record) => {
                    switch (record) {
                        case "un_pay":
                            return "未付款";
                            break;
                        case "wait_order_receiving":
                            return "待接单";
                            break;
                        case "un_order_receiving":
                            return "退款中";
                            break;
                        case "for_consumption":
                            return "待消费";
                            break;
                        case "finished":
                            return "已完成";
                            break;
                        case "canceled":
                            return "已过期";
                            break;
                        case "rejected":
                            return "已拒绝";
                            break;
                        case "refunded":
                            return "已退款";
                            break;


                    }
                }
            },
            {
                title: '付款方式',
                dataIndex: 'pay_type',
                width: '7%',
                align: "center",
            },
            {
                title: '是否试用订单',
                dataIndex: 'is_trial',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span >{text === 1 ? "试用" : "正式"}</span>
                    )
                }
            },

            {
                title: '订单是否过期',
                dataIndex: 'is_expire',
                width: '8%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_expire === 1 ? "green" : "#ff0000" }}>{record.is_expire === 1 ? "过期" : "未过期"}</span>
                    )
                }
            },
            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Button onClick={() => {
                            this.props.history.push("/myOrderDetail?" + record.id)
                        }}>查看</Button>
                    )
                }
            },
        ];
    }

    async componentDidMount() {
        QueryOrders().then(data => {
            if (data && data.data) {
                this.setState({
                    data: data.data.data
                })
            }
        })
    }

    render() {

        return (
            <div className="waperBox">
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(MyOrders))

