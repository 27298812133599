import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch ,Select } from 'antd'; 
import { getDocumentList, editDocument, deleteDocument  } from "../../action/shopAction";
import { GetConfigSetDocument, UpdateConfigSetDocument,getLanguageList } from "../../action/authAction";
 



class DocumentList extends React.Component {
    constructor(props) {
        super(props);
        
        //this.state = { data: [], editingKey: '' };

        this.state = { data: [], editingKey: '' ,LanguageData: "", language_id: "" ,is_document_enable:1 };


        this.columns = [
            {
                title: "Id",
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            {
                title: "标题",
                dataIndex: 'title',
                width: '10%',
                align: "center",
            },

            {
                title: "分类",
                dataIndex: 'documentcategory.title',
                width: '10%',
                align: "center",
            },


            {
                title: "是否显示",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.show === 1} onChange={() => {
                            const { id, show } = record
                            let data = { "show": show === 1 ? "0" : "1" }
                            editDocument(id, data).then(res => {
                                this.HandleGetData(this.state.language_id)
                            })
                        }} />
                    )
                }
            },
            {
                title: "排序",
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            {
                title: "操作",
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    if (record.is_default !== 1) {
                        return <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => {
                                this.props.history.push("/EditDocument/" + record.id)
                            }}>
                                编辑
                            </Button>
                            <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>
                    }

                },
            },

        ];
    }



     async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })


        let res = await GetConfigSetDocument() 

       
        this.setState({ is_document_enable: res.data.value   })

         console.log("is_document_enable===",res.data.value); 
         console.log("is_document_enable1===",this.state.is_document_enable); 


    }


    /*
    componentDidMount() {
        this.HandleGetData()
    }
    */


    async HandleGetData(id) { 
        let res = await getDocumentList(id);
        this.setState({ data: res.data.data ,language_id: id}) 
    }

    async handleDelete(data) {
        await deleteDocument(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        let { LanguageData,is_document_enable } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px",  }}>
                    {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.HandleGetData(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }




                    <div style= {{ display: "flex" , alignItems: "center"  }}  >

                         <div style={{ marginRight: "20px" }}>
                            <span>是否开启文档：</span>
                            <Switch checked={is_document_enable == "1"} onChange={() => {
                                UpdateConfigSetDocument({ "value": !is_document_enable ? "1" : "0" }).then(res => {
                                    this.setState({
                                        is_document_enable: !is_document_enable
                                    })
                                })
                            }} />
                        </div>




                        <Button type="primary"
                            onClick={() => this.props.history.push("/newChat")}
                        >
                            新建
                        </Button>


                    </div>


                    
                </div>


                <Table
                    rowKey='id'
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(DocumentList))

