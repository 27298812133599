import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Modal, Input, Select } from 'antd';
import { getPrinterCategoryList, AddPrinterCategory, UpdatePrinterCategory, deletePrinterCategory, getPrinterCategoryDetail } from "../../../action/AdAction";
import { getLanguageList } from "../../../action/authAction"

class PrinterCategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], visible: false, itemData: "", LanguageData: "", language_id: "" };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },

            {
                title: '打印机品牌',
                dataIndex: "brand",
                width: '10%',
                align: "center",
            },

            {
                title: '打印机型号',
                dataIndex: "model",
                width: '10%',
                align: "center",
            },

            {
                title: '默认名称',
                dataIndex: "name",
                width: '10%',
                align: "center",
            },
            {
                title: '价格',
                dataIndex: "price",
                width: '10%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '备注',
                dataIndex: "remark",
                width: '10%',
                align: "center",
            },

            {
                title: '操作',
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => this.edit(record)}>
                                编辑
                            </Button>
                            <Popconfirm title="确定删除" onConfirm={() => this.handleDelete(record.id)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                },
            },

        ];
    }
    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData, language_id: LanguageData[1].id }, () => {
            this.handleGetPrintCategoryList(LanguageData[1].id)
        })
    }



    handleGetPrintCategoryList = async (id) => {
        let res = await getPrinterCategoryList(id)
        let data = res.data.data
        this.setState({ data: data, language_id: id })
    }
    handleGetPrinterCategoryDetail = (language_id) => {
        let { id } = this.state
        if (id) {
            getPrinterCategoryDetail(id, language_id).then(res => {
                this.setState({
                    visible: true,
                    itemData: res.data,
                    id: ""
                });
            })
        }

    }
    edit(record) {
        let { language_id } = this.state
        getPrinterCategoryDetail(record.id, language_id).then(res => {
            this.setState({
                visible: true,
                id: res.data.id,
                itemData: res.data
            });
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            itemData: '',
            id: ""
        });
    };

    async handleDelete(id) {
        await deletePrinterCategory(id)
        this.handleGetPrintCategoryList(this.state.language_id)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { itemData } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            // values.request_language_id = values.request_language_id
            if (!err) {
                if (itemData && itemData.id) {
                    //修改-注意参数
                    UpdatePrinterCategory(itemData.id, values).then(res => {
                        this.handleGetPrintCategoryList(this.state.language_id)
                        this.setState({
                            visible: false,
                            itemData: '',
                            id: ""
                        });
                    })
                } else {
                    //新增
                    AddPrinterCategory(values).then(res => {
                        this.handleGetPrintCategoryList(this.state.language_id)
                        this.setState({
                            visible: false,
                            itemData: '',
                            id: ""
                        });
                    })
                }
            }
        });
    };


    render() {
        const { itemData, LanguageData, visible, language_id } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.handleGetPrintCategoryList(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }
                    <Button type="primary"
                        onClick={() => this.setState({ visible: true })}
                    >
                        添加
                    </Button>
                </div>
                <Table
                    rowKey='id'
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
                {
                    visible && <Modal
                        destroyOnClose
                        title={itemData.id ? '编辑打印机' : '新建打印机'}
                        visible={visible}
                        onOk={this.handleSubmit}
                        onCancel={this.handleCancel}
                    >
                        <Form onSubmit={this.handleSubmit} {...formItemLayout}>
                            <Form.Item
                                label={<span>选择录入的语言</span>}
                            >
                                {getFieldDecorator('request_language_id', {
                                    rules: [{ required: true, message: '请选择语言!' }],
                                    initialValue: language_id ? language_id : LanguageData[1].id
                                })(
                                    <Select
                                        onChange={(e) => {
                                            this.handleGetPrinterCategoryDetail(e)
                                        }}
                                    >
                                        {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                label='打印机品牌'
                            >
                                {getFieldDecorator('brand', {
                                    rules: [{ required: true, message: '请输入打印机品牌!', whitespace: true }],
                                    initialValue: itemData && itemData.brand
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item
                                label='打印机型号'
                            >
                                {getFieldDecorator('model', {
                                    rules: [{ required: true, message: '请输入打印机型号!', whitespace: true }],
                                    initialValue: itemData && itemData.model
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label='打印机默认别名'
                            >
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入打印机默认别名!', whitespace: true }],
                                    initialValue: itemData && itemData.name
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label='价格'
                            >
                                {getFieldDecorator('price', {
                                    rules: [{ required: true, message: '请输入价格!', whitespace: true }],
                                    initialValue: itemData && itemData.price
                                })(<Input prefix="$" />)}
                            </Form.Item>

                            <Form.Item
                                label='备注'
                            >
                                {getFieldDecorator('remark', {
                                    initialValue: itemData && itemData.remark
                                })(<Input />)}
                            </Form.Item>
                        </Form>
                    </Modal>
                }

            </div>


        );
    }
}

export default withRouter(Form.create()(PrinterCategoryList))
