import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Switch } from 'antd';
import { getPartnerNavList } from "../../action/AdAction";

class LangList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: "center",
            },
            {
                title: '名称',
                dataIndex: 'name',
                align: "center",
            }, {
                title: '是否显示',
                dataIndex: 'is_show',
                align: "center",
                render: text => <Switch checked={text === 1} disabled />
            }, {
                title: '排序',
                dataIndex: 'sort',
                align: "center",
            },


        ];
    }

    async componentDidMount() {
        let res = await getPartnerNavList()
        this.setState({ data: res.data.data })
    }


    render() {
        return (
            <Table
                rowKey='id'
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }}
                className="waperBox"
            />

        );
    }
}

export default withRouter(LangList)

