import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form , Descriptions, Card, Divider, Table, Button,Popconfirm,Modal,Input, Icon ,Tooltip} from 'antd';
import { OrderRefund ,OrderRefundGoods } from "../../action/authAction";
import { getOrderSubscriptionDetail  } from "../../action/goodAction";

import { confirmOrderOrder } from "../../action/goodAction";

import gsthave from "../../img/gsthave.png"
import gstno from "../../img/gstno.png"

import silverImg from "../../img/member/silver.png"
import goldImg from "../../img/member/gold.png"
import diamondImg from "../../img/member/diamond.png"



let currency_symbol = '$'
const { TextArea } = Input;


class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: "", 
            order_gst: 0,
            order_gst_inc : 0,//含gst 
            order_gst_excl:0,//不含gst 
            order_gst_total:0,//总计gst
            weight_total:0,
            refundvisible:false, 
            refund_message: "",
            refundvisibleGoods:false,
            refundvisible:false, 
            refund_message:"",
            refund_message_part:"",
            refund_spu_id:null,
            refund_spu_num:1,
            refund_quantity:1,
            gstVisible:false,


             };
    }

    async componentDidMount() {
        this.getDetail();     
    }

    async getDetail(){
        const id = this.props.location.pathname.substring(25);
        if (id) {
            let res = await getOrderSubscriptionDetail(id);
            
            let orderSpuData = {};
            let sum = 0


            /* 
            if (orderSpuData) {
                for (let i in orderSpuData) {
                    sum += parseFloat(orderSpuData[i].gst_total)
                }
            }
            */



              //计算重量，gst 
              var order_gst_inc = 0;
              var order_gst_excl = 0;


              // orderSpuData.forEach((item) => { 
              //   if(item.is_gst == 1){ 
              //       if(item.gst_total){
              //           order_gst_inc += parseFloat(item.gst_total);
              //       } 
              //   }  
              //   if(item.is_gst == 2){ 
              //       if(item.gst_total){
              //           order_gst_excl += parseFloat(item.gst_total);
              //       } 
              //   }  
                
              // }); 
 
              var order_gst_total =  parseFloat(order_gst_inc) + parseFloat(order_gst_excl)  ;

     
             //计算重量  
              var weight_total = 0; 

              // orderSpuData.forEach((item) => {  
              //   if(item.weight_total){
              //       weight_total += parseFloat(item.weight_total);
              //   } 
              // }); 
               

 
            this.setState({ 
                data: res.data, 
                order_gst: sum,
                order_gst_inc:order_gst_inc,
                order_gst_excl:order_gst_excl,
                order_gst_total:order_gst_total,
                weight_total:weight_total, 

                 })
        }

     }


    handleReset=()=>{
      this.props.form.resetFields("refund_spu_num");  
    }



    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            refund_goods_price, 
            refund_message_part,
            refund_spu_id,
            refund_spu_num,
            refund_quantity,
            refundvisibleGoods,
            refund_message, 
            ItemId, 
            data, 
            order_gst ,
            order_gst_excl,
            order_gst_inc,
            order_gst_total,
            weight_total,
            refundvisible,
            gstVisible
            } = this.state;


        const columns = [{
            title: 'id',
            dataIndex: 'id',
            width: '10%',
            align: "center",
        }, 

        {
            title:"bdid",
            dataIndex:"attach_data.bdid"
        },

        {
            title: '商品',
            width: '25%',
            render: (text, record) => {
                return (
                    <div key={record.id} style={{ display: "flex" }}>
                        <img src={record.attach_data.image} style={{ width: "50px", height: "50px", marginRight: "10px", objectFit: "cover" }} alt="" />
                        <div>
                            
                            <p>

                            {record.attach_data.is_gst == 2 && '[不含GST]' } 

                            {record.is_refunded == 1 ?  
                                <span style={{color:'red'}} >[已退款{currency_symbol}{record.is_refunded_price}]</span>  : "" 
                            } 

                            {record.is_refunded_num > 0 ? 

                                <span style={{color:'red'}} >[退款数量{record.is_refunded_num}]</span>  : "" 
                            } 

                              
                            {record.name}
                            </p>

                            
                            <div style={{ display: "flex" }}>
                                {
                                    record.attach_data && record.attach_data.spu_sku && record.attach_data.spu_sku.spu_sku_goods_specification_item && record.attach_data.spu_sku.spu_sku_goods_specification_item.map(key => {
                                        return (
                                            <p key={key.id} style={{ marginLeft: 5, color: "#878FA5", fontSize: 12 }}>{key.name}</p>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                {
                                    record.attach_data && record.attach_data.spu_goods_annex_type && record.attach_data.spu_goods_annex_type.map(key => {
                                        let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                        return (
                                            <div key={key.goods_annex_type_id}>
                                                {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {

                                                    let goods_annex_type_item = type_key.goods_annex_type_item
                                                    return <p key={i + goods_annex_type_item.name} style={{ marginLeft: 5, color: "#878FA5", fontSize: 12 }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                })}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        }, {
            title: '价格',
            //dataIndex: 'price',
            width: '15%',
            align: "center",
            render: (record) => {

                if(record.member_price > 0 ){
                        return ( 
                        <div> 

                         {record.member_level_name == 'silver' ? <img src={silverImg} alt='avatar' style={{ width: '20px',paddingRight:'5px' }} /> : "" }  
                         {record.member_level_name == 'gold' ? <img src={goldImg} alt= 'avatar' style={{ width: '20px',paddingRight:'5px' }} /> : "" } 
                         {record.member_level_name == 'diamond' ? <img src={diamondImg} alt='avatar' style={{ width: '20px',paddingRight:'5px' }} /> : "" } 



                         {currency_symbol + record.member_price} 

                        {record.attach_data.is_gst ==  1 &&  
                            <img style={{ width: "20px" }} src={gsthave} alt='="' />
                         } 


                         {record.attach_data.is_gst ==  2 &&  
                            <img style={{ width: "20px" }} src={gstno} alt='="' />
                         } 
                         </div>

                        )

                }  else{
                    return ( 
                        <div>  
                            {currency_symbol + record.price}

                            {record.attach_data.is_gst ==  1 &&  
                                <img style={{ width: "20px" }} src={gsthave} alt='="' />
                             } 


                             {record.attach_data.is_gst ==  2 &&  
                                <img style={{ width: "20px" }} src={gstno} alt='="' />
                             } 
                             </div> 
                     )
                }

                    
            }
        }, {
            title: '数量',
            dataIndex: 'quantity',
            width: '10%',
            align: "center",
        }, {
                title: "退款", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return (data.orderSpu.data.length > 1 && data?.order_status !== "refunded" &&  <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            

                            onClick={() => {
                                 this.setState({
                                    refundvisibleGoods: true,
                                    refund_goods_price: record.member_price > 0 ? record.member_price: record.price,
                                    refund_spu_id: record.id,
                                    refund_quantity:record.quantity-record.is_refunded_num,
                                    refund_spu_num:record.quantity,
                                    //refund_message_part: ""
                                 })
                            }}


                            >
                           退款
                        </Button>)
                }

            }
        ]




        const columnsRefund = [
             
            // {
            //     title: '退款商品id',
            //     dataIndex: 'order_refund_spu_id' ,
            //     width: '10%',
            //     align: "center",
            // },

            {
                title: "退款商品",
                width: '25%', 
                render: (text, record) => {
                    return (
                        <div style={{ display: "flex" }}>
                            <img src={record.spu?.image} style={{ width: "50px", height: "50px", marginRight: "10px", objectFit: "cover" }} alt="" />
                            <div>
                                <p> 
                                {record.spu?.is_gst == 2 && '[不含GST]' } 
                                {record.spu?.name} 
                                </p> 
                            </div>
                        </div>
                    )
                }
            },

            
              {
                title: "退款金额",
                //dataIndex: 'order_refund_price',
                width: '10%',
                align: "center",
                render: (record) => {
                     return ( 
                        <div>
                    { currency_symbol + record.order_refund_price}

                    {record.spu.is_gst ==  1 &&  
                        <img style={{ width: "20px" }} src={gsthave} alt='="' />
                     } 


                     {record.spu.is_gst ==  2 &&  
                        <img style={{ width: "20px" }} src={gstno} alt='="' />
                     } 
                     </div>

                     ) 

                }
            }, {
                title: '数量',
                dataIndex: 'order_refund_spu_num',
                width: '10%',
                align: "center",
            }, 
            , {
                title: "备注",
                dataIndex: 'order_refund_message_part',
                width: '10%',
                align: "center",
            },
            {
                title: "处理来源", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return   record.refund_source 
                }
            },

            {
                title: "处理人", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return  record.refund_user
                }
            }
            , {
                title: "退款日期", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            }

        ]







        let order_status = ""
        switch (data.order_status) {
            case "un_pay":
                order_status = "未付款";
                break;
            case "wait_order_receiving":
                order_status = "待接单";
                break;
            case "un_order_receiving":
                order_status = "退款中";
                break;
            case "for_consumption":
                order_status = "待消费";
                break;


            case "wait_delivery":
                order_status = "待配送";
                break;

            case "be_delivery":
                order_status = "配送中";
                break;
              
            case "finished":
                order_status = "已完成";
                break;
            case "canceled":
                order_status = "已过期";
                break;
            case "rejected":
                order_status = "已拒绝";
                break;
            case "refunded":
                order_status = "已退款";
                break;
        }
        let spu_discount = data?.orderSpu?.data[0]?.attach_data?.spu_discount
        let userCoupon = data.userCoupon && data.userCoupon.data[0]
        let coupon_status = ""
        switch (userCoupon && userCoupon.status) {
            case "used":
                coupon_status = <span style={{ color: "green" }}>已使用</span>
                break;
            case "unused":
                coupon_status = "未使用"
                break;
            case "expired":
                coupon_status = <span style={{ color: "#ff0000" }}>已过期</span>
                break;
            case "disabled":
                coupon_status = <span style={{ color: "#ff0000" }}>已失效</span>
                break;
        }


        let coupon_type = "";
        switch (userCoupon && userCoupon.coupon.type) {
            case 1:
                coupon_type = "N";
                break;
            case 2:
                coupon_type = "R";
                break;
            case 3:
                coupon_type = "SR";
                break;
            case 4:
                coupon_type = "SSR";
                break;
            default:
                coupon_type = "";
                break;
        }


        let  operation_type = "";
        switch (userCoupon && userCoupon.coupon.operation_type) {
            case 0:
                operation_type = "线下";
                break;
            case 1:
                operation_type = "线上";
                break;
            case 2:
                operation_type = "线下和线上";
                break;
            default:
                operation_type = "";
                break;
             
        }



        let user_operation_type = "";
        switch (userCoupon && userCoupon.use_operation_type) {
            case 0:
                user_operation_type = "线下";
                break;
            case 1:
                user_operation_type = "线上";
                break;
            case 2:
                user_operation_type = "线下和线上";
                break;
            default:
                user_operation_type = "";
                break;
             
        }


        










        let orderType = ""
        if (data?.spu_discount_id) {
            orderType = "Deal订单"
        } else if (data?.is_user_cunpon) {
            orderType = "优惠券订单"
        } else {
            orderType = "商品订单"
        }


        let orderSource = ""
        if (data?.order_source == 1) {
            orderSource = "店铺"
        } else if (data?.order_source == 2) {
            orderSource = "平台"
        }  



        let nostockTypeNote = ""
        if (data?.nostock_type == '0') {
            nostockTypeNote = "商家进行部分商品退款"
        } else if (data?.nostock_type == '1') {
            nostockTypeNote = "订单全额进行退款"
        } else if (data?.nostock_type == '2') {
            nostockTypeNote = "商家联系我进行处理"
        }  




        //服务费类型判断
        let rate_type = ""; 
        let last_rate =  data.orderPartnerMoney?.partner_charge_rate / 100;    
        
        //活动服务费类型
        if(data?.spu_discount_id > 0 || data?.is_user_coupon > 0 ){

            //活动

            if(data.partner?.activity_service_rate > 0){ 
                if(last_rate > data.partner?.activity_service_rate){
                    rate_type = "商家自定义";
                }else{
                    rate_type = "系统指定";
                }
            } else {
                rate_type = "套餐";
            }
              
        }else{ 
            //商品服务费类型
            rate_type = data.partner?.rate > 0 ? "系统指定" : "套餐";

        }




        return (
            <div>
            <Card>
                <Descriptions title="基本信息">
                    {
                        data?.spu_discount_id > 0 &&
                        <Descriptions.Item label="活动类型">
                            {
                                data?.spu_many_help_id > 0 ? "Deal 砍一刀" : "Deal"
                            }
                        </Descriptions.Item>
                    }

                    

                    

                    <Descriptions.Item label="订单号">{data && data.order_sn}</Descriptions.Item>
                    <Descriptions.Item label="下单时间">{data && data.created_at && data.created_at.date.substring(0, 19)}</Descriptions.Item>
                    

                    {/*<Descriptions.Item label="支付状态">{data && data.is_pay === 0 ? "未付款" : "已付款"}</Descriptions.Item>*/}
                    

                    {
                        data?.order_status !== "refunded" &&
                        <Descriptions.Item label="使用状态">{data && data.is_finish === 0 ? "未使用" : "已使用"}</Descriptions.Item>
                    }
                    {
                        data?.order_status !== "refunded" &&
                        <Descriptions.Item label="确认订单时间">{data && data.service_at && data.service_at.date.substring(0, 19)}</Descriptions.Item>
                    }
                    {
                        data?.order_status !== "refunded" &&
                        <Descriptions.Item label="确认管理员">{data && data.service_user_name}</Descriptions.Item>
                    }

                    {/* <Descriptions.Item label="订单原价">{data && data.origin_price}</Descriptions.Item>
                    <Descriptions.Item label="付款金额">{data && data.price}</Descriptions.Item>
                    <Descriptions.Item label="取货方式">{data && data.service_type === 1 ? "到店" : "配送"}</Descriptions.Item>
                    <Descriptions.Item label="配送费">{data && data.delivery_price}</Descriptions.Item>
                    <Descriptions.Item label="包装费">{data && data.partner_pack_charge}</Descriptions.Item>
                    <Descriptions.Item label="服务费">{data && data.service_charge}</Descriptions.Item> */}
                    

                    {/*<Descriptions.Item label="付款时间">{data && data.pay_at && data.pay_at.date.substring(0, 19)}</Descriptions.Item>*/}

                    {/*<Descriptions.Item label="收款方">{data && data.payee && data.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>*/}


                    {/*<Descriptions.Item label="支付方式">{data && data.pay_type}</Descriptions.Item>*/}




                    <Descriptions.Item label="订单状态">{order_status}</Descriptions.Item>
                    {
                        data && data.order_receiving_at && data.order_receiving_at.date &&
                        <Descriptions.Item label="接单时间">{data.order_receiving_at.date.substring(0, 19)}</Descriptions.Item>
                    }
                    {
                        data && data.service_end_at && data.service_end_at.date &&
                        <Descriptions.Item label="消费时间">{data && data.service_end_at && data.service_end_at.date.substring(0, 19)}</Descriptions.Item>
                    }


                    {/*
                        data && data.is_eatmoon == 1 &&
                        <Descriptions.Item label="来源">eatmoon</Descriptions.Item>
                        */
                    }
                    


                    {
                        data &&
                        <Descriptions.Item label="订单类型">{orderType}</Descriptions.Item>
                    }


                    {
                        data &&
                        <Descriptions.Item label="订单来源">{orderSource}</Descriptions.Item>
                    }


                    {
                        data &&
                        <Descriptions.Item label="缺货处理方式">{nostockTypeNote}</Descriptions.Item>
                    }







                    {/* {
                        data && data.service_at && data.service_at.date &&
                        <Descriptions.Item label="到店时间">{data.service_at.date.substring(0, 19)}</Descriptions.Item>
                    } */}

                    {
                        data && data.remark &&
                        <Descriptions.Item label="备注">{data.remark}</Descriptions.Item>
                    }

                </Descriptions>
                <Divider />





                <Descriptions title="付款信息">
                     
                    
 
                    <Descriptions.Item label="支付状态">{data && data.is_pay === 0 ? "未付款" : "已付款"}</Descriptions.Item>
                    
                    
                    

                  
                    <Descriptions.Item label="付款时间">{data && data.pay_at && data.pay_at.date.substring(0, 19)}</Descriptions.Item>
                    <Descriptions.Item label="收款方">{data && data.payee && data.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>
                    
                    <Descriptions.Item label="支付方式">{data && data.pay_type}</Descriptions.Item>
                    

                    
                    <Descriptions.Item label="支付凭证">{data && data.stripe_pay_no}</Descriptions.Item>

                     

                </Descriptions>
                <Divider />










                <Descriptions title="用户">
                    <Descriptions.Item label="uuid">{data && data.user && data.user.uuid}</Descriptions.Item>
                    <Descriptions.Item label="昵称">{data && data.user && data.user.name}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{data && data.user && data.user.phone}</Descriptions.Item>
                    
                </Descriptions>
                <Divider />
                {
                    data && data.shareUser &&
                    <div>
                        <Descriptions title="分享用户">
                            <Descriptions.Item label="uuid">{data.shareUser.uuid}</Descriptions.Item>
                            <Descriptions.Item label="昵称">{data.shareUser.name}</Descriptions.Item>
                            <Descriptions.Item label="手机号">{data.shareUser.phone}</Descriptions.Item>
                            <Descriptions.Item label="邮箱">{data.shareUser.email}</Descriptions.Item>
                        </Descriptions>
                        <Divider />
                    </div>
                }

                {
                    data && data.address &&
                    <Descriptions title="用户地址">
                        <Descriptions.Item label="名称">{data.username}</Descriptions.Item>
                        <Descriptions.Item label="电话">{data.phone}</Descriptions.Item>
                        <Descriptions.Item label="用户地址">{data.address}</Descriptions.Item>

                    </Descriptions>
                }
                {
                    data && data.address &&
                    <Divider />
                }
                <Descriptions title="套餐信息">
                    <Descriptions.Item label="套餐id">{data && data.orderPartnerMoney && data.orderPartnerMoney.package_id}</Descriptions.Item>
                    <Descriptions.Item label="套餐名称">{data && data.orderPartnerMoney && data.orderPartnerMoney.package && data.orderPartnerMoney.package.name}</Descriptions.Item>
                </Descriptions>

                <Divider />

                {
                    userCoupon &&
                    <div>
                        <Descriptions title="优惠券信息">
                            <Descriptions.Item label="优惠券adid">{userCoupon.coupon.adid}</Descriptions.Item>
                            <Descriptions.Item label="优惠券名称">{userCoupon.coupon.name}</Descriptions.Item>
                            <Descriptions.Item label="营销类型">{coupon_type}</Descriptions.Item>
                            <Descriptions.Item label="运营类型">{userCoupon.coupon.coupon_type == 0 ? "商品优惠券" : "店铺优惠券"}</Descriptions.Item>
                            <Descriptions.Item label="使用类型">{operation_type}</Descriptions.Item>
                            <Descriptions.Item label="用户使用类型">{user_operation_type}使用</Descriptions.Item>




                            <Descriptions.Item label="使用状态">{coupon_status}</Descriptions.Item>
                            <Descriptions.Item label="Ticket code">{userCoupon.bar_code}</Descriptions.Item>
                        </Descriptions>
                        <Divider />
                    </div>
                }




                {
                    userCoupon &&
                    <div>
                        <Descriptions title="会员折扣信息"> 
                            
                            <Descriptions.Item label="营销类型">会员折扣</Descriptions.Item> 
                            <Descriptions.Item label="折扣金额"></Descriptions.Item> 
                        </Descriptions>
                        <Divider />
                    </div>
                }



                {
                    data.use_user_points_money &&
                    <div>
                        <Descriptions title="积分使用"> 
                            
                            <Descriptions.Item label="营销类型">积分折扣</Descriptions.Item> 
                            <Descriptions.Item label="折扣金额">${data.use_user_points_money}</Descriptions.Item> 
                            <Descriptions.Item label="折扣金额">使用积分</Descriptions.Item> 
                            <Descriptions.Item label="折扣金额">{data.use_user_points}</Descriptions.Item> 
                        </Descriptions>
                        <Divider />
                    </div>
                }



                


                {
                    data?.spu_discount_id > 0 &&
                    <div>
                        <Descriptions title="Deal信息">

                        <Descriptions.Item label="Deal名称">
                            {
                                data?.orderSpu?.data[0]?.attach_data?.spu_discount.name
                            }
                        </Descriptions.Item>
                         
                            
                        <Descriptions.Item label="Deal adid">
                            {
                                data?.orderSpu?.data[0]?.attach_data?.spu_discount.adid
                            }
                        </Descriptions.Item>
                         

 
                            
                        <Descriptions.Item label="Discount off">
                            {
                                spu_discount && spu_discount.discount_type === "fixed" ? "$" + spu_discount.discount_on_fixed : parseFloat(spu_discount.discount_on_percentage) * 100 + "%" + " off"
                            }
                        </Descriptions.Item>
                         

 
                        </Descriptions>
                        <Divider />
                    </div>
                }





                <Descriptions title="客户付款金额">

                    <Descriptions.Item label="商品小计">${data && data.spu_discount_price}</Descriptions.Item>
                    <Descriptions.Item label="商品重量">{data && weight_total} Kg</Descriptions.Item> 

                    {data.consumption_type == 1 && <Descriptions.Item label="运费">${data && data.delivery_price}</Descriptions.Item> }

                    {
                        data && data.coupon_reduction_price > 0 &&
                        <Descriptions.Item label="优惠券抵扣">-${data.coupon_reduction_price}</Descriptions.Item>
                    }
                    
                    <Descriptions.Item label="含GST">${order_gst_inc}</Descriptions.Item>
                    <Descriptions.Item label="不含GST">${order_gst_excl}</Descriptions.Item>
                    <Descriptions.Item label={<span>GST Total<Icon type="question-circle" style={{ marginLeft: "5px" }} 
                            onClick={() => {
                                this.setState({
                                    gstVisible: true
                                })
                            }} 
                            /></span>}>${order_gst_total} 
                    </Descriptions.Item>

                    {data.use_user_money > 0 && <Descriptions.Item label="钱包抵扣">-${data && data.use_user_money}</Descriptions.Item> }
                    <Descriptions.Item label="用户系统手续费(用户给系统支付的手续费)">${data && data.system_user_fee}</Descriptions.Item>
                    <Descriptions.Item label="支付通道手续费">${data && data.transaction_fee}</Descriptions.Item>
                    

                    {data.is_pay != 1 && <Descriptions.Item label="合计">${data && parseFloat(data.price).toFixed(2)}</Descriptions.Item> }

                    


                    <Descriptions.Item label="实际付款金额">${data && data.pay_total_price}</Descriptions.Item>

                     

                    {/* <Divider />  */}


                </Descriptions>



                 <Divider />

                



                 <Descriptions title="商家收款金额">

                    <Descriptions.Item label="商品小计">${data && data.spu_discount_price}</Descriptions.Item> 

                    {data.consumption_type == 1 && <Descriptions.Item label="运费">${data && data.delivery_price}</Descriptions.Item> }

                    {
                        data && data.coupon_reduction_price > 0 &&
                        <Descriptions.Item label="优惠券抵扣">-${data.coupon_reduction_price}</Descriptions.Item>
                    }
                    
                     
                    <Descriptions.Item label="不含GST">${order_gst_excl}</Descriptions.Item> 

                    {data.use_user_money > 0 && <Descriptions.Item label="钱包抵扣">-${data && data.use_user_money}</Descriptions.Item> }
                    <Descriptions.Item label="用户系统手续费(用户给系统支付的手续费)">${data && data.system_user_fee}</Descriptions.Item>
                    
 

                    <Descriptions.Item label="商家代收 / 应收">${data && parseFloat(data.price).toFixed(2)}</Descriptions.Item>

                     

                    {/* <Divider />  */}


                </Descriptions>



                 <Divider />






                

                 <Descriptions title="系统服务费">   

                      
                    <Descriptions.Item label={ 

                     (data.spu_discount_id > 0 || data.is_user_coupon > 0) ? "活动服务费" : "商品服务费" 

                      

                     }   >

                    {data && data.orderPartnerMoney && data.orderPartnerMoney.partner_charge_rate}%

                    ({rate_type})
                    




                    </Descriptions.Item>


 
                    <Descriptions.Item label= "定额服务费" >
                    ${data && data.orderPartnerMoney && data.orderPartnerMoney.partner_fixed_fee || 0}
                    </Descriptions.Item>


                    {
                       (data.spu_discount_id > 0 || data.is_user_coupon > 0) &&

                        <Descriptions.Item label= "折扣金额" >
                        ${data && data.orderPartnerMoney && data.reduction_price || 0}
                        </Descriptions.Item> 
                    }

 

                    <Descriptions.Item label="服务费合计">
                    ${data && data.orderPartnerMoney && data.orderPartnerMoney.partner_charge_money || 0}
                    </Descriptions.Item>



                        
                </Descriptions>


                <div style={{ fontSize:"13px" }} >

                <h5 style={{ fontSize:"13px"}} >服务费说明</h5>
               
               <div style={{ display:'flex'}} >

                <div style={{ paddingRight:"15px"}} >定额服务费(套餐) ${data && data.orderPartnerMoney && data.orderPartnerMoney.package && data.orderPartnerMoney.package.order_fixed_fee}</div>
                <div style={{ paddingRight:"15px"}} >商品服务费(套餐) {data && data.orderPartnerMoney && data.orderPartnerMoney.package && data.orderPartnerMoney.package.rate}%</div>
                <div style={{ paddingRight:"15px"}} >活动服务费(套餐) {data && data.orderPartnerMoney && data.orderPartnerMoney.package && data.orderPartnerMoney.package.activity_service_rate}%</div>
                </div>


                


                </div>


 
 

                {
                    data?.order_status === "refunded" && <Divider />
                }

                {
                    data?.order_status === "refunded" &&
                    <Descriptions title="退款详情">

                        <Descriptions.Item label="退款到">
                            {
                                data?.is_refunded == 1 ?
                                    <Button
                                        type='link'
                                        onClick={() => this.props.history.push("/userRefundDetail/" + data.oneUserWithdrawLog.id)}
                                    >
                                        C端用户付款银行卡
                                    </Button> : data?.is_pay == 2 ? "C端用户付款银行卡" : "C端用户付款银行卡"
                            }
                        </Descriptions.Item>


                        <Descriptions.Item label="退款时间">
                            {
                                data?.is_refunded == 1 ? data?.user_withdraw_at?.date.substring(0, 19) : data?.is_pay == 2 ? data?.refund_at?.date.substring(0, 19) : ""
                            }
                        </Descriptions.Item>




                        <Descriptions.Item label="退款金额">
                            ${data?.refund_price}
                        </Descriptions.Item>


                        {
                            
                            <Descriptions.Item label="处理来源">
                                {data?.refund_source}
                            </Descriptions.Item>
                        }


                        {
                            
                            <Descriptions.Item label="处理人">
                                 {data?.refund_user}
                            </Descriptions.Item>
                        }




                        {
                            data.refund_message &&  
                            <Descriptions.Item label="备注">
                                {data?.refund_message}
                            </Descriptions.Item>
                        }






                    </Descriptions>
                }
                <Divider />



                <Descriptions title="商品"></Descriptions>
                <Table
                    rowKey='id'
                    dataSource={data && data.orderSpu && data.orderSpu.data}
                    columns={columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />





                {
                        data?.is_refund_goods == "1" && data?.order_status !== "refunded" &&  <Divider />
                    }
                    
                    {
                        data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" &&
                        <Descriptions title="订单部分退款">

                            <Descriptions.Item label='退款到'>
                                {
                                    data?.is_refunded == 1 ?
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                this.setState({
                                                    is_show: true
                                                })
                                            }}
                                        >
                                            '银行卡'
                                        </Button>
                                        : data?.is_pay == 2 ? '' : "C端用户付款的银行卡"
                                }
                            </Descriptions.Item>


                            

                            <Descriptions.Item label="部分退款总金额">
                                ${data?.is_refund_goods_total_price}
                            </Descriptions.Item>


 
                        
                        </Descriptions>


                        


                    }

                    {
                        data?.refundPartPayLog && data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" &&
                        <div style={{marginTop:'20px'}}></div>
                    }

                    {
                        data?.refundPartPayLog && data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" &&

                        <Descriptions title='部分退款记录'></Descriptions>
                    }

                    {
                        data?.refundPartPayLog  && data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" && 

                        <Table
                            rowKey={record => record.id}
                            dataSource={data.refundPartPayLog && data.refundPartPayLog.data}
                            columns={columnsRefund}
                            pagination={{
                                hideOnSinglePage: true
                            }}
                        />
                    }























                {
                    (data.order_status == 'be_delivery'  &&  data?.consumption_type == '1' ) &&  
                    <div> 
                        <Divider />    
                        <Descriptions title="发货信息"> 
                            <Descriptions.Item label="快递名称" > {data?.delivery_company_name}  </Descriptions.Item>
                            <Descriptions.Item label="快递单号" > {data?.delivery_no} </Descriptions.Item>
                            <Descriptions.Item label="快递单号追踪" > {data?.delivery_track} </Descriptions.Item>
                            <Descriptions.Item label="商家留言" > {data?.delivery_partner_message} </Descriptions.Item>
                            <Descriptions.Item label="备注"> {data?.delivery_partner_note} </Descriptions.Item>
                            <Descriptions.Item label="附件"> <a href={data.delivery_image} target="_blank"><img style={{ width: "100px" }} src={data.delivery_image} alt="" /></a> </Descriptions.Item> 
                            <Descriptions.Item label="发货时间"> {data?.delivery_at?.date.substring(0, 10)} </Descriptions.Item>
                        </Descriptions>
                    </div> 
                }


                    

            </Card> 
           

            <Modal
                title="确认部分退款"
                visible={refundvisibleGoods} 
                onCancel={() => {
                    this.setState({
                        refundvisibleGoods: false,
                        refund_spu_id: "",
                        refund_message_part: "",
                        refund_spu_num:"",
                    }, () =>{
                            this.handleReset();  
                    } )
                }}
                onOk={() => {

                    OrderRefundGoods(data.id, { refund_message_part,refund_goods_price,refund_spu_id,refund_spu_num }).then(res => {
                        

                        setTimeout(() => {
                             this.setState({
                                refundvisibleGoods: false
                            }, () =>  {
                                    this.getDetail() ;
                                    this.handleReset();  
                                } 
                            ) 
                         }, 3000)
                        
                    }).catch(error => {
                        this.setState({ 
                            refundvisibleGoods: false
                        })
                    })



                }}
                >
                    
                    <Form.Item
                    label={
                        <span>
                            商品数量
                            <Tooltip title="不能超过用户购买的最大商品数量">
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>

                    }
                >
                        {getFieldDecorator('refund_spu_num', {
                            initialValue: refund_quantity,
                            rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: "请输入整数" }],
                        })(<Input type="number" onBlur={(e) => {
                                 this.setState({
                                    refund_spu_num: e.target.value
                                })    

                        }}/>)}
                    </Form.Item>

 
                    <TextArea
                        value={refund_message_part}
                        placeholder="请输入管理员备注（客户不可见）"
                        onChange={(e) => {
                            this.setState({
                                refund_message_part: e.target.value
                            })
                        }}
                    />
                </Modal>



           <Modal
                title="确认退款"
                visible={refundvisible}

                onCancel={() => {
                    this.setState({
                        refundvisible: false, 
                        refund_message: ""
                    })
                }}
                onOk={() => {
                    OrderRefund(data.id, { refund_message }).then(res => {
                         
                           
                        


                        setTimeout(() => {
                             this.setState({
                                refundvisible: false
                            }, () =>  
                                this.getDetail() 
                            ) 
                         }, 3000)


                             
 

                    }).catch(error => {
                        this.setState({ 
                            refundvisible: false
                        })
                    })
                }}
            >
                <TextArea
                    value={refund_message}
                    placeholder="请输入管理员备注（客户不可见）"
                    onChange={(e) => {
                        this.setState({
                            refund_message: e.target.value
                        })
                    }}
                />
            </Modal>


            <Modal
                title="GST 说明"
                visible={this.state.gstVisible}
                footer={null}
                onOk={() => { 
                    
                }}
                onCancel={() => {
                    this.setState({ 
                        gstVisible: false
                    })
                }} 
                > 
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" ,flexDirection: 'column' }} > 
                    <span>带<img src={gsthave}  style={{width:'15px',height:'15pxr'}} />的商品，价格含gst</span>
                    <span>带<img src={gstno}    style={{width:'15px',height:'15pxr'}} />的商品，价格不含gst</span>
                    <span>不带图标的商品，价格不存在gst</span>   
                </div>  
             </Modal> 






            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}> 


                { ( data.is_pay == 1  &&  data.order_status != 'refunded'  )   && 
                         
                        <Button 
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                  this.setState({
                                    refundvisible: true,
                                    //ItemId: record.id,
                                    refund_message: ""
                                })
                            }}
                        >
                            退款
                        </Button>
                 
                }



                { ( data.is_finish == 0  &&  data.order_status != 'refunded'  )   && 
                    <Popconfirm
                        title="确认完成订单？"
                        onConfirm={() => {
                             confirmOrderOrder({ "unique_code": data.unique_code }).then(res => {
                                this.getDetail() 
                            })
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                
                            }}
                        >
                            确认完成
                        </Button>
                    </Popconfirm>
                }  
            </div>

             </div>

        );
    }
}

//export default withRouter(PlugInList)
export default withRouter(Form.create()(PlugInList))
