import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, DatePicker } from 'antd';
import { GetPartnerMonthlyFeet } from "../../action/authAction";


import intl from 'react-intl-universal';
import moment from 'moment';
import { getLangType } from "../../util/function";
let currency_symbol = sessionStorage.getItem("currency_symbol")

const { MonthPicker } = DatePicker;
class OrderFeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: "",
            date: "",
            url: ""
        };

        let currency_symbol = '$';

        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '6%',
                align: "center",
            },
            {
                title: "订单编号",
                width: '15%',
                align: "center",
                render: (record) => {
                    return <Button
                        type='link'
                        onClick={() => {
                            this.props.history.push("/OrderDetail/" + record.order.id)
                        }}
                    >
                        {record.spu_discount_id && "【Deal】"}
                        {record.is_user_coupon && "【Coupon】"}
                        {record.order && record.order.order_sn}
                    </Button>
                }
            },
            {
                title: "名称",
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: "金额",
                dataIndex: 'free',
                width: '10%',
                align: "center",
                render: (record) => {
                    let value = ""
                    if (!!record[0] && record[0] === '-') {

                        value = "-" + currency_symbol + record.slice(1)
                    } else {
                        value = currency_symbol + record
                    }
                    return <span>{value}</span>
                }
            },
            // {
            //     title: intl.get('nav.PayRoute'),
            //     dataIndex: 'payee',
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return <span>{record}</span>
            //     }
            // },
            {
                title: "状态",
                dataIndex: "type",
                width: '10%',
                align: "center",
                render: (record) => {
                    return <span>{record === "order_price" ? intl.get("home.table.useStatus.paid") : intl.get("home.table.useStatus.refunded")}</span>
                }
            },
            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"),
            //     dataIndex: "order",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
            //     }
            // },
            {
                title: "收款方",
                dataIndex: 'payee',
                width: '10%',
                align: "center",
            },
            {
                title: "备注",
                dataIndex: 'explain',
                width: '10%',
                align: "center",
            },

            {
                title: "创建时间",
                dataIndex: 'created_at',
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },

        ];
    }
    componentDidMount() {
        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        let res = await GetPartnerMonthlyFeet(page, "&type=order_price" + this.state.url, this.state.date)
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }

    expandedRowRender = (record) => {
        const columns = [
            {
                title: "名称",
                dataIndex: "name",
                width: '10%',
                align: "center"
            },
            {
                title: "金额类型",
                dataIndex: "money_type",
                width: '10%',
                align: "center"
            },
            {
                title: "金额",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.symbol + currency_symbol + record.money
                }
            },


        ];

        const data = record.order_price_data;
        return <Table columns={columns} className="extraTable" bordered rowKey={record => record.id} dataSource={data} pagination={false} />;
    };

    render() {
        let { meta } = this.state
        return (
            <div>
                <div style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" }}>
                    <div>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            全部
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "&payee=system" ? "#58924d" : "", color: this.state.url === "&payee=system" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&payee=system",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            system
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "&payee=own" ? "#58924d" : "", color: this.state.url === "&payee=own" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&payee=own",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            own
                        </Button>
                    </div>
                    <MonthPicker
                        placeholder="Select month"
                        allowClear
                        onChange={(e) => {
                            let date = moment(e).format('YYYY-MM')
                            this.setState({ date }, () => {
                                this.HandleGetData(1)
                            })
                        }} />
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    expandedRowRender={this.expandedRowRender}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(OrderFeeList))

