import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Select } from 'antd';
import { getPrinterList } from "../../../action/AdAction";
import { getLanguageList } from "../../../action/authAction"
class PrinterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], PrinterCategoryData: "", meta: "", language_id: "" };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '打印机品牌',
                dataIndex: ["printerCategory", "brand"],
                width: '10%',
                align: "center",
            },
            {
                title: '打印机型号',
                dataIndex: ["printerCategory", "model"],
                width: '10%',
                align: "center",
            },
            {
                title: '打印机别名',
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: '价格',
                dataIndex: ["printerCategory", "price"],
                width: '10%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '编号',
                dataIndex: "sn",
                width: '10%',
                align: "center",
            },
            {
                title: '添加时间',
                dataIndex: "created_at",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },
            {
                title: '店铺',
                dataIndex: ["partner", "name"],
                width: '10%',
                align: "center",
            },
        ];
    }
    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data

        this.setState({
            LanguageData,
        }, () => {
            this.HandleGetData(1, LanguageData[1].id)
        })

    }

    async HandleGetData(page, language_id) {
        let res = await getPrinterList(page, "", language_id)
        let data = res.data.data
        this.setState({ data: data, meta: res.data.meta.pagination, language_id })
    }


    render() {
        const { meta, LanguageData } = this.state;
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.HandleGetData(1, e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }
                </div>

                <Table
                    rowKey='id'
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current, this.state.language_id)
                    }}
                />
            </div>


        );
    }
}
export default withRouter(Form.create()(PrinterList))
