import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch,Select } from 'antd';
import { getAdvertisementList, editAdvertisement, deleteAdvertisement } from "../../action/shopAction";
import { type } from "../../util/varibales";
// import "./category.less"
import {getLanguageList } from "../../action/authAction";

const EditableContext = React.createContext();

class AdvertisementList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' , LanguageData: "", language_id: "",url: "&type=0", type: 0 };
        this.columns = [
            {
                title: "Id",
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            {
                title: "标题",
                dataIndex: 'title',
                width: '10%',
                align: "center",
            },

            {
                title: "图片",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <img src={record.image} style={{ height: "60px" }} alt="" />
                    )
                }
            },
            {
                title: "广告类型",
                dataIndex: 'type',
                width: '5%',
                align: "center",
                render: (record) => {
                    return record === 1 ? "商家广告" : "EM广告"
                }
            },
            {
                title: "链接",
                dataIndex: 'link',
                width: '10%',
                align: "center",
            },
            {
                title: "是否显示",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.show === 1} onChange={() => {
                            const { id, show } = record
                            let data = { "show": show === 1 ? "0" : "1" }
                            editAdvertisement(id, data).then(res => {
                                this.HandleGetData()
                            })
                        }} />
                    )
                }
            },
            {
                title: "排序",
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            {
                title: "操作",
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    if (record.is_default !== 1) {
                        return <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => {
                                this.props.history.push("/EditAdvertisement/" + record.id)
                            }}>
                                编辑
                            </Button>
                            <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    }

                },
            },

        ];
    }



     async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })
    }




    /*componentDidMount() {
        this.HandleGetData()
    }
    */

    async HandleGetData(id) {

        

        let res = await getAdvertisementList(id,this.state.url);
        this.setState({ data: res.data.data  , language_id: id})



    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.SubmitData(newData, newData[index])


            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    async SubmitData(newData, item) {
        const { id, name, sort } = item
        let data = { "name": name, "sort": sort }
        await editAdvertisement(data, id)
        this.setState({ data: newData, editingKey: '' });
    }


    edit(key) {
        this.setState({ editingKey: key.id });
    }

    async handleDelete(data) {
        await deleteAdvertisement(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        let { LanguageData,url ,type} = this.state
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px",   }}>
                    
                    <div>
                     {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.HandleGetData(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }


                    {/*
                     <Button
                        style={{ margin: "0 5px", backgroundColor: url === "" ? "#1DA57A" : "", color: url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                             
                        }}>
                        全部
                    </Button>*/}
                    


                    
                    <Button
                        style={{ margin : "0 5px", backgroundColor: url === "&type=0" ? "#1DA57A" : "", color: url === "&type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                             

                             //this.setState({ type: 0 });
                             //this.HandleGetData(this.state.language_id)  


                             this.setState({
                                    url: "&type=0"
                                }, () => {
                                    this.HandleGetData(this.state.language_id)  
                                })



                        }}>
                        EM广告
                    </Button>


                    <Button
                        style={{ marginRight: "5px", backgroundColor: url === "&type=1" ? "#1DA57A" : "", color: url === "&type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={(e) =>  {
                            // this.setState({ type: 1 });
                            // this.HandleGetData(this.state.language_id)  


                             this.setState({
                                    url: "&type=1"
                            }, () => {
                                this.HandleGetData(this.state.language_id)  
                            })



                            
                        }}>
                        商家广告
                    </Button>
                    </div>





                    <div>
                        <Button type="primary"
                            onClick={() => this.props.history.push("/NewAdvertisement")}
                        >
                            新建
                        </Button>
                    </div>


                </div>
                <Table
                    rowKey='id'
                    // bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </EditableContext.Provider>
        );
    }
}

export default withRouter(Form.create()(AdvertisementList))

