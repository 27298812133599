import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Modal, Input, Select, Switch } from 'antd';
import { getPrinterCategoryList, getPrinterList, getPrinterStatus, AddPrinter, UpdatePrinter, deletePrinter, getPrinterDetail } from "../../../action/AdAction";
import { getLanguageList } from "../../../action/authAction"
import { getShopDetail, putShop } from "../../../action/shopAction"


class PrinterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            PrinterCategoryData: "",
            visible: false,
            itemData: "",
            selectData: "",
            LanguageData: "",
            language_id: "",
            printer_id: "",
            is_print_off: false
        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '打印机品牌',
                dataIndex: ["printerCategory", "brand"],
                width: '10%',
                align: "center",
            },
            {
                title: '打印机型号',
                dataIndex: ["printerCategory", "model"],
                width: '10%',
                align: "center",
            },
            {
                title: '打印机别名',
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: '价格',
                dataIndex: ["printerCategory", "price"],
                width: '10%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: '编号',
                dataIndex: "sn",
                width: '10%',
                align: "center",
            },
            // {
            //     title: "自动打印",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch
            //                 checked={record.is_auto_print === 1}
            //                 onChange={() => {
            //                     UpdatePrinter(record.id, { "is_auto_print": record.is_auto_print === 1 ? "0" : "1" }).then(res => {
            //                         this.HandleGetData()
            //                     })
            //                 }} />
            //         )
            //     }
            // },
            {
                title: '添加时间',
                dataIndex: "created_at",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },
            // {
            //     title: '状态',
            //     dataIndex: "status",
            //     width: '10%',
            //     align: "center",
            // },

            {
                title: '操作',
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => this.edit(record.id, this.state.language_id)}>
                                编辑
                            </Button>
                            <Popconfirm title="确定删除" onConfirm={() => this.handleDelete(record.id)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }
    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data

        this.setState({
            LanguageData,
            language_id: LanguageData[1].id
        }, () => {
            this.HandleGetData(LanguageData[1].id)
            this.HandleGetShopDetail(LanguageData[1].id)
        })
    }

    async HandleGetData(language_id) {
        const id = this.props.location.pathname.substring(13)
        let value = "";
        if (id) {
            value = "&partner_id=" + id
        }
        let PrinterCategory = await getPrinterCategoryList(language_id)
        let res = await getPrinterList(1, value, language_id)
        let data = res.data.data
        if (this.state.id) {
            this.edit(this.state.id, language_id)
        }
        this.setState({ data: data, PrinterCategory: PrinterCategory.data.data })
    }

    async HandleGetShopDetail(language_id) {
        const id = this.props.location.pathname.substring(13)
        let detail = await getShopDetail(id, language_id)
        this.setState({ is_print_off: detail.data.is_print_off === 1 })
    }

    edit(id, language_id) {
        if (id) {
            getPrinterDetail(id, language_id).then(res => {
                this.setState({
                    visible: true,
                    printer_id: id,
                    itemData: res.data,
                    selectData: res.data.printerCategory,
                });
            })
        }

    }
    handleCancel = () => {
        this.setState({
            visible: false,
            itemData: '',
            selectData: '',
            id: ""
        });
    };

    async handleDelete(id) {
        await deletePrinter(id)
        this.HandleGetData(this.state.language_id)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { itemData } = this.state;
        const id = this.props.location.pathname.substring(13)
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.request_language_id = values.request_language_id
            if (!err) {
                if (id) {
                    values.partner_id = id
                }
                if (itemData && itemData.id) {
                    //修改-注意参数
                    UpdatePrinter(itemData.id, values).then(res => {
                        this.HandleGetData(this.state.language_id)
                        this.setState({
                            visible: false,
                            itemData: '',
                            selectData: '',
                            id: ""
                        });
                    })
                } else {
                    //新增
                    AddPrinter(values).then(res => {
                        this.HandleGetData()
                        this.setState({
                            visible: false,
                            itemData: '',
                            selectData: '',
                            id: ""
                        });
                    })
                }
            }
        });
    };

    onPrintOffChange = () => {
        const id = this.props.location.pathname.substring(13)
        putShop(id, { is_print_off: this.state.is_print_off ? "0" : "1" }).then(res => {
            this.HandleGetShopDetail(this.state.language_id)
        })
    }

    render() {
        const { itemData, PrinterCategory, selectData, LanguageData, visible, language_id, is_print_off } = this.state;
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.HandleGetData(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }
                    <div>
                        
                        {/*
                           <span>开启打印机：</span>
                                <Switch
                            style={{ marginRight: "40px" }}
                            checked={is_print_off} onChange={() => this.onPrintOffChange()} /> 


                        */}
                        



                        <Button type="primary"
                            onClick={() => this.setState({ visible: true })}
                        >
                            添加
                        </Button>
                    </div>

                </div>
                <Table
                    rowKey='id'
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
                {
                    visible && <Modal
                        destroyOnClose
                        title={itemData.id ? '编辑打印机' : '新建打印机'}
                        visible={visible}
                        onOk={this.handleSubmit}
                        onCancel={this.handleCancel}
                    >
                        <Form onSubmit={this.handleSubmit} className="waperBox" {...formItemLayout}>
                            <Form.Item
                                label="选择录入的语言"
                            >
                                {getFieldDecorator('request_language_id', {
                                    rules: [{ required: true, message: '请选择语言!' }],
                                    initialValue: language_id ? language_id : LanguageData[1].id
                                })(
                                    <Select
                                        onChange={(e) => {
                                            this.edit(this.state.id, e)
                                        }}
                                    >
                                        {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            {
                                itemData ? <Form.Item
                                    label="打印机id"
                                >
                                    {getFieldDecorator('printer_category_id', {
                                        rules: [{ required: true, message: '请选择打印机id!' }],
                                        initialValue: itemData && itemData.printer_category_id
                                    })(
                                        <Select
                                            onChange={(value, e) => {
                                                let select = e.props.select
                                                itemData.remark = select && select.remark
                                                itemData.name = select && select.name
                                                this.setState({
                                                    selectData: select,
                                                    itemData
                                                })
                                            }}
                                        >
                                            {PrinterCategory && PrinterCategory.map(el => <Select.Option key={el.id} select={el} value={el.id}>{el.id}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item> : <div>
                                    <Form.Item
                                        label="打印机id"
                                    >
                                        {getFieldDecorator('printer_category_id', {
                                            rules: [{ required: true, message: '请选择打印机id!' }],
                                        })(
                                            <Select
                                                placeholder="打印机id"
                                                onChange={(value, e) => {
                                                    let select = e.props.select
                                                    let itemData = {}
                                                    itemData.remark = select && select.remark
                                                    itemData.name = select && select.name
                                                    this.setState({
                                                        selectData: select,
                                                        itemData
                                                    })
                                                }}
                                            >
                                                {PrinterCategory && PrinterCategory.map(el => <Select.Option key={el.id} select={el} value={el.id}>{el.id}</Select.Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>
                            }
                            {
                                selectData &&
                                <Form.Item
                                    label='打印机品牌'
                                >
                                    <Input disabled value={selectData.brand} />
                                </Form.Item>
                            }
                            {
                                selectData &&
                                <Form.Item
                                    label='打印机型号'
                                >
                                    <Input disabled value={selectData.model} />
                                </Form.Item>
                            }
                            {
                                selectData &&
                                <Form.Item
                                    label='默认名称'
                                >
                                    <Input disabled value={selectData.name} />
                                </Form.Item>
                            }


                            <Form.Item
                                label='打印机别名'
                            >
                                {getFieldDecorator('name', {
                                    rules: [{ required: false, whitespace: true }],
                                    initialValue: itemData && itemData.name
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item
                                label='打印机编号'
                            >
                                {getFieldDecorator('sn', {
                                    rules: [{ required: true, message: '请输入打印机编号!', whitespace: true }],
                                    initialValue: itemData && itemData.sn
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item
                                label='密钥'
                            >
                                {getFieldDecorator('key', {
                                    rules: [{ required: true, message: '请输入密钥!', whitespace: true }],
                                    initialValue: itemData && itemData.key
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item
                                label='备注'
                            >
                                {getFieldDecorator('remark', {
                                    initialValue: itemData && itemData.remark
                                })(<Input />)}
                            </Form.Item>
                        </Form>
                    </Modal>
                }

            </div>


        );
    }
}
export default withRouter(Form.create()(PrinterList))
