import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Form, Button } from 'antd';
import { getSystemRechargeList } from "../../action/shopAction";

class SystemRecharge extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], url: "", meta: "" };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '用户id',
                dataIndex: 'user_id',
                width: '5%',
                align: "center",
            },
            {
                title: '用户名',
                dataIndex: ["user", "name"],
                width: '10%',
                align: "center",
            },
            {
                title: '充值金额',
                dataIndex: 'money',
                width: '10%',
                align: "center",
                render: (record) => {
                    return "$" + record
                }
            },
            {
                title: '充值时间',
                dataIndex: "created_at",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },
            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     width: '10%',
            //     align: "center",
            //     render: (text, record) => {

            //         return (
            //             <div>
            //                 <Button onClick={() => this.props.history.push("/userRefundDetail/" + record.id)}>
            //                     查看
            //                 </Button>
            //             </div>

            //         );
            //     },
            // },

        ];
    }

    async componentDidMount() {
        this.HandleGetData(1)
    }

    HandleGetData = async (page) => {
        let res = await getSystemRechargeList(page);
        this.setState({ data: res.data.data, meta: res.data.meta.pagination, })
    }




    render() {
        let { meta } = this.state
        return (
            <Table
                rowKey='id'
                // components={components}
                // bordered
                dataSource={this.state.data}
                columns={this.columns}
                className="waperBox"
                rowClassName="editable-row"
                pagination={{
                    pageSize: meta.per_page,
                    total: meta.total
                }}
                onChange={(e) => {
                    this.HandleGetData()
                }}
            />
        );
    }
}

export default withRouter(Form.create()(SystemRecharge))

