import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button } from 'antd';
import { getPlugInList, delPlugIn } from "../../action/authAction";

class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: '插件名称',
                dataIndex: 'name',
                width: '15%',
            },{
                title: '插件识别KEY',
                dataIndex: 'plugin_key',
                width: '15%',
             }, 
            //{
            //     title: '适用客户端',
            //     dataIndex: 'client',
            //     width: '8%',
            // }, {
            //     title: '识别路由',
            //     dataIndex: 'route',
            //     width: '16%',
            // },
             
             {
                title: '每月价格',
                dataIndex: 'price',
                width: '10%',
                render: (record) => {
                    return <span>${record}</span>
                }
            }, {
                title: '排序',
                dataIndex: 'sort',
                width: '10%',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '20%',
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button onClick={() => {
                                this.props.history.push("/PlugInEdit?" + record.id)
                            }}
                                style={{ marginRight: 15 }}
                                type="primary"
                            >
                                编辑
                            </Button>
                            <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                                <Button type="danger" ghost>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        let res = await getPlugInList()
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        await delPlugIn(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        return (
            <Table
                rowKey='id'
                // bordered
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }}
                className="waperBox"
            />

        );
    }
}

export default withRouter(Form.create()(PlugInList))

