import React from "react";
import { withRouter } from 'react-router-dom'
import { Input, Table, Form, Button, Modal, Switch, InputNumber, message } from 'antd';
import { getUserList, UpdateUser, sendUpdatePayParamCodeMail } from "../../action/authAction";
import "../../style/common.less"
const { Search } = Input;


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            passwordVisible: false,
            newPassword: "",
            confirmPassword: "",
            isError: false,
            itemId: "",
            page: 1,
            moneyVisible: false,
            money: "",
            ItemId: "",
            code: "",
            update_pay_param_code:"",
            url:"",
            url_1:"",
            url_2:"",
            url_3:"", 

        };
        this.columns = [

            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center"
            },
            {
                title: '用户头像',
                width: '1%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: "cover" }} src={res.head_portrait} alt="" />
                    )
                }
            },

            {
                title: '昵称',
                dataIndex: 'name',
                width: '10%',
                align: "center",
            }, {
                title: '手机号码',
                dataIndex: 'phone',
                width: '10%',
                align: "center"
            },{
                title: '邮箱',
                dataIndex: 'email',
                width: '10%',
                align: "center"
            },

            {
                title: 'UUID',
                dataIndex: 'uuid',
                width: '5%',
                align: "center"
            },

         

            /*
            {
                 title: '成长值',
                 dataIndex: 'grow_up',
                 width: '5%',
                 align:"center"
             },
             {
                 title: '账户余额',
                 dataIndex: 'money',
                 width: '10%',
                 align:"center"
             },
             */

            {
                title: '超过退款限额',
                width: '10%',
                align: "center",
                render: (record) => {
                    return <Switch checked={record.is_exceed_single_refund === 1} onChange={() => {
                        const { id, is_exceed_single_refund } = record
                        let data = { "is_exceed_single_refund": is_exceed_single_refund === 1 ? "0" : "1" }
                        UpdateUser(data, id).then(res => {
                            this.GetUserData("")
                        })
                    }} />
                }
            },
            {
                title: '是否开启分享',
                width: '10%',
                align: "center",
                render: (record) => {
                    return <Switch checked={record.is_open_share === 1} onChange={() => {
                        const { id, is_open_share } = record
                        let data = { "is_open_share": is_open_share === 1 ? "0" : "1" }
                        UpdateUser(data, id).then(res => {
                            this.GetUserData("")
                        })
                    }} />
                }
            },
            {
                title: '注销',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <Switch checked={record.logoff === 1} onChange={() => {
                        const { id, logoff } = record
                        let data = { "logoff": logoff === 1 ? "0" : "1" }
                        UpdateUser(data, id).then(res => {
                            this.GetUserData("")
                        })
                    }} />
                }
            },

            {
                title: '钱包余额',
                width: '6%',
                align: "center",
                render: record => {
                    return (
                        <span>${record.money}</span>
                    )
                }
            },
            {
                title: '操作',
                width: '15%',
                align: "center",
                render: (text, record) => {
                    if (record.logoff === 0) {
                        return (
                            <div className='deleteView'>
                                <Button style={{ marginRight: "5px" }} onClick={() => this.showPasswordModal(record)}>
                                    修改密码
                                </Button>
                                <Button style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        this.setState({
                                            moneyVisible: true,
                                            ItemId: record.id,
                                        })
                                    }}>
                                    系统充值
                                </Button>

                                 <Button onClick={() => {
                                    this.props.history.push("/userWalletList?" + record.id)
                                }}>钱包流水</Button>



                                <Button onClick={() => {
                                    this.props.history.push("/userDetail?" + record.id)
                                }}>查看</Button>
                            </div >
                        )
                    }

                }
            },
            // {
            //     title: '店铺名称',
            //     width: '5%',
            //     align:"center",
            //     render:(res)=>{
            //         return(
            //         <span>{res.partner&&res.partner.title}</span>
            //         )
            //     }
            // }
        ];
    }

    async componentDidMount() {
        this.GetUserData("")
    }

    async GetUserData(phone,url="") {
        let res = await getUserList(this.state.page, phone,url)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        // await deleteRecommendShop(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    async onChange(record) {
        let { data } = this.state
        let NewData = data
        let is_show = 0
        if (record.is_show === 1) {
            is_show = 0
        } else {
            is_show = 1
        }
        for (let i in NewData) {
            if (NewData[i].id === record.id) {
                NewData[i].is_show = is_show
                this.setState({
                    data: NewData
                })
                break
            }
        }
        // await putRecommendShop(record.id, { is_show })
    }

    showPasswordModal = (item) => {
        this.setState({
            passwordVisible: true,
            itemId: item.id,
            newPassword: "",
            confirmPassword: "",
            newPasswordError: false,
            confirmPasswordError: false,
        });
    };

    handlePasswordOk = e => {
        const { newPassword, confirmPassword, itemId, newPasswordError, confirmPasswordError } = this.state
        if (newPassword !== confirmPassword) {
            this.setState({
                isError: true
            })
        } else {
            this.setState({
                isError: false
            })
            if (newPassword && confirmPassword && !newPasswordError && !confirmPasswordError) {
                UpdateUser({ "password": newPassword }, itemId).then(res => {
                    this.setState({
                        passwordVisible: false,
                    });
                })
            }
        }
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            passwordVisible: false,
        });
    };

    render() {
        const { data, meta, newPassword, confirmPassword, money, ItemId, code,update_pay_param_code ,url_1,url_2,url_3,url} = this.state
        if (data) {
            return (
                <div>
                    <div  style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    {/*<div className="waperBox searchWaper" > </div>*/} 
                        <div className="searchItem">

                            <Button
                                style={{ marginRight: "5px", backgroundColor:   "", color:  "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: ""  , 
                                        url_1:"",
                                        url_2:"",
                                        url_3:"",
                                        
                                    }, () => {
                                          this.GetUserData(  "",this.state.url)
                                    })
                                }}>
                                全部
                            </Button> 

                            <Search
                                style={{ width: 400 }}
                                placeholder={'请输入手机号'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.GetUserData(val)
                                }}
                            />
                        </div>

                        


                   
                    </div>



                     <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                超过退款限额
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_1 === "&is_exceed_single_refund=1" ? "#1DA57A" : "", color: url_1 === "&is_exceed_single_refund=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_exceed_single_refund=1" + url_2 +  url_3  ,
                                        url_1:"&is_exceed_single_refund=1",
                                         
                                    }, () => {
                                        this.GetUserData( "", this.state.url)
                                    })
                                }}>
                                已超过
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_1 === "&is_exceed_single_refund=0" ? "#1DA57A" : "", color: url_1 === "&is_exceed_single_refund=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_exceed_single_refund=0" + url_2 +  url_3   , 
                                        url_1:"&is_exceed_single_refund=0",
                                        
                                    }, () => {
                                          this.GetUserData(  "",this.state.url)
                                    })
                                }}>
                                未超过
                            </Button> 





                        </div>
                    </div>



                     <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                开启分享
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&is_open_share=1" ? "#1DA57A" : "", color: url_2 === "&is_open_share=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_open_share=1" + url_1 +  url_3   ,
                                        url_2:"&is_open_share=1",
                                         
                                    }, () => {
                                         this.GetUserData( "",this.state.url)
                                    })
                                }}>
                                已开启
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&is_open_share=0" ? "#1DA57A" : "", color: url_2 === "&is_open_share=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_open_share=0" + url_1 +  url_3  , 
                                        url_2:"&is_open_share=0",
                                        
                                    }, () => {
                                        this.GetUserData(  "",this.state.url)
                                    })
                                }}>
                                未开启
                            </Button> 
                        </div>
                    </div>


                     <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                注销
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&logoff=1" ? "#1DA57A" : "", color: url_3 === "&logoff=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&logoff=1" + url_1 +  url_3   ,
                                        url_3:"&logoff=1",
                                         
                                    }, () => {
                                        this.GetUserData(  "",this.state.url)
                                    })
                                }}>
                                未注销
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&logoff=0" ? "#1DA57A" : "", color: url_3 === "&logoff=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&logoff=0" + url_1 +  url_3   , 
                                        url_3:"&logoff=0",
                                        
                                    }, () => {
                                         this.GetUserData( "",this.state.url)
                                    })
                                }}>
                                已注销
                            </Button> 
                        </div>
                    </div>




                    <Table
                        rowKey='id'
                        // bordered
                        dataSource={data}
                        columns={this.columns}
                        className="waperBox"
                        rowClassName={(record) => record.is_exceed_single_refund === 1 ? "userRowBackground" : ""}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e, a, b) => {
                            this.setState({
                                page: e.current
                            }, () => this.GetUserData(""))

                        }}
                    />
                    <Modal
                        title="修改密码"
                        visible={this.state.passwordVisible}
                        onOk={this.handlePasswordOk}
                        onCancel={this.handleCancel}
                    >
                        <div style={{ height: "60px" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>密码:</span>
                                <Input
                                    value={newPassword}
                                    onChange={(e) => {
                                        e.persist()
                                        //let value = e.target.value.replace(/\W/g, '')
                                        let value = e.target.value;

                                        
                                        //密码正则校验
                                        var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                        var result=patt.exec(value);    
                                        if(result == null){  
                                            this.setState({
                                                newPasswordError: true
                                            }) 
                                        }else {
                                            if (value.length > 16 || value.length < 6) {
                                                this.setState({
                                                    newPasswordError: true
                                                })

                                            } else {
                                                this.setState({
                                                    newPasswordError: false
                                                })
                                            }   
                                        }


                                         
                                        this.setState({
                                            newPassword: value
                                        })
                                    }} />
                            </div>
                            {
                                this.state.newPasswordError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                                </div>
                            }
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>确认密码:</span>
                                <Input
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        e.persist()
                                        //let value = e.target.value.replace(/\W/g, '')
                                        let value = e.target.value

                                        //密码正则校验
                                        var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                        var result=patt.exec(value);    
                                        if(result == null){
                                            this.setState({
                                                confirmPasswordError: true
                                            })
                                        }else{
                                            if (value.length > 16 || value.length < 6) {
                                                this.setState({
                                                    confirmPasswordError: true
                                                })
                                            } else {
                                                this.setState({
                                                    confirmPasswordError: false
                                                })
                                            }
                                        } 
                                        
                                        this.setState({
                                            confirmPassword: value
                                        })
                                    }} />
                            </div>
                            {
                                this.state.isError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>两次密码不一致</span>
                                </div>
                            }
                            {
                                this.state.confirmPasswordError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                                </div>
                            }

                        </div>
                    </Modal>
                    <Modal
                        title="请输入要充值的金额"
                        visible={this.state.moneyVisible}
                        onOk={() => {
                            if (money && update_pay_param_code) {
                                UpdateUser({ money,update_pay_param_code }, ItemId).then(res => {
                                    this.setState({
                                        money: "",
                                        moneyVisible: false
                                    }, () => {
                                        this.GetUserData("")
                                    })
                                })
                            } else {
                                message.error("请先填入要充值的金额和验证码")
                            }

                        }}
                        onCancel={() => {
                            this.setState({
                                money: "",
                                moneyVisible: false
                            })
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            <InputNumber
                                max={10000}
                                value={money}
                                formatter={value => `$${value}`}
                                parser={value => value.replace("$", '')}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    this.setState({
                                        money: e
                                    })
                                }} />

                        </div>
                        <span>备注：充值的金额可以为负值</span>
                        <Search
                            style={{ marginTop: "10px" }}
                            placeholder="输入验证码"
                            enterButton="获取验证码"
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    update_pay_param_code: e.target.value
                                })
                            }}
                            onSearch={() => {
                                sendUpdatePayParamCodeMail().then(res => {
                                    console.log(res, "1111")
                                })
                            }}
                        />
                    </Modal>
                </div>
            );
        } else {
            return (<div />)
        }


    }
}


export default withRouter(Form.create()(UserList))

