import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, DatePicker ,Select,Tooltip, Icon, Popover,Popconfirm} from 'antd';
import { GetPartnerStatementList , putPartnerStatementPay,putPartnerStatementSettle,createStatement,deleteStatement } from "../../../action/authAction";
import intl from 'react-intl-universal';
import moment from 'moment';
import { getLangType } from "../../../util/function";
import { getShopList,getShopDetail } from "../../../action/shopAction"
import {getLanguageList } from "../../../action/authAction";
const { Option } = Select;
let currency_symbol = sessionStorage.getItem("currency_symbol")

const { MonthPicker } = DatePicker;
class OrderFeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: "",
            date: "",
            url: "",
            partner_id:"",
            partner_name:"",
            settle_date:"",

            url_1: "",
            url_2: "",
            url_3: "",
           


        };

        let currency_symbol = "$";
        this.columns = [


            {
                title: "ID",
                dataIndex: 'id',
                width: '6%',
                align: "center",
            },


            {
                title: "店铺",
                dataIndex: 'partner.title',
                width: '6%',
                align: "center",
            },



            {
                title: "结算周期",
                width: '15%',
                align: "center",
                dataIndex:'statement_date'
            },




            // {
            //     title: intl.get("home.table.ordernumber"),
            //     width: '15%',
            //     align: "center",
            //     render: (record) => {
            //         return <Button
            //             type='link'
            //             onClick={() => {
            //                 this.props.history.push("/OrderDetail/" + record.order.id)
            //             }}
            //         >
            //             {record.spu_discount_id && "【Deal】"}
            //             {record.is_user_coupon && "【Coupon】"}
            //             {record.order && record.order.order_sn}
            //         </Button>
            //     }
            // },



            // {
            //     title: intl.get('nav.CostFlow.name'),
            //     dataIndex: 'name',
            //     width: '10%',
            //     align: "center",
            // },


            {
                title: "金额",
                dataIndex: 'free',
                width: '10%',
                align: "center",
                render: (record) => {
                    let value = ""
                    if (!!record[0] && record[0] === '-') {

                        value = "-" + currency_symbol + record.slice(1)
                    } else {
                        value = currency_symbol + record
                    }
                    return <span>{value}</span>
                }
            },




            // {
            //     title: intl.get('nav.PayRoute'),
            //     dataIndex: 'payee',
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return <span>{record}</span>
            //     }
            // },



            {
                title: "结算状态",
                //dataIndex: "settle_status",
                width: '10%',
                align: "center",
                render: (record) => {
                    // return  record == "1" ? 
                    //     <span style={{color:'green'}}>已结清</span>:  <span style={{color:'red'}}>未结清</span> 

                    if( record.free  == '0.00'){
                        return <span  >/</span>     
                    }else{
                        if(record.settle_status == "1"){
                            return <span style={{color:'green'}}>已结清</span>
                        }else if(record.settle_status == "0"){
                            return <span style={{color:'red'}}>未结清 </span> 
                        }else if(record.settle_status == "3"){
                            return <span style={{color:'red'}}>校验中 </span> 
                        }else{
                            return <span  >/</span>     
                        }
                    }
                        



                }
            },



            {
                title: "付款状态",
                //dataIndex: "pay_status",
                width: '10%',
                align: "center",
                render: (record) => {
                    // return  record == "1" ? 
                    //     <span style={{color:'green'}}>已付款</span>:  <span style={{color:'red'}}>未付款</span> 

                    if(  record.free  == '0.00'){
                        return <span  >/</span>     
                    }else{
                        if(record.pay_status == "1"){
                            return <span style={{color:'green'}}>已付款</span>
                        }else if(record.pay_status == "0"){
                            return <span style={{color:'red'}}>未付款</span> 
                        }else if(record.pay_status == "3"){
                            return <span style={{color:'red'}}>校验中</span> 
                        }else{
                            return <span style={{color:'red'}}>/</span>     
                        }
                    }



                }
            },


            
            {
                title: "类型", 
                width: '10%',
                align: "center",
                render: (record) => { 
                        if(  record.free  == '0.00'){
                            return <span  >/</span>     
                        }else{
                            return record.free > 0 ?  <span style={{color:'green'}}>商家应收</span>:  <span style={{color:'red'}}>商家应付</span> 
                        }
                        
                }
            },

            {
                title: "是否逾期", 
                width: '10%',
                align: "center",
                render: (record) => { 
                    if(  record.free  == '0.00'){
                        return <span  >/</span>     
                    }else{
                        return  record.settle_status == 0  && ( record.is_overdue == 0  ?  <span style={{color:'green'}}>未逾期</span>:  <span style={{color:'red'}}>已逾期</span> )
                    }

                    
                }
            },

            



            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"),
            //     dataIndex: "order",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
            //     }
            // },




            // {
            //     title: intl.get('home.table.payee'),
            //     dataIndex: 'payee',
            //     width: '10%',
            //     align: "center",
            // },






            {
                title: "备注",
                dataIndex: 'statement_note',
                width: '10%',
                align: "center",
            },

            



            // {
            //     title: "付款",
            //     width: '15%',
            //     align: "center",
            //     render: (record) => {
            //         return <Button
            //                 style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
            //                 onClick={() => {
            //                     this.setState({
            //                         url: "",
            //                     }, () => {
            //                         this.HandleGetData(1)
            //                     })
            //                 }}>
            //                去支付
            //             </Button>


            //     }
            // },





            {
                title: "创建时间",
                dataIndex: 'created_at',
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },


            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                


                                {/* <Popconfirm title={"确认已结清？"} onConfirm={() => 

                                     putPartnerStatementPay(record.id, { settle_status:1 } ).then(res => {  
                                               this.HandleGetData(1) 
                                            })   
                                            
                                }>
                                   <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {  
                                            // putPartnerStatementPay(record.id, { settle_status:1 } ).then(res => {  
                                            //    this.HandleGetData(1) 
                                            // })   
                                    }}


                                    >
                                    已结清
                                </Button>
                                </Popconfirm>


 
                                <Popconfirm title={"确认已付款？"} onConfirm={() => 

                                    putPartnerStatementSettle(record.id, { pay_status:1 } ).then(res => {  
                                               this.HandleGetData(1)
                                            }) 

                                }>
                                    <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                          
                                        // putPartnerStatementSettle(record.id, { pay_status:1 } ).then(res => {  
                                        //        this.HandleGetData(1)
                                        //     }) 

 
                                    }}>已付款</Button>
                                </Popconfirm>
                                */}

                                


                                 <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record)}>
                                    <Button
                                    style={{ marginRight: "5px" }}
                                    >
                                        删除
                                    </Button>
                                </Popconfirm>


                                <Button
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                       this.props.history.push("/statementDetail/" + record.id)
                                }}>详情</Button>




                                
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },





        ];
    }
     async componentDidMount() {


        const shop_id = this.props.location.pathname.substring(15);
 

        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data


        this.setState({ LanguageData }, () => {
            //this.HandleGetData(LanguageData[1].id)
        })



        let resp = await getShopList("", "")
        let data = resp.data.data

        console.log("data==",data);

        if(shop_id){ 


            let shopinfo = await getShopDetail(shop_id,LanguageData[1].id);  
            this.setState({
                shopList: data,
                partner_id: shop_id,
                partner_name:shopinfo.data.title

            }, () => {
                this.HandleGetData(LanguageData[1].id)
            })


        }else{
            this.setState({
                shopList: data,
                //partner_id: "" ,//data[0].id, 
            }, () => {
                this.HandleGetData(LanguageData[1].id)
            })
        }


        



        //this.HandleGetData(1)
    }

    async HandleGetData(page) {


        let res = await GetPartnerStatementList(page, "&partner_id="+this.state.partner_id + this.state.url, this.state.date) //,order_price_refund
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }



    async createStatement(data) { 
        createStatement(data).then(res => {
            this.HandleGetData(1) 
        }) 
    }


    async handleDelete(data) { 
        deleteStatement(data.id).then(res => {
            this.HandleGetData(1) 
        })  
    }


    expandedRowRender = (record) => {
        const columns = [
            {
                title: "名称",
                dataIndex: "name",
                width: '10%',
                align: "left"
            },
            // {
            //     title: intl.get('nav.CostFlow.costType'),
            //     dataIndex: "money_type",
            //     width: '10%',
            //     align: "center"
            // },
            {
                title: "金额",
                width: '10%',
                align: "left",
                render: (record) => {
                    return  record.money
                }
            },


        ];

        const data = record.charge_data;
        return <Table columns={columns} className="extraTable" bordered rowKey={record => record.id} dataSource={data} pagination={false} />;
    };

    render() {
        let { url,url_1,url_2,url_3 , meta,shopList ,LanguageData,partner_id,partner_name,date } = this.state
        return (
            <div>


                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none', marginBottom: "10px" }}>
                   

                     {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                 this.setState({  language_id:e}, 
                                      () => {  this.HandleGetData(e)    } 
                                 )  
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    } 

                    {
                        shopList &&
                        <Select showSearch
                            defaultValue =  {partner_name?partner_name:null}
                            placeholder="请选择店铺"
                            style={{ width: 300, marginLeft: "10px", marginRight: "10px" }}
                            onChange={(e, value) => {
                                this.setState({
                                    partner_id: e,
                                    url_1:"",
                                    url_2:"",
                                    url_3:"",
                                    url_4:"",
                                    url_5:"",
                                    page:1,
                                    
                                }, () => this.HandleGetData(1))

                            }}
                            /*value={partner_id}*/ 
                        >
                            {
                                shopList.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.title}</Option>
                                })
                            }
                        </Select>

                         

                    }
                    
                    <Tooltip title={'请选择店铺'}>
                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                    </Tooltip>
 
                </div>








                <div style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" , alignItems: "center"}}>
                    <div  style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" ,    flexDirection:"column"  }}>


                         <div  style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:  "#fff"  }}
                                onClick={() => {
                                    this.setState({
                                        url: "",
                                        url_1:"",
                                        url_2:"",
                                        url_3:"",
                                        
                                        partner_id:"",
                                        partner_name:"",
                                        created_at:"",
                                        date:"",
                                    }, () => {
                                        this.HandleGetData(1) 
                                    })
                                }}>
                                全部
                            </Button>



                            <Popconfirm
                            title="确认手动生成清单？"
                            onConfirm={() => {
                                    this.setState({
                                        url: "",
                                    }, () => {
                                        this.createStatement({date:this.state.date,partner_id:this.state.partner_id})
                                    })
                            }}>
                            <Button
                                style={{ marginRight: "5px", backgroundColor:   "#1DA57A"  , color:  "#fff"   }}
                                onClick={() => {
                                    // this.setState({
                                    //     url: "",
                                    // }, () => {
                                    //     this.createStatement({date:this.state.date,partner_id:this.state.partner_id})
                                    // })
                                }}>
                                手动生成清单
                            </Button>

                            <Tooltip title={'默认生成上一个月'}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>


                        </Popconfirm>




                             
 
                            </div>




                        {/*<div>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            全部
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "&payee=system" ? "#58924d" : "", color: this.state.url === "&payee=system" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&payee=system",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            system
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "&payee=own" ? "#58924d" : "", color: this.state.url === "&payee=own" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&payee=own",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            own
                        </Button>
                        </div>
                        */}




                         <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                       <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                            onClick={() => {
                               
                            }}>
                            结算状态
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&settle_status=1" ? "#1DA57A" : "", color: url_1 === "&settle_status=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&settle_status=1" + url_2 +  url_3   ,
                                   url_1: "&settle_status=1",
                                   page:1,
                                   created_at:date
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            已结清
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&settle_status=0" ? "#1DA57A" : "", color: url_1 === "&settle_status=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&settle_status=0" + url_2 +  url_3   ,
                                   url_1: "&settle_status=0",
                                   page:1,
                                   created_at:date
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            未结清
                        </Button>

                         

                        

                </div>



                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                       <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:  "#fff"   }}
                            onClick={() => {
                                
                            }}>
                            付款状态
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&pay_status=1" ? "#1DA57A" : "", color: url_2 === "&pay_status=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&pay_status=1" + url_1 +  url_3   ,
                                   url_2: "&pay_status=1",
                                   page:1,
                                   created_at:date
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            已支付
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&pay_status=0" ? "#1DA57A" : "", color: url_2 === "&pay_status=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&pay_status=0" + url_1 +  url_3  ,
                                   url_2: "&pay_status=0",
                                   page:1,
                                   created_at:date
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            未支付
                        </Button>

                         

                        

                </div>



                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                       <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:  "#fff"   }}
                            onClick={() => {
                                 
                            }}>
                            是否逾期
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&is_overdue=1" ? "#1DA57A" : "", color: url_3 === "&is_overdue=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&is_overdue=1" + url_2 +  url_1   ,
                                   url_3: "&is_overdue=1",
                                   page:1,
                                   created_at:date
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            已逾期
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&is_overdue=0" ? "#1DA57A" : "", color: url_3 === "&is_overdue=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                  partner_id: partner_id,
                                   url: "&is_overdue=0" + url_2 +  url_1   ,
                                   url_3: "&is_overdue=0",
                                   page:1,
                                   created_at:date
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            未逾期
                        </Button>

                         

                        

                </div>





                        <div style={{ fontSize:"12px",padding:"10px 0 "}}>备注：每个月8号生成上个月的结算清单，当月22号之前完成清单结算</div>


                    </div>


                    <MonthPicker
                        placeholder="Select month"
                        allowClear
                        onChange={(e) => {
                            let date = moment(e).format('YYYY-MM')
                            this.setState({ date }, () => {
                                this.HandleGetData(1)
                            })
                        }} />
                </div>

                <div  className="waperBox">
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    //expandedRowRender={this.expandedRowRender}
                    columns={this.columns} 
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                />
                </div>

            </div>


        );
    }
}

export default withRouter(Form.create()(OrderFeeList))

