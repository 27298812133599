import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon, InputNumber } from 'antd';
import moment from 'moment';
import { getNoSkuGoodsList, AddSpuDiscount } from "../../action/goodAction";

const { Option } = Select;
const { RangePicker } = DatePicker;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt2M;
}
class NewPromotionalGoods extends React.Component {
    state = {
        value: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        price_type: "price",
        date: "",
        goods: "",
        imageUrl: "",
        logo_file: "",
        loading: false,
        is_show: true,
        is_lock: true,
    };

    getNowFormatDate = () => {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let ha = this.getNowFormatDate()
        this.props.form.validateFieldsAndScroll((err, values) => {
            let formData = new FormData()
            let { spu_id, discount_type, date, price_type, logo_file, is_show, is_lock } = this.state
            values.spu_id = spu_id
            values.discount_type = discount_type
            values.price_type = price_type
            values.state = is_show ? "1" : "0"
            values.is_lock = is_lock ? "1" : "0"
            values.start_at = date ? moment(date[0]).format("YYYY-MM-DD HH:mm:ss") : ha + " 00:00:00"
            values.end_at = date ? moment(date[1]).format("YYYY-MM-DD HH:mm:ss") : ha + " 23:59:59"
            values.discount_on_percentage = parseInt(values.discount_on_percentage) / 100
            if (!err) {
                if (values.spu_id) {
                    if (values.quota >= 1) {
                        for (let i in values) {
                            if (values[i]) {
                                formData.append(i, values[i])
                            }
                        }
                        logo_file && formData.append("image_file", logo_file)
                        AddSpuDiscount(formData).then(res => {
                            this.props.history.push("/promotionalgoodsList")
                        })
                    } else {
                        message.error("限额最小为1")
                    }

                } else {
                    message.error("请选择一个商品")
                }
            }


        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    onSelectSearch = (val) => {
        if (val.length > 1) {
            getNoSkuGoodsList(val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    logo_file: img
                }),
            );
        }
    };

    checkPsd(rule, value, callback) {
        let price = this.state.goods.price;
        if (price && parseInt(price) < parseInt(value)) {
            callback(new Error("折扣价格不能大于商品价格"));
        } else {
            callback();
        }
    }

    render() {
        const { SearchData, discount_type, price_type, goods, imageUrl, loading, is_show, is_lock } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label="选择商品"
                >
                    <Select
                        showSearch
                        // value={this.state.value}
                        placeholder={"选择商品"}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.onSelectSearch}
                        onChange={(e, value) => {
                            this.setState({
                                spu_id: value.props.value,
                                goods: value.props.item,
                            })
                        }}
                        notFoundContent={null}
                    >
                        {
                            SearchData.length > 0 && SearchData.map((item, index) => {
                                return <Option key={index} value={item.id} item={item}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>


                <Form.Item
                    label="促销类型"
                >
                    <Radio.Group
                        onChange={(e) => {
                            this.setState({
                                discount_type: e.target.value
                            })
                        }}
                        value={discount_type}>
                        <Radio value={"fixed"}>fixed</Radio>
                        <Radio value={"percentage"}>percentage</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    discount_type === "fixed" ?
                        <Form.Item
                            label="折扣"
                        >
                            {getFieldDecorator('discount_on_fixed', {
                                rules: [{ required: true, message: '请输入折扣!' }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                                initialValue: "0"
                            })(<Input prefix="$" style={{ width: "200px" }} />)}
                        </Form.Item> :
                        <Form.Item
                            label="打折(%)"
                        >
                            {getFieldDecorator('discount_on_percentage', {
                                rules: [{ required: true, message: '请输入折扣!' }],
                                initialValue: 100
                            })(<InputNumber formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')} min={1} style={{ width: "200px" }} />)}
                        </Form.Item>
                }



                <Form.Item
                    label="价格类型"
                >
                    <Radio.Group
                        onChange={(e) => {
                            this.setState({
                                price_type: e.target.value
                            })
                        }}
                        value={price_type}
                        style={{ marginTop: "10px" }}
                    >
                        <Radio style={{ display: "block", marginBottom: "10px" }} value={"rrp"}>
                            rrp:${goods && goods.rrp || 0}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {
                                discount_type === "fixed" ? "-$" + ` ${goods.price ? parseFloat(goods.rrp) - (goods.price - parseFloat(this.props.form.getFieldValue('discount_on_fixed'))) : 0}` :
                                    `${goods.price ? (goods.price * parseFloat(this.props.form.getFieldValue('discount_on_percentage')) / parseFloat(goods.rrp)).toFixed(2) || 100 : 100}` + "%"
                            }
                            （该价格仅作展示）
                        </Radio>
                        <Radio value={"price"} >
                            price:${goods && goods.price || 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {
                                discount_type === "fixed" ? "-$" + ` ${this.props.form.getFieldValue('discount_on_fixed') || 0}` :
                                    `${this.props.form.getFieldValue('discount_on_percentage') || 100}` + "%"
                            }
                        </Radio>
                    </Radio.Group>
                </Form.Item >
                <Form.Item
                    label="折扣价格"
                >
                    <span>
                        ${
                            (goods && goods.rrp) || (goods && goods.price) ?
                                discount_type === "fixed" ?
                                    goods && goods.price - this.props.form.getFieldValue('discount_on_fixed') :
                                    goods && goods.price * this.props.form.getFieldValue('discount_on_percentage') / 100 : 0
                        }
                    </span>
                </Form.Item>
                <Form.Item
                    label="商品图片"
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                    >
                        {!!imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="销售时间"
                >
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        onOk={(val) => {
                            this.setState({
                                date: val
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="库存"
                >
                    {getFieldDecorator('stock', {
                        rules: [{ required: true, message: '请输入库存!' }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="限额"
                >
                    {getFieldDecorator('quota', {
                        rules: [{ required: true, message: '请输入限额!' }],
                    })(<Input type="number" />)}
                </Form.Item>
                <Form.Item
                    label="是否显示"
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="eatmoon锁"
                >
                    <Switch checked={is_lock} onChange={() => {
                        this.setState({
                            is_lock: !is_lock
                        })
                    }} />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewPromotionalGoods))
