import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch } from 'antd';
import { getStyleList, editStyle, deleteStyle } from "../../action/shopAction";
// import "./category.less"

const EditableContext = React.createContext();

class StyleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            {
                title: "Id",
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: "name",
                dataIndex: 'name',
                width: '5%',
                align: "center",
            },

            {
                title: "图片",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <img src={record.image} style={{ height: "60px" }} alt="" />
                    )
                }
            },
            {
                title: "附加图片",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <img src={record.static_1nd} style={{ height: "60px" }} alt="" />
                    )
                }
            },

            {
                title: "主颜色",
                width: '5%',
                align: "center",
                render: (record) => <div style={{ width: "100%" }}><div style={{ width: "50px", height: "50px", backgroundColor: record.color, margin: "0 auto" }} /></div>
            },
            {
                title: "主色号",
                dataIndex: 'color',
                width: '5%',
                align: "center",
            },
            {
                title: "副颜色1",
                width: '5%',
                align: "center",
                render: (record) => <div style={{ width: "100%" }}><div style={{ width: "50px", height: "50px", backgroundColor: record.color_1nd, margin: "0 auto" }} /></div>
            },
            {
                title: "副色号1",
                dataIndex: 'color_1nd',
                width: '5%',
                align: "center",
            },
            {
                title: "副颜色2",
                width: '5%',
                align: "center",
                render: (record) => <div style={{ width: "100%" }}><div style={{ width: "50px", height: "50px", backgroundColor: record.color_2nd, margin: "0 auto" }} /></div>
            },
            {
                title: "副色号2",
                dataIndex: 'color_2nd',
                width: '5%',
                align: "center",
            },


            {
                title: "排序",
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            {
                title: "状态",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.status === 1} onChange={() => {
                            const { id, status } = record
                            let data = { "name": record.name, "status": status === 1 ? "0" : "1" }
                            editStyle(id, data).then(res => {
                                this.HandleGetData()
                            })
                        }} />
                    )
                }
            },
            {
                title: "是否默认",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_default === 1} onChange={() => {
                            const { id, is_default } = record
                            let data = { "name": record.name, "is_default": is_default === 1 ? "0" : "1" }
                            editStyle(id, data).then(res => {
                                this.HandleGetData()
                            })
                        }} />
                    )
                }
            },
            {
                title: "操作",
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    if (record.is_default !== 1) {
                        return <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => {
                                this.props.history.push("/EditStyle/" + record.id)
                            }}>
                                编辑
                            </Button>
                            <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    }

                },
            },

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getStyleList();
        this.setState({ data: res.data.data })
    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.SubmitData(newData, newData[index])


            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    async SubmitData(newData, item) {
        const { id, name, sort } = item
        let data = { "name": name, "sort": sort }
        await editStyle(data, id)
        this.setState({ data: newData, editingKey: '' });
    }


    edit(key) {
        this.setState({ editingKey: key.id });
    }

    async handleDelete(data) {
        await deleteStyle(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/newStyle")}
                    >
                        新建
                    </Button>
                </div>
                <Table
                    rowKey='id'
                    // bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </EditableContext.Provider>
        );
    }
}

export default withRouter(Form.create()(StyleList))

