import React from "react";
import { Table, Form } from 'antd';
import { getUserCouponList } from "../../action/goodAction";

class UserCoupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], page: 1 };
        this.columns = [
            {
                title: "Id",
                dataIndex: 'id',
                width: '2%',
                align: "center",
            },
            {
                title: "优惠券图片",
                dataIndex: 'coupon',
                width: '5%',
                align: "center",
                render: (record) => {
                    return record.image && <img src={record.image} style={{ width: "80px", height: "80px", objectFit: "contain" }} />
                }
            },
            {
                title: "名称",
                dataIndex: 'coupon',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{record.name}</span>
                }
            },

            {
                title: "库存",
                dataIndex: 'coupon',
                width: '5%',
                render: (record) => {
                    let stock = record.stock
                    let sale_quantity = record.sale_quantity
                    let total_stock = Number(stock) + Number(sale_quantity)
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>销量:{sale_quantity}</span>
                        <span style={{ marginBottom: "5px" }}>剩余库存:{stock}</span>
                        <span>总库存:{total_stock}</span>
                    </div>
                }
            },

            {
                title: "领取用户",
                dataIndex: 'user',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{record.name}</span>
                }
            },
            {
                title: "领取时间",
                width: '8%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            },
            {
                title: "使用时间",
                dataIndex: "use_at",
                width: '8%',
                align: "center",
                render: (record) => {
                    return record && record.date.substring(0, 19)
                }
            },
            {
                title: "状态",
                width: '3%',
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>结束</span> :
                                    <span style={{ color: "green" }}>正常</span>
                            }
                        </div>
                    )
                }
            }
        ];
    }


    componentDidMount() {
        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        const id = this.props.location.pathname.substring(12)
        let res = await getUserCouponList(page, "?coupon_id=" + id)
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }


    render() {
        let { meta, data } = this.state
        return (
            <Table
                rowKey={record => record.id}
                dataSource={data}
                columns={this.columns}
                // scroll={{ x: 1000 }}
                pagination={{
                    pageSize: meta && meta.per_page,
                    total: meta && meta.total
                }}
                onChange={(e) => {
                    this.HandleGetData(e.current)
                }}
                className="waperBox"
            />
        );
    }
}

export default UserCoupon

