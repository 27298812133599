import React from "react";
import { Table, Button, Popover, Tooltip, Modal } from 'antd';
import { getPackageLog } from "../../../action/shopAction";

class packageLogList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packageLogData: [],
            packageLogMeta: "",
            url: "",
            remarksVisible: false,
            remarks: ""
        };

        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '5%',
            },
            {
                title: "套餐名称",
                dataIndex: 'package[name]',
                width: '10%',
                align: "center",
            },
            {
                title: "定额订单服务费",
                dataIndex: 'order_fixed_fee',
                width: '10%',
                align: "center",
                render: (record) => {
                    if (record) {
                        return "$" + record
                    }
                }
            },
            {
                title: "商品订单服务费",
                dataIndex: 'rate',
                width: '10%',
                align: "center",
                render: (record) => {
                    if (record) {
                        return record + "%"
                    }
                }
            },
            {
                title: "活动订单服务费",
                dataIndex: 'activity_service_rate',
                width: '10%',
                align: "center",
                render: (record) => {
                    if (record) {
                        return record + "%"
                    }
                }
            },

            {
                title: "原因",
                dataIndex: 'event',
                width: '10%',
                align: "center",
            },
            {
                title: "备案时间",
                dataIndex: 'record_at',
                width: '10%',
                align: "center",
                // render: (text) => text.date.substring(0, 19)
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            {
                title: "执行时间",
                dataIndex: 'implement_at',
                width: '10%',
                align: "center",
                // render: (text) => text.date.substring(0, 19)
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            {
                title: "创建时间",
                dataIndex: 'created_at',
                width: '10%',
                align: "center",
                // render: (text) => text.date.substring(0, 19)
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            {
                title: "备注",
                width: '5%',
                dataIndex: 'remarks',
                align: "center",
                render: (record) => {
                    return <Button
                        shape="circle"
                        icon="more"
                        onClick={() => {
                            this.setState({
                                remarksVisible: true,
                                remarks: record
                            })
                        }}
                    />
                },
            },
            // {
            //     title: "备注",
            //     dataIndex: 'remarks',
            //     width: '15%',
            //     align: "center",
            // },
        ];

    }



    async componentDidMount() {
        let url = ""
        let partner_id = this.props.location.pathname.substring(16)
        if (partner_id) {
            url = "&partner_id=" + partner_id
        }
        this.GetPackageLogData(url, 1)
    }


    async GetPackageLogData(url, page) {
        let res = await getPackageLog(url, page)
        this.setState({
            packageLogData: res.data.data,
            packageLogMeta: res.data.meta.pagination
        })
    }



    render() {
        const { packageLogData, packageLogMeta, url } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => {
                            let partner_id = this.props.location.pathname.substring(16)
                            this.props.history.push("/NewPackageLog/" + partner_id)
                        }}
                    >
                        新建
                    </Button>
                </div>


                <Table
                    rowKey={(record, index) => record.id.toString()}
                    className="waperBox"
                    dataSource={packageLogData}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: packageLogMeta.per_page,
                        total: packageLogMeta.total
                    }}
                    onChange={(e) => {
                        this.GetPackageLogData(url, e.current)
                    }}
                />
                <Modal
                    title="备注"
                    visible={this.state.remarksVisible}
                    width={435}
                    footer={null}
                    onCancel={() => this.setState({ remarksVisible: false })}
                >
                    <div style={{ height: "60px" }}>
                        <span style={{ width: "140px", textAlign: "right", marginRight: 5 }}>{this.state.remarks}</span>
                    </div>

                </Modal>
            </div>
        );

    }
}

export default packageLogList

