import React from "react";
import { withRouter } from 'react-router-dom'

import { Table, Form, Popconfirm, Button, Switch } from 'antd';
import { getRegionList, EditRegion, deleteRegion } from "../../action/shopAction";


class regionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            {
                title: "id",
                dataIndex: 'id',
                width: '2%',
                editable: false,
                align: "center",
            },
            {
                title: "国旗",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <img src={record.flag} style={{ height: "20px" }} alt="" />
                    )
                }
            },
            {
                title: "中文名称",
                dataIndex: 'name',
                width: '6%',
                editable: true,
                align: "center",
            },
            {
                title: "英文名称",
                dataIndex: 'full_name',
                width: '10%',
                editable: true,
                align: "center",
            },
            {
                title: "英文缩写",
                dataIndex: 'short_name',
                width: '6%',
                editable: true,
                align: "center",
            },


            {
                title: "货币",
                dataIndex: 'currency',
                width: '5%',
                editable: true,
                align: "center",
            },
            {
                title: "货币符号",
                // dataIndex: 'currency_symbol',
                width: '6%',
                editable: true,
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            <span style={{ marginRight: "10px" }}>{record.currency_symbol}</span>
                            <Switch checked={record.currency_state === 1} disabled onChange={() => {
                                const { id, currency_state } = record
                                let data = { "currency_state": currency_state === 1 ? "0" : "1" }
                                EditRegion(id, data).then(res => {
                                    this.HandleGetData()
                                })
                            }} />
                        </div>
                    )
                }
            },
            // {
            //     title: "货币开关",
            //     width: '6%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.currency_state === 1} disabled onChange={() => {
            //                 const { id, currency_state } = record
            //                 let data = { "currency_state": currency_state === 1 ? "0" : "1" }
            //                 EditRegion(id, data).then(res => {
            //                     this.HandleGetData()
            //                 })
            //             }} />
            //         )
            //     }
            // },
            {
                title: "国际区号",
                // dataIndex: 'area_code',
                width: '6%',
                editable: true,
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            <span style={{ marginRight: "10px" }}>{record.area_code}</span>
                            <Switch
                                checked={record.area_code_state === 1}
                                // disabled
                                onChange={() => {
                                    const { id, area_code_state } = record
                                    let data = { "area_code_state": area_code_state === 1 ? "0" : "1" }
                                    EditRegion(id, data).then(res => {
                                        this.HandleGetData()
                                    })
                                }} />
                        </div>
                    )
                }
            },





            // {
            //     title: "国际区号",
            //     width: '6%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.area_code_state === 1} disabled onChange={() => {
            //                 const { id, area_code_state } = record
            //                 let data = { "area_code_state": area_code_state === 1 ? "0" : "1" }
            //                 EditRegion(id, data).then(res => {
            //                     this.HandleGetData()
            //                 })
            //             }} />
            //         )
            //     }
            // },
            {
                title: "状态",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.state === 1} disabled onChange={() => {
                            const { id, state } = record
                            let data = { "state": state === 1 ? "0" : "1" }
                            EditRegion(id, data).then(res => {
                                this.HandleGetData()
                            })
                        }} />
                    )
                }
            },

            // {
            //     title: "操作",
            //     dataIndex: 'operation',
            //     width: '15%',
            //     align: "center",
            //     render: (text, record) => {
            //         if (record.is_default !== 1) {
            //             return <div className='deleteView'>
            //                 <Button style={{ marginRight: "10px" }} onClick={() => {
            //                     this.props.history.push("/editRegion/" + record.id)
            //                 }}>
            //                     编辑
            //                 </Button>
            //                 <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record.id)}>
            //                     <Button>
            //                         删除
            //                     </Button>
            //                 </Popconfirm>
            //             </div>

            //         }

            //     },
            // },

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getRegionList();
        this.setState({ data: res.data.data })
    }


    onChange = (item) => {
        const { id, is_show } = item
        let data = { "is_show": is_show === 1 ? "0" : "1" }
        EditRegion(data, id).then(res => {
            this.HandleGetData()
        })
    }

    async handleDelete(id) {
        await deleteRegion(id)
        this.HandleGetData()
    }

    render() {
        return (
            <div>
                {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/newRegion")}
                    >
                        新建
                    </Button>
                </div> */}
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(regionList))

