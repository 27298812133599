import React, { Component } from 'react'
import { Button, Row, Form, Input, message } from 'antd'
import { withRouter } from 'react-router-dom'
import './index.less'
import { login } from "../../action/authAction";
import { setTokenStorage, getTokenStorage } from "../../util/function";
import adminbackgroundImg from "../../img/adminbackground.png";

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.resizeTTY.bind(this)
        this.state = {
            width: document.body.clientWidth,
            height: document.body.clientHeight
        }
    }

    componentDidMount() {
        let token = getTokenStorage()
        if (token && token !== "null") {
            window.location.href = '/'
        }
        window.addEventListener('resize', this.resizeTTY)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeTTY)
    }
    resizeTTY = () => {
        var width = document.body.clientWidth;
        var height = document.body.clientHeight;
        if (width < 1000) {
            this.setState({
                width: 1000
            })
        } else {
            this.setState({
                width, height
            })
        }

    }

    handleOk = () => {
        const { form } = this.props
        const { validateFieldsAndScroll } = form
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }
            let res = await login(values)
            message.success('登录成功')
            // 成功跳转给出提示
            setTokenStorage(res.data.access_token)
            localStorage.setItem("username", values.name)
            setTimeout(() => {
                this.props.history.push('/dataStatistics')
            }, 1000)

        })
    }

    render() {
        const { width, height } = this.state
        const siteName = ' '
        const FormItem = Form.Item
        const { getFieldDecorator } = this.props.form
        return (
            <div style={{
                width: width + "px",
                height: height + "px",
                position: "relative",
                backgroundColor: "#f9b42e"

            }}>
                <div style={{ position: "absolute", top: "20%", display: "flex", marginLeft: "30px" }}>
                    <img style={{ width: "500px" }} src={adminbackgroundImg} alt='' />
                    <div className='loginForm'>
                        <div className='Loginlogo'>
                            <span>{siteName}</span>
                        </div>
                        <Form>
                            <FormItem hasFeedback>
                                {getFieldDecorator('name', {
                                    rules: [{ required: true }]
                                })(
                                    <Input onPressEnter={this.handleOk} placeholder={`Username`} />
                                )}
                            </FormItem>
                            <FormItem hasFeedback>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true
                                        }
                                    ]
                                })(
                                    <Input
                                        type='password'
                                        placeholder={`Password`}
                                    />
                                )}
                            </FormItem>
                            <Row>
                                <Button style={{ backgroundColor: "#fae27e", color: "#000", border: "none" }} onClick={this.handleOk}>
                                    登录
                                </Button>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div style={{ textAlign: "center", position: "absolute", bottom: "10px", width: "100%" }}>
                    <span>© 2023 Eatmoon System. All Rights Reserved. </span>
                </div>
            </div>
        )
    }
}

export default withRouter(Form.create()(LoginForm))
