import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, DatePicker, Switch, Select } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import moment from 'moment';
import "./setup.less"
import { AddAnnouncement, getLanguageList } from "../../action/authAction";
import { getShopList } from "../../action/shopAction"

const { RangePicker } = DatePicker;
const { Option } = Select;
class NewAnnouncement extends React.Component {
    state = {
        shopList: "",
        value: "",
        date: "",
        is_show: false,
        editorState: BraftEditor.createEditorState(null),
        LanguageData: "",
        type: [{ "id": 1, name: "店铺前台" }, { "id": 2, name: "商家后台" }]
    };

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData
        })


        let resp = await getShopList("", "")
        let data = resp.data.data
        this.setState({
            shopList: data,
            partner_id: "" ,//data[0].id
        }, () => {
            //this.HandleGetData()
        })



    }

    getNowFormatDate = () => {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let ha = this.getNowFormatDate()
        this.props.form.validateFieldsAndScroll((err, values) => {
            let { date, is_show, editorState } = this.state
            values.state = is_show ? "1" : "0"
            values.start_at = date ? moment(date[0]).format("YYYY-MM-DD HH:mm:ss") : ha + " 00:00:00"
            values.stop_at = date ? moment(date[1]).format("YYYY-MM-DD HH:mm:ss") : ha + " 23:59:59"
            values.content = editorState.toHTML()
            values.request_language_id = values.request_language_id
            AddAnnouncement(values).then(res => {
                this.props.history.push("/AnnouncementList")
            })
        });
    };

    handleChange = (editorState) => {
        this.setState({ editorState })
    }


    render() {
        const { is_show, LanguageData, type ,shopList } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label="选择录入的语言"
                >
                    {getFieldDecorator('request_language_id', {
                        rules: [{ required: true, message: '请选择语言!' }],
                    })(
                        <Select>
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>

                
                <Form.Item
                    label="选择店铺"
                    style={{ margin: "0px" }}
                >
                    {getFieldDecorator('partner_id', {
                        rules: [{ required: false   }],
                    })(<Select onChange={(e) => {
                        for (let i in shopList) {
                            if (shopList[i].id === e) {
                                this.setState({
                                    partner_id: shopList[i]
                                })
                            }
                        }
                    }}>
                        {
                            shopList && shopList.map(item => {
                                return <Option value={item.id} key={item.id}>{item.title}</Option>
                            })

                        }
                    </Select>)}
                </Form.Item>
              




                <Form.Item
                    label="标题"
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: false }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="起始时间"
                >
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        onOk={(val) => {
                            this.setState({
                                date: val
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                    })(<Input type="number" />)}
                </Form.Item>
                <Form.Item
                    label="是否显示"
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="显示位置"
                >
                    {getFieldDecorator('type', {
                        rules: [{ required: false }],
                        initialValue: 1
                    })(
                        <Select>
                            {type && type.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="内容"
                >
                    <div style={{
                        border: "1px solid #dedede",
                    }}>
                        <BraftEditor
                            value={this.state.editorState}
                            onChange={this.handleChange} />
                    </div>

                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewAnnouncement))
