import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Select } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { AddBlindboxPrice } from "../../action/goodAction";
import { getLanguageList } from "../../action/authAction"


class NewBlindboxPrice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            disable_language: true,
            LanguageData: "",
            editorState: BraftEditor.createEditorState(null),
            typeData: [
            
            {
                id: 1,
                name: "A"  //intl.get("nav.goodsManagement.promotionalgoods.external_coupon")
            },

            {
                id: 2,
                name: "B" //intl.get("nav.goodsManagement.promotionalgoods.external_coupon")
            },


            {
                id: 3,
                name: "C"
            }, 
            ],
        }
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.show = this.state.show ? "1" : "0"
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.content = this.state.editorState.toHTML()
            if (!err) {
                AddBlindboxPrice(values).then(res => {
                    this.props.history.push("/BlindboxPrice")
                })
            }

        });
    };


    handleChange = (editorState) => {
        this.setState({ editorState })
    }




    render() {
        let { show, disable_language, LanguageData,typeData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">


                 <Form.Item
                    label="盲盒类型"
                >
                    {getFieldDecorator('type', {
                        rules: [{ required: true }],
                        initialValue: 'A'
                    })(<Select
                         
                    >
                        {typeData && typeData.map(el => <Select.Option key={el.id} value={el.name}>{el.name}</Select.Option>)}
                    </Select>)}
                </Form.Item>




                
                <Form.Item
                    label="价格"
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: false }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="是否显示">
                    <Switch checked={show} onChange={() => {
                        this.setState({
                            show: !show
                        })
                    }} />
                </Form.Item>

 
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewBlindboxPrice))
