import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Popconfirm, Form, Button } from 'antd';
import { getStateList, editSalesArea, deleteSalesArea } from "../../action/shopAction";
// import "./category.less"

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps} >
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class SalesAreaList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            {
                title: "Id",
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
 

            {
                title: "州",
                dataIndex: 'state',
                width: '10%',
                align: "center",
            },

             
 
            /*
            {
                title: "操作",
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    if (record.is_default !== 1) {
                        return <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => {
                                this.props.history.push("/EditSalesArea/" + record.id)
                            }}>
                                编辑
                            </Button>
                            <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    }

                },
            },*/ 

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getStateList();
        this.setState({ data: res.data.data })
    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.SubmitData(newData, newData[index])


            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    async SubmitData(newData, item) {
        const { id, name, sort } = item
        let data = { "name": name, "sort": sort }
        await editSalesArea(data, id)
        this.setState({ data: newData, editingKey: '' });
    }


    edit(key) {
        this.setState({ editingKey: key.id });
    }

    async handleDelete(data) {
        await deleteSalesArea(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/newSalesArea")}
                    >
                        新建
                    </Button>
                </div>
                */}


                <Table
                    rowKey='id'
                    components={components}
                    // bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </EditableContext.Provider>
        );
    }
}

export default withRouter(Form.create()(SalesAreaList))

