import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Select } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { getDocumentcategoryDetail, editDocumentcategory } from "../../action/shopAction";
import { getLanguageList, getAnnouncementContent } from "../../action/authAction"


class EditDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            goodImage: "",
            show: true,
            disable_language: true,
            LanguageData: ""
        }
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData
        }, () => {
            this.handleGetDetail(LanguageData[1].id)
        })
    }

    handleGetDetail = async (language_id) => {
        const id = this.props.location.pathname.substring(14)
        if (id) {
            let data = await getDocumentcategoryDetail(id, language_id)
            
            /*
            getAnnouncementContent(data.data.content).then(res => {
                this.setState({
                    data: data.data,
                    show: data.data.show === 1,
                    disable_language: data.data.disable_language === 0,
                    editorState: BraftEditor.createEditorState(res.data),
                })
            })*/


             this.setState({
                data: data.data,
                show: data.data.show === 1,
                disable_language: data.data.disable_language === 0, 
            })



        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let { disable_language, editorState } = this.state
            values.show = this.state.show ? 1 : 0
            values.disable_language = disable_language ? 0 : 1
            //values.content = editorState.toHTML()
            if (!err) {
                const id = this.props.location.pathname.substring(14)
                editDocumentcategory(id, values).then(res => {
                    this.props.history.push("/CategoryList")
                })
            }

        });
    };

    handleChange = (editorState) => {
        this.setState({ editorState })
    }

    render() {
        let { data, show, disable_language, LanguageData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        if (data) {

            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                    <Form.Item
                        label="选择录入的语言"
                    >
                        {getFieldDecorator('request_language_id', {
                            rules: [{ required: true, message: '请选择语言!' }],
                            initialValue: LanguageData && LanguageData[1].id
                        })(
                            <Select
                                onChange={(e) => {
                                    this.handleGetDetail(e)
                                }}
                            >
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="标题"
                    >
                        {getFieldDecorator('title', {
                            rules: [{ required: false }],
                            initialValue: data.title
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item
                        label="排序"
                    >
                        {getFieldDecorator('sort', {
                            initialValue: data.sort
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="是否显示">
                        <Switch checked={show} onChange={() => {
                            this.setState({
                                show: !show
                            })
                        }} />
                    </Form.Item>

 

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            );

        } else {
            return null
        }
    }
}

export default withRouter(Form.create()(EditDocument))
