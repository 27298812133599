import React from "react";
import { Table, Popconfirm, Button, Select,Switch } from 'antd';
import { getPackageList, deletePackage, getLanguageList,UpdatePackage } from "../../action/authAction";

class PackageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], LanguageData: "",language_id: "" };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '3%',
                editable: false,
                align: "center",
            },
            {
                title: '套餐名称',
                dataIndex: 'name',
                width: '5%',
                editable: true,
                align: "center",
            },
            // {
            //     title: '订单服务费固定抽成',
            //     dataIndex: 'order_fixed_fee',
            //     align: "center",
            // },
            {
                title: '初始费',
                dataIndex: 'setup_fee',
                width: '5%',
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>${record}</span>
                    }
                }
            },

            // {
            //     title: '预存款',
            //     dataIndex: 'prestore_money',
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         if (record || record === 0) {
            //             return <span>${record}</span>
            //         }
            //     }
            // },



            {
                title: '月费',
                dataIndex: 'month_service_fee',
                width: '5%',
                align: "center",
                render: (record) => {
                    return record && <span>${record}</span>
                }
            },



            {
                title: '月平台活动达标单数',
                dataIndex: 'money_standard_activity_order_num',
                width: '8%',
                align: "center",
                 
            },






            // {
            //     title: '年达标费',
            //     dataIndex: 'year_service_fee',
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return record && <span>${record}</span>
            //     }
            // },





            // {
            //     title: '年费要求订单数量',
            //     dataIndex: 'year_service_fee_by_order_num',
            //     align: "center",
            // },
            // {
            //     title: '月服务费',
            //     dataIndex: 'month_service_fee',
            //     width: '10%',
            //     align: "center",
            // },
            // {
            //     title: '月费要求订单数量',
            //     dataIndex: 'month_service_fee_by_order_num',
            //     align: "center",
            // },
            {
                title: '订单定额服务费',
                dataIndex: 'order_fixed_fee',
                width: '8%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>${record}</span>
                    }

                }
            },
            {
                title: '活动订单服务费',
                dataIndex: 'activity_service_rate',
                width: '8%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>{record}%</span>
                    }

                }
            },
            {
                title: '商品订单服务费',
                dataIndex: 'rate',
                width: '8%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>{record}%</span>
                    }
                }
            },
            // {
            //     title: '体验期(月)',
            //     dataIndex: 'trial_period_days',
            //     width: '10%',
            //     align: "center",
            // },
           
            {
                title: '独立结算',
                dataIndex: 'is_own_payment',
                width: '8%',
                align: "center",
                render: (record) => {
                    return <span>{record === 1 ? "是" : "否"}</span>
                }
            },


            {
                title: "是否显示",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_show === 1} onChange={() => {
                            const { id, is_show } = record
                            let data = { "is_show": is_show === 1 ? "0" : "1" }
                            UpdatePackage(data,id).then(res => {
                                this.handleGetPackageList(this.state.language_id)
                            })
                        }} />
                    )
                }
            },



            {
                title: '排序',
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            // {
            //     title: '订单减免周期',
            //     dataIndex: 'day_of_fee_reduction',
            //     align: "center",
            // }, {
            //     title: '订单减免期费率',
            //     dataIndex: 'fee_reduction_rate',
            //     align: "center",
            // }, {
            //     title: '是否支持小程序',
            //     dataIndex: 'is_have_weapp',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // }, {
            // },
            //  {
            //     title: '是否支持小程序',
            //     dataIndex: 'is_have_weapp',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // },
            //  {
            //     title: '是否支持多语言',
            //     dataIndex: 'is_langs',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // },
            //  {
            //     title: '是否支持h5',
            //     dataIndex: 'is_have_h5',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // }, 
            // {
            //     title: '是否支持独立域名',
            //     dataIndex: 'is_have_domain',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // },
            // {
            //     title: '是否支持独立收款',
            //     dataIndex: 'is_own_payment',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // },
            // {
            //     title: '是否支持外卖',
            //     dataIndex: 'is_have_takeout',
            //     align: "center",
            //     render: text => <span>{text === 1 ? '是' : '否'}</span>
            // },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '15%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button type="primary" onClick={() => this.edit(record)} style={{ marginRight: "10px" }}>
                                编辑
                            </Button>
                            <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                                <Button type="danger" ghost style={{ marginRight: "10px" }}>
                                    删除
                                </Button>
                            </Popconfirm>

                             <Button   onClick={() => this.detail(record)} >
                                功能设置
                            </Button>


                        </div>
                    );
                },
            },
        ];
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.handleGetPackageList(LanguageData[1].id)
        })
    }

    handleGetPackageList = async (id) => {
        let res = await getPackageList(id);
        this.setState({ data: res.data.data ,language_id:id})
    }

    edit(key) {
        this.props.history.push("/NewPackage/" + key.id)
    }

    detail(key) {
        this.props.history.push("/PackageDetail/" + key.id)
    }

    async handleDelete(data) {
        await deletePackage(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });
        let { LanguageData } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.handleGetPackageList(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }

                    <Button type="primary"
                        onClick={() => this.props.history.push("/NewPackage")}
                    >
                        新建
                    </Button>
                </div>
                <Table
                    rowKey={(record, index) => record.id.toString()}
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default PackageList

