import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch, Select } from 'antd';
import { getAnnouncementList, deleteAnnouncement, UpdateAnnouncement, getLanguageList } from "../../action/authAction";

class AnnouncementList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], LanguageData: "", language_id: "" };
        this.columns = [

            {
                title: '公告标题',
                dataIndex: 'title',
                width: '30%',
            },


            {
                title: '商家',
                dataIndex: 'partner_id',
                 
            },


            {
                title: '显示',
                width: '6%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_show === 1} onChange={() => {
                            const { id, is_show } = record
                            let data = { "is_show": is_show === 1 ? "0" : "1" }
                            UpdateAnnouncement(data, id).then(res => {
                                this.HandleGetData(this.state.language_id)
                            })
                        }} />
                    )
                }
            },

            {
                title: '排序',
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            {
                title: '显示位置',
                dataIndex: 'type',
                width: '10%',
                align: "center",
                render: (record) => {
                    return record === 1 ? "店铺前台" : "商家后台"
                }
            },

            {
                title: '创建时间',
                dataIndex: 'created_at.date',
                width: '20%',
                align: "center",
                render: (record) => {
                    return record.substring(0, 19)
                }
            },
            /*
            {
                title: '开始时间',
                dataIndex: 'start_at',
                width: '20%',
                align: "center",
            },
            {
                title: '结束时间',
                dataIndex: 'stop_at',
                width: '20%',
                align: "center",
            },
            */
            {
                title: '操作',
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button onClick={() => {
                                this.props.history.push("/EditAnnouncement/" + record.id)
                            }}
                                style={{ marginRight: 15 }}
                                type="primary"
                            >
                                编辑
                            </Button>
                            <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                                <Button type="danger" ghost>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })
    }

    async HandleGetData(id) {
        let res = await getAnnouncementList(id)
        this.setState({ data: res.data.data, language_id: id })
    }



    async handleDelete(data) {
        await deleteAnnouncement(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        let { LanguageData } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                this.HandleGetData(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    }
                    <Button type="primary"
                        onClick={() => this.props.history.push("/NewAnnouncement")}
                    >
                        新建
                    </Button>
                </div>
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    // pagination={{
                    //     hideOnSinglePage: true
                    // }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(AnnouncementList))

