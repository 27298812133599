import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Spin } from 'antd';
import { AddSalesArea, queryAddress, getRegionList ,getStateList} from "../../action/shopAction";
let { Option } = Select
let lastFetchId = 0;

class NewCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fetching: false,
            SuburbList: [],
            value: "",
            SearchSuburbsTag: [],
            regionList: "",
            //stateData: ["NSW", "QLD", "SA", "TAS", "VIC", "WA"]
        }
    }

    componentDidMount() {

        getRegionList().then(res => {
            this.setState({
                regionList: res.data.data
            })
        })


        getStateList().then(res => {
            this.setState({
                stateData: res.data.data
            })
        })



    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.name) {
                    this.SubmitData(values)
                }
            }

        });
    };

    async SubmitData(data) {
        let { SearchSuburbsTag } = this.state
        let subs = ""
        for (let i in SearchSuburbsTag) {
            if (subs) {
                subs = subs + "," + SearchSuburbsTag[i]
            } else {
                subs = SearchSuburbsTag[i]
            }

        }
        data.subs = subs
        await AddSalesArea(data).then(res => {
            this.props.history.push("/salesAreaList")
        })

    }


    render() {
        let { fetching, SuburbList, SearchSuburbsTag, regionList, stateData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        const fetchSuburb = (value) => {

            lastFetchId += 1;
            const fetchId = lastFetchId;
            this.setState({
                fetching: true
            })
            queryAddress(value).then((res) => {
                if (fetchId !== lastFetchId) {
                    return;
                }
                this.setState({
                    SuburbList: [...res.data],
                    fetching: true
                })
            });

        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                {/* <Form.Item
                    label={<span>选择国家</span>}
                >
                    {getFieldDecorator('region_id', {
                        rules: [{ required: true, message: 'Please select region!' }],
                        initialValue: 1
                    })(
                        <Select disabled>
                            {regionList && regionList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item> */}


                <Form.Item
                    label="州"
                >
                    {getFieldDecorator('state_id', {
                        rules: [{ required: false, message: "请选择州" }],
                    })(<Select placeholder="请选择州">
                        {/* stateData.map(el => <Select.Option key={el} value={el}>{el}</Select.Option>) */ }
                        {stateData && stateData.map(el => <Select.Option key={el.id} value={el.id}>{el.state}</Select.Option>)}
                    </Select>)}
                </Form.Item>


                <Form.Item
                    label="大区名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: "请输入名称", whitespace: true }],
                    })(<Input placeholder='请输入大区名称' />)}
                </Form.Item>



                <Form.Item
                    label="选择sub"
                >
                    {getFieldDecorator('subs', {
                        rules: [{ required: false, message: '请选择sub!' }],
                    })(
                        <Select
                            mode="multiple"
                            placeholder="Select Suburb"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            value={SearchSuburbsTag}
                            filterOption={false}
                            onSearch={fetchSuburb}
                            onChange={(value) => {
                                this.setState({
                                    SearchSuburbsTag: value
                                })
                            }}
                        >
                            {SuburbList.map((item, index) => {
                                return (
                                    <Option
                                        key={index + item.postcode + item.name}
                                        //value={item.state.abbreviation + ' ' + item.name + ' ' + item.postcode}
                                        value={item.name}
                                        id={index}
                                    >
                                        {/*item.state.abbreviation + ' ' + item.name + ' ' + item.postcode*/}
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewCategory))
