import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Input, Switch, Card } from 'antd';
import { getShopDetail, putShop } from "../../action/shopAction";

class RoyalPaySet extends React.Component {

    state = {
        data: "",
        // is_open_royal_pay: true,
        // is_open_stripe: true,
        // is_open_paypal: true,
        is_self_pay: true,
        is_discount_self_pay: false
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(11)
        let res = await getShopDetail(id)
        this.setState({
            data: res.data,
            // is_open_royal_pay: res.data.is_open_royal_pay === 1,
            // is_open_stripe: res.data.is_open_stripe === 1,
            // is_open_paypal: res.data.is_open_paypal === 1,
            is_self_pay: res.data.is_self_pay === 1,
            is_discount_self_pay: res.data.is_discount_self_pay === 1,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const id = this.props.location.pathname.substring(11)
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // values.is_open_royal_pay = this.state.is_open_royal_pay ? "1" : "0"
                // values.is_open_stripe = this.state.is_open_stripe ? "1" : "0"
                // values.is_open_paypal = this.state.is_open_paypal ? "1" : "0"
                values.is_self_pay = this.state.is_self_pay ? "1" : "0"
                values.is_discount_self_pay = this.state.is_discount_self_pay ? "1" : "0"
                putShop(id, values).then(res => {
                    window.location.reload()
                })
            }
        });
    };


    render() {
        const { data, is_self_pay, is_discount_self_pay } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        if (data.package && data.package.is_own_payment == 1) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                    <div style={{ height: 30 }}></div>
                    



                    {/* <Form.Item
                        label="Is open royalPay"
                    >
                        <Switch checked={is_open_royal_pay} onChange={() => {
                            this.setState({
                                is_open_royal_pay: !is_open_royal_pay
                            })
                        }} />
                    </Form.Item>


                    <Form.Item
                        label="Is open stripe"
                    >
                        <Switch checked={is_open_stripe} onChange={() => {
                            this.setState({
                                is_open_stripe: !is_open_stripe
                            })
                        }} />
                    </Form.Item>


                    <Form.Item
                        label="Is open paypal"
                    >
                        <Switch checked={is_open_paypal} onChange={() => {
                            this.setState({
                                is_open_paypal: !is_open_paypal
                            })
                        }} />
                    </Form.Item> */}







                    <Form.Item
                        label="Is self pay"
                    >
                        <Switch checked={is_self_pay} onChange={() => {
                            this.setState({
                                is_self_pay: !is_self_pay
                            })
                        }} />
                    </Form.Item>



                    {
                        is_self_pay && <div>
                            <Form.Item
                                label="Stripe Pay Key"
                            >
                                {getFieldDecorator('stripe_key', {
                                    rules: [{ required: true }],
                                    initialValue: data.stripe_key
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item
                                label="Stripe Pay Secret"
                            >
                                {getFieldDecorator('stripe_secret', {
                                    rules: [{ required: true }],
                                    initialValue: data.stripe_secret
                                })(<Input />)}
                            </Form.Item>


                            {/*
                            <Form.Item
                                label="Stripe Webhook Sigh"
                            >
                                {getFieldDecorator('stripe_webhook_sigh', {
                                    rules: [{ required: true }],
                                    initialValue: data.stripe_webhook_sigh
                                })(<Input />)}
                            </Form.Item>
                            
                            */}






                            {/*<Form.Item
                                label="开启促销独立支付"
                            >
                                <Switch checked={is_discount_self_pay} onChange={() => {
                                    this.setState({
                                        is_discount_self_pay: !is_discount_self_pay
                                    })
                                }} />
                            </Form.Item>*/}


                        </div>

                    }


                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>


                    
                </Form>
            );
        } else {
            return <Card>
                <p>该商家不支持独立结算</p>
            </Card>
        }


    }
}

export default withRouter(Form.create()(RoyalPaySet))
