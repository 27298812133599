import React from "react";
import { withRouter } from 'react-router-dom'
import { Input, Table, Form, Select, Button, DatePicker, Modal, Icon, Popover } from 'antd';
import { GetOrderList, OrderRefund, OrderWaitRefund } from "../../action/authAction";
import { getShopList } from "../../action/shopAction"
import moment from 'moment';
const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;

class orderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            shopList: "",
            defaultShop: "",
            defaultShopId: "",
            meta: "",
            url: "",
            url_1:"",
            url_2:"",
            url_3:"",
            url_4:"",
            one_url: "",
            two_url: "",
            visible: false,
            ItemId: "",
            refund_message: "",
            consumption_type:null,//消费方式

        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },

            {
                title: "店铺",
                dataIndex:  ['partner','title'],
                width: '5%',
                align: "center",
            },



            {
                title: '订单编号',
                dataIndex: 'order_sn',
                width: '7%',
                align: "center",
            },
            {
                title: '名称',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    const data = record.orderSpu && record.orderSpu.data
                    if (record.spu_discount_id > 0) {
                        let attach_data = data[0].attach_data
                        return <div style={{ display: "flex" }}>
                            <img src={attach_data.image} style={{ backgroundColor:"rgb(239 239 239)",width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                            <div style= {{ flex: "1 1 0%" ,display: "flex" }}>
                                {
                                    record.spu_many_help_id > 0 ?
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[砍一刀]</span>{attach_data.name}
                                        </span> :
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[促销]</span>{attach_data.name}
                                        </span>
                                }
                            </div>
                        </div>
                    } else {
                        return (
                            <div>
                                {
                                    data && data.map(item => {
                                        return (<div key={item.id.toString() + item.order_id} style={{ display: "flex", marginBottom: "5px" }}>
                                            <img src={item.attach_data.image} style={{ width: "50px", height: "50px", objectFit: "cover", marginRight: "10px" }} alt="" />
                                            <div className="good-name">
                                                <div style={{ textAlign: "left" }} title={item.attach_data.name}>{item.attach_data.name}</div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {
                                                        item.attach_data && item.attach_data.spu_sku && item.attach_data.spu_sku.spu_sku_goods_specification_item && item.attach_data.spu_sku.spu_sku_goods_specification_item.map((key, index) => {
                                                            return (
                                                                <span key={item.order_id + key.name + key.goods_specification_id + index + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{key.name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.attach_data && item.attach_data.spu_goods_annex_type && item.attach_data.spu_goods_annex_type.map(key => {
                                                            let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                                            return (
                                                                <div key={item.order_id + Math.random()}>
                                                                    {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {
                                                                        let goods_annex_type_item = type_key.goods_annex_type_item
                                                                        return <p key={i + goods_annex_type_item.name + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                                    })}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                        )
                    }
                }
            },
            {
                title: '电话',
                dataIndex: ["user", "phone"],
                width: '7%',
                align: "center",
            },

            {
                title: '付款金额',
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },


            /*
            {
                title: '订单原价',
                dataIndex: 'origin_price',
                width: '7%',
                align: "center",
            },
            
           
            {
                title: '钱包抵现',
                dataIndex: 'use_user_money',
                width: '4%',
                align: "center",
            },
            */

            // {
            //     title: '配送费',
            //     dataIndex: 'delivery_price',
            //     width: '6%',
            //     align: "center",
            // },

            /*
            {
                title: '包装费',
                dataIndex: 'partner_pack_charge',
                width: '6%',
                align: "center",
            },
            {
                title: '服务费',
                dataIndex: 'service_charge',
                width: '6%',
                align: "center",
            },
           
            {
                title: '支付状态',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_pay === 1 ? "green" : "#ff0000" }}>{record.is_pay === 0 ? "未付款" : "已付款"}</span>
                    )
                }
            },
            {
                title: '取货方式',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span >{record.service_type === 1 ? "到店" : "配送"}</span>
                    )
                }
            },
           
            {
                title: '使用状态',
                width: '8%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_finish === 1 ? "green" : "#ff0000" }}>{record.is_finish === 1 ? "已使用" : "未使用"}</span>
                    )
                }
            }, */

            {
                title: '下单时间',
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            },
            {
                title: '订单状态',
                width: '5%',
                align: "center",
                // dataIndex: "order_status",
                render: (record) => {
                    switch (record.order_status) {
                        case "un_pay":
                            return "未付款";
                        // break;
                        case "wait_order_receiving":
                            return "待接单";
                        // break;
                        case "un_order_receiving":
                            return "退款中";
                        // break;
                        case "for_consumption":
                            return "待消费";

 
                        case  'wait_delivery':  
                            return  "待配送"; 
                            
            
                        case  'be_delivery':  
                            return  "配送中"; 
                          

                        // break;
                        case "finished":
                            return "已完成";
                        // break;
                        case "canceled":
                            return "已过期";
                        // break;
                        case "rejected":
                            return "已拒绝";
                        case "refunding":
                            return "退款中";
                        // break;
                        case "refunded":
                            return <div>
                                <span style={{ marginRight: "5px" }}>已退款</span>
                                {/*
                                    record?.is_refunded === 1 ?
                                        <Icon type="credit-card" style={{ color: "#f3b143" }} />
                                        : record?.is_pay === 2 ? <Icon type="wallet" /> : ""
                                */}

                            </div>;
                        // break;
                        default: return ""

                    }
                }
            },
            // {
            //     title: "退款到",
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_refunded === 1 ? "银行卡" : "钱包"
            //     }
            // },

            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        this.props.history.push("/subscriptionOrderDetail/" + record.id)
                                    }}>查看</Button>
                                {
                                    record.is_pay === 1 &&
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                visible: true,
                                                ItemId: record.id,
                                                refund_message: ""
                                            })
                                        }}>退款</Button>
                                }
                                {
                                    record.is_wait_refund === 1 &&
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        onClick={() => {
                                            OrderWaitRefund(record.id).then(res => {
                                                this.setState({
                                                    url: "&is_user_coupon=1" + this.state.two_url,
                                                    one_url: "&is_user_coupon=1",
                                                    orderValue: "",
                                                    phoneValue: "",
                                                }, () => {
                                                    this.HandleGetData(this.state.defaultShopId, 1)
                                                })
                                            })
                                        }}>待审核</Button>
                                }
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                }
            },
        ];
    }


    async componentDidMount() {
        let resp = await getShopList(1, "")
        this.setState({
            url: "",
            defaultShop: "",
            defaultShopId: "",
            shopList: resp.data.data,
        }, () => {
            this.HandleGetData(this.state.defaultShopId, 1)
        })

    }

    async HandleGetData(shopId, page) {
        let res = await GetOrderList(this.state.url, shopId, page);
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination,
            ItemId: "",
            visible: false
        })
    }



    render() {
        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });
        const { Option } = Select;
        const { consumption_type , data, meta, shopList, defaultShop, defaultShopId, url, url_1,url_2,url_3, url_4 ,one_url, two_url, visible, ItemId, refund_message } = this.state
        return (
            <div>
                {
                    !!data &&
                    <div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {
                                defaultShop ?
                                    <div>
                                        <Select showSearch
                                            defaultValue={defaultShop}
                                            placeholder="请选择店铺"
                                            style={{ width: 300, marginRight: "5px" }}
                                            onChange={(e, value) => {
                                                let data = value.props
                                                this.setState({
                                                    defaultShop: data.children,
                                                    defaultShopId: data.value,
                                                }, () => {
                                                    localStorage.setItem("shop", JSON.stringify(data))
                                                    this.HandleGetData(data.value, 1)
                                                })
                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                shopList.length !== 0 && shopList.map(item => {
                                                    return <Option value={item.id} key={item.id}>{item.title}</Option>
                                                })
                                            }
                                        </Select>
                                    </div> :
                                    <Select showSearch
                                        placeholder="请选择店铺"
                                        style={{ width: 300, marginRight: "5px" }}
                                        onChange={(e, value) => {
                                            let data = value.props
                                            this.setState({
                                                url: "",
                                                defaultShop: data.children,
                                                defaultShopId: data.value,
                                            }, () => {
                                                localStorage.setItem("shop", JSON.stringify(data))
                                                this.HandleGetData(data.value, 1)
                                            })
                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            shopList.length !== 0 && shopList.map(item => {
                                                return <Option value={item.id} key={item.id}>{item.title}</Option>
                                            })
                                        }
                                    </Select>
                            }
                            <Search
                                style={{ width: 300, marginRight: "10px", marginBottom: "5px" }}
                                placeholder={'请输入订单号'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.setState({
                                        url: "&order_sn=" + val
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}
                            />
                            <Search
                                style={{ width: 300, marginBottom: "5px", marginRight: "5px" }}
                                placeholder={'请输入手机号'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.setState({
                                        url: "&phone=" + val
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}
                            />
                            <RangePicker
                                style={{ marginBottom: "5px" }}
                                format="YYYY-MM-DD"
                                onChange={(value) => {
                                    let start_at = moment(value[0]).format("YYYY-MM-DD") + " 00:00:00"
                                    let end_at = moment(value[1]).format("YYYY-MM-DD") + " 23:59:59"
                                    this.setState({
                                        url: "&start_at=" + start_at + "&end_at=" + end_at
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }} />
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url === "" ? "#1DA57A" : "#fff", color: url === "" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "",
                                        url_1: "",
                                        url_2: "",
                                        url_3:"",
                                        url_4:"",
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                全部
                            </Button>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: this.state.url_1 === "&is_discount_order=0" ? "#1DA57A" : "#fff", color: this.state.url_1 === "&is_discount_order=0" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_discount_order=0" + url_2 + url_3 + url_4,
                                        url_1: "&is_discount_order=0"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                商品订单
                            </Button>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: this.state.url_1 === "&is_discount_order=1" ? "#1DA57A" : "#fff", color: this.state.url_1 === "&is_discount_order=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_discount_order=1" + url_2 + url_3 +url_4,
                                        url_1: "&is_discount_order=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                Deal订单
                            </Button>

                            <Button
                                style={{ marginRight: "5px", backgroundColor: this.state.url_1 === "&is_user_coupon=1" ? "#1DA57A" : "#fff", color: this.state.url_1 === "&is_user_coupon=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_user_coupon=1" + url_2 + url_3 +url_4,
                                        url_1: "&is_user_coupon=1",

                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                优惠券订单
                            </Button>
                        </div>




                        <div style={{ marginBottom: "5px" }}> 


                            <Button
                                style={{ marginRight: "5px", backgroundColor:   "#1DA57A", color:   "#fff" }}
                                onClick={() => {
                                    this.setState({
                                        // url: "",
                                        // url_1:"",
                                        // url_2:"",
                                        // url_3:"", 
                                        // orderValue: "",
                                        // phoneValue: "",
                                    }, () => {
                                        // this.columns.splice(5, 1, {
                                        //     title: intl.get("home.table.ordertime"),
                                        //     width: '8%',
                                        //     align: "center",
                                        //     render: (record) => {
                                        //         return record.created_at && record.created_at.date.substring(0, 19)
                                        //     }
                                        // })
                                        //this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                订单来源
                            </Button>




                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_4 === "&order_source=1" ? "#1DA57A" : "", color: url_4 === "&order_source=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: url_1 + url_2+ "&order_source=1" + url_3,
                                        url_4: "&order_source=1",
                                        url_2:"",
                                        orderValue: "",
                                        phoneValue: "",
                                        consumption_type:0,
                                    }, () => {
                                         
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                店铺订单
                            </Button>
                            
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_4 === "&order_source=2" ? "#1DA57A" : "", color: url_4 === "&order_source=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url:  url_1 + url_2+  url_3 +  "&order_source=2",
                                        url_4: "&order_source=2",
                                        url_2: "",
                                        orderValue: "",
                                        phoneValue: "",
                                        consumption_type:1,
                                    }, () => {
                                        
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                平台订单
                            </Button>

                           
                             
                        </div>







                         <div style={{ marginBottom: "5px" }}>


                            <Button
                                style={{ marginRight: "5px", backgroundColor:   "#1DA57A"  , color:   "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        // url: "",
                                        // one_url: "",
                                        // two_url: ""
                                    }, () => {
                                        //this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                消费方式
                            </Button>

 
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&consumption_type=0" ? "#1DA57A" : "#fff", color: url_2 === "&consumption_type=0" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&consumption_type=0" + url_1  +url_4,
                                        url_2:"&consumption_type=0",
                                        url_3:"",
                                        consumption_type:0,
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                到店
                            </Button>  

 
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&consumption_type=1" ? "#1DA57A" : "#fff", color: url_2 === "&consumption_type=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:  "&consumption_type=1"+ url_1  + url_4,
                                        url_2: "&consumption_type=1",
                                        url_3:"",
                                        consumption_type:1,
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                配送
                            </Button>
                        </div>





                        <div style={{ marginBottom: "5px" }}>



                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        // url: "",
                                        // one_url: "",
                                        // two_url: ""
                                    }, () => {
                                        //this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                状态
                            </Button>



                            

                            {/* <Button
                                style={{ marginRight: "5px", backgroundColor: url === "&is_pay=0" ? "#1DA57A" : "#fff", color: url === "&is_pay=0" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_pay=0" + url_1 + url_2
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                未付款
                            </Button> */}


                           



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_pay=1" ? "#1DA57A" : "#fff", color: url_3 === "&is_pay=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url: url_1 + url_2 + "&is_pay=1"  +url_4,
                                        url_3: "&is_pay=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已付款
                            </Button>


                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&order_status=wait_order_receiving" ? "#1DA57A" : "#fff", color: url_3 === "&order_status=wait_order_receiving" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "下单时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.created_at && record.created_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:  "&order_status=wait_order_receiving" + url_1 + url_2  +url_4 ,
                                        url_3: "&order_status=wait_order_receiving"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                待接单
                            </Button>


                             { (consumption_type == null  || consumption_type === 1 ) && 
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&order_status=wait_delivery" ? "#1DA57A" : "#fff", color: url_3 === "&order_status=wait_delivery" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:   "&order_status=wait_delivery" + url_1 + url_2   +url_4,
                                        url_3: "&order_status=wait_delivery"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                待配送
                            </Button>

                        }

                         { (consumption_type == null  || consumption_type === 1 ) && 

                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&order_status=be_delivery" ? "#1DA57A" : "#fff", color: url_3 === "&order_status=be_delivery" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:   "&order_status=be_delivery" + url_1 + url_2  +url_4,
                                        url_3: "&order_status=be_delivery"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                配送中
                            </Button>

                        }


                           { (consumption_type == null  ||   consumption_type === 0 ) && 
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&order_status=for_consumption" ? "#1DA57A" : "#fff", color: url_3 === "&order_status=for_consumption" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:  "&order_status=for_consumption" + url_1 + url_2   +url_4,
                                        url_3: "&order_status=for_consumption"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                待消费
                            </Button>
                            }



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_finish=1" ? "#1DA57A" : "#fff", color: url_3 === "&is_finish=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:  "&is_finish=1" + url_1 + url_2  +url_4,
                                        url_3: "&is_finish=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已完成
                            </Button>
                            {/* <Button
                                style={{ marginRight: "5px", backgroundColor: url === "&status=7" ? "#1DA57A" : "#fff", color: url === "&status=7" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&status=7"
                                    }, () => {
                                        this.HandleGetData(url, defaultShopId, 1)
                                    })
                                }}>
                                已取消
                            </Button> */}




                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_apply_refund=1" ? "#1DA57A" : "#fff", color: url_3 === "&is_apply_refund=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "退款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.refund_at && record.refund_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:   "&is_apply_refund=1" + url_1 + url_2  +url_4,
                                        url_3: "&is_apply_refund=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                退款中
                            </Button>


                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_refund_goods=1" ? "#58924d" : "", color: url_3 === "&is_refund_goods=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: url_1 + "&is_refund_goods=1" +  url_4,
                                        url_3: "&is_refund_goods=1",

                                        
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: "退款时间",
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.refund_at && record.refund_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已部分退款
                            </Button> 




                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&status=9" ? "#1DA57A" : "#fff", color: url_3 === "&status=9" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "退款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.refund_at && record.refund_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:   "&status=9" + url_1 + url_2  +url_4,
                                        url_3: "&status=9"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已退款
                            </Button>

                        </div>



                        


                        <div className="waperBox">
                            <Table
                                rowKey='id'
                                // bordered
                                dataSource={data}
                                columns={columns}
                                pagination={{
                                    pageSize: meta.per_page,
                                    total: meta.total
                                }}
                                onChange={(e) => {
                                    this.HandleGetData(defaultShopId, e.current)
                                }}
                            />
                        </div>
                    </div>
                }
                <Modal
                    title="确认退款"
                    visible={visible}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                            ItemId: "",
                            refund_message: ""
                        })
                    }}
                    onOk={() => {
                        OrderRefund(ItemId, { refund_message }).then(res => {

                           

                        setTimeout(() => {
                                 this.setState({
                                    //ItemId: "",
                                    //refundvisible: false
                                }, () => { 
                                     this.HandleGetData(defaultShopId, 1)
                                })
                         }, 3000)




                        }).catch(error => {
                            this.setState({
                                ItemId: "",
                                visible: false
                            })
                        })
                    }}
                >
                    <TextArea
                        value={refund_message}
                        placeholder="请输入管理员备注（客户不可见）"
                        onChange={(e) => {
                            this.setState({
                                refund_message: e.target.value
                            })
                        }}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(orderList))

