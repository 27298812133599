import React from "react";
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment-timezone';
import { Table, Popconfirm, Form, Button, Switch, Select, Modal, Input, InputNumber, message , Tooltip, Icon } from 'antd';
import { getSpuDiscountList, deleteSpuDiscount, putSpuDiscount } from "../../action/goodAction";
import { getShopList } from "../../action/shopAction"
import {getLanguageList } from "../../action/authAction";
import eatmoonLogo from "../../img/eatmoon.png"
const { Option } = Select;
class PromotionalGoodsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shopList: "",
            meta: "",
            url: "",
            partner_id: "",
            visible: false,
            rateVisible: false,
            imgVisible: false,
            itemData: false,
            itemData: "",
            sortValue: "",
            closed: "",
            activity_service_rate: "",
            eat_moon_qrcode: "",
            page: 1,
            LanguageData: "",
            language_id: 1, 

            url_1:"", 
            url_2:"",
            url_3:"",
            url_4:"", 
            url_5:"",

            opt_type : 0 ,//but点击的是店铺还是平台


        };
        this.columns = [
            {
                title: "id",
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: "店铺",
                dataIndex: ['partner', 'title'],
                width: '6%',
                align: "center",
            },
            // {
            //     title: "adid",
            //     dataIndex: 'adid',
            //     width: '8%',
            //     align: "center",
            // },
            // {
            //     title: "Qr Code",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return <img src={record.qrcode_url} style={{ width: "80px", height: "80px" }} />
            //     }
            // },


            {
                title: "活动名称",
                // dataIndex: ["_spu", "name"],
                width: '6%',
                align: "center",
                render: (record) => {


                     return <div style={{ display: "flex", alignItems: "center" }}>

                        
                        {

                             record.permanent_state === 1 &&  
                             <span><Icon type="stop" theme="filled" style={{ marginRight: "5px",color:"#ff5722" }} /></span>
                            

                        }

 
                        {record.spuManyHelp && record.spuManyHelp.state === 1 &&

                            <span style={{ color: "#ff0000" }}>[砍一刀]</span>
                        }


                        {
                            record.operation_type === 0 &&
                            <Icon type="shop" style={{ marginRight: "5px" }} />
                        }


                        {
                            record.operation_type === 1 &&
                            <img src={eatmoonLogo} alt="" style={{ width: "20px", height: "20px",margin:"5px" }} />
                        }


                       




                        <span>{record.name}</span>


                     </div>

 
                }

            },



            // {
            //     title: "price",
            //     dataIndex: ["_spu", "price"],
            //     width: '6%',
            //     align: "center",
            //     render: (record) => {
            //         return <span>${record}</span>
            //     }
            // },
            // {
            //     title: "促销类型",
            //     dataIndex: 'discount_type',
            //     width: '8%',
            //     align: "center",
            // },
            {
                title: "Discount off",
                // dataIndex: 'discount_on_fixed',
                width: '4%',
                align: "center",
                render: (record) => {
                    return record.discount_type === "fixed" ? "$" + record.discount_on_fixed : parseFloat(record.discount_on_percentage) * 100 + "%" + " off"
                }
            },


            // {
            //     title: "打折（%）",
            //     // dataIndex: 'discount_on_percentage',
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return record.discount_type === "percentage" ? parseFloat(record.discount_on_percentage) * 100 : ""
            //     }
            // },
            {
                title: "库存",
                //dataIndex: 'stock',
                width: '4%',
                align: "center",

                render: (record) => { 
                    let isRed =  ( record.stock === 0) ? true : false ; 
                    let stock = record.stock 
                    return <div style={{ display: "flex", flexDirection: "column" }}> 
                        <span style={{ marginBottom: "5px" ,color: isRed ? "#ff0000" : "" }}>{stock}</span> 
                    </div>
                }

            },


            {
                title: "销量",
                dataIndex: 'sale_quantity',
                width: '4%',
                align: "center",
            },

            // {
            //     title: "限额",
            //     dataIndex: 'quota',
            //     width: '8%',
            //     align: "center",
            // },


            {
                title: "折扣后价格",
                dataIndex: ["_spu", "discount_price"],
                width: '6%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },
            {
                title: "活动期间",
                width: '6%',
                align: "center",
                render: (record) => {
                    let start_at = record.start_at
                    let end_at = record.end_at


                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate


                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{start_at.substring(0, start_at.length - 8)}</span>
                        <span>~</span>
                        <span  style={{ color: isRed ? "#ff0000" : "" }} >{end_at.substring(0, end_at.length - 8)}</span>
                    </div>
                }
            },
            // {
            //     title: "开始时间",
            //     dataIndex: 'start_at',
            //     width: '8%',
            //     align: "center",
            // },
            // {
            //     title: "结束时间",
            //     dataIndex: 'end_at',
            //     width: '8%',
            //     align: "center",
            // },
            {
                title: "服务费",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.setState({
                                    rateVisible: true,
                                    itemData: record,
                                    activity_service_rate: parseFloat(record.activity_service_rate) * 100,
                                    order_fee_total:record.order_fee_total,
                                    order_fixed_fee:record.order_fixed_fee,
                                })
                            }}>
                            详情
                        </span>
                    )
                }
            },

            /*
            {
                title: "eatmoon锁",
                width: '6%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_lock === 1} onChange={() => this.onLockChange(record)} />
                    )
                }
            }, 
            {
                title: "系统已购",
                width: '6%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_already_buy === 1} onChange={() => this.onAlreadyBuyChange(record)} />
                    )
                }
            },*/


            {
                title: "平台显示",
                width: '5%',
                align: "center",
                render: (record) => {

                     //非终止，并且是平台deal才可以在平台上显示
                     if(record.operation_type == 1 && record.permanent_state != 1){ 
                        return (
                            <div style={{ marginTop: "41px", width: "80px" }}>
                                <Switch checked={record.is_eatmoon_show === 1} onChange={() => this.onEatMoonChange(record)} />

                                <Button type="link" style={{ padding: "0px", marginTop: "10px" }}
                                    onClick={() => {
                                        axios.get(record.eat_moon_qrcode).then(res => {
                                            this.setState({
                                                imgVisible: true,
                                                eat_moon_qrcode: res.data
                                            })
                                        })

                                    }}
                                >二维码</Button>
                            </div>
                        )
                     }
                    
                }
            },
            


            // {
            //     title: "活动状态",
            //     width: '6%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
            //                     record.end_status ?
            //                         <span style={{ color: "#ff0000" }}>已终止</span> :
            //                         <span style={{ color: "green" }}>正常</span>
            //                 }
            //             </div>
            //         )
            //     }
            // },



            {
                title: "上架",
                width: '6%',
                align: "center",
                render: (record) => {
 
                    //已终止的活动不允许上架
                    if(record.permanent_state != 1){
                        return (
                            <div>
                                {
                                    
                                     <Switch checked={record.state === 1}   onChange={() => this.onDealChange(record)}   />

                                        /*record.state === 0 ?
                                        <span style={{ color: "#ff0000" }}>已下架</span> :
                                        <span style={{ color: "green" }}>已上架</span>*/
                                }
                            </div> 
                        ) 
                    }else{
                        return ""
                    }
                    
                }
            },


            // {
            //     title: "普通下架",
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.state === 0} onChange={() => this.onChange(record)} />
            //         )
            //     }
            // },
            // {
            //     title: "是否显示",
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.is_show === 1} onChange={() => this.onShowChange(record)} />
            //         )
            //     }
            // },
            // {
            //     title: "是否永久下架",
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.permanent_state === 1} onChange={() => this.handleOverOff(record)} />
            //         )
            //     }
            // },
            // {
            //     title: "是否强制下架",
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.force_state === 1} onChange={() => this.handleMustOff(record)} />
            //         )
            //     }
            // },
            {
                title: "精选排序",
                width: '4%',
                align: "center",
                render: (record) => {

                     if(record.operation_type == 1 && record.permanent_state != 1){  
                            return <div style={{ marginTop: "41px", display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                    <span>{record.system_sort}</span>
                                    <Button type="link" style={{ padding: "0px", marginTop: "10px" }}
                                        onClick={() => {
                                            this.setState({
                                                sortVisible: true,
                                                itemData: record,
                                                sortValue: record.system_sort
                                            })
                                        }}
                                    >编辑</Button>
                                </div>
                     }
                    
                }
            },
            {
                title: "操作",
                width: '10%',
                align: "center",
                fixed: 'right',
                render: (text, record) => {
                    
                    // return record.end_status === 1 || record.sale_quantity && record.sale_quantity > 0 ?
                    //return record.end_status ?
                    // null :
                    
                    if( record.permanent_state != 1){   

                        return   <div style={{ display: "flex", flexDirection: "column" }}>  
                                {/* <Button style={{ margin: "5px" }} onClick={() => {
                                    this.props.history.push("/editpromotionalgoods/" + record.id)
                                }}>
                                    编辑
                                </Button> */}


                                <Button style={{ margin: "5px" }}
                                    onClick={() => {
                                        this.setState({
                                            visible: true,
                                            itemData: record
                                        })
                                    }} >
                                    终止
                                </Button>


                                {/*
                                    record.type !== 1 && record.state === 1 && <Popconfirm title="是否普通下架" onConfirm={() => this.onChange(record)}>
                                        <Button style={{ margin: "5px" }}>
                                            普通下架
                                        </Button>
                                    </Popconfirm>
                                */} 
                            </div>

                    }else{
                        return    <span style={{ color: "red" }}>已终止</span>
                    }
                    
                        

                         

                },
            },
        ];
    }


    async componentDidMount() {

        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })




        let resp = await getShopList("", "")
        let data = resp.data.data
        this.setState({
            shopList: data,
            partner_id: "" ,//data[0].id
        }, () => {
            this.HandleGetData()
        })
    }



    async HandleGetData() {
        let { page, url, partner_id,language_id } = this.state

        console.log("partner_id===",partner_id);

        let res = await getSpuDiscountList(page, url, partner_id,language_id)

        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }




    async handleDelete(data) {
        await deleteSpuDiscount(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    onChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.state = item.state === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    onShowChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_show = item.is_show === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    onLockChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_lock = item.is_lock === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }

    onAlreadyBuyChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_already_buy = item.is_already_buy === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }

    onEatMoonChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_eatmoon_show = item.is_eatmoon_show === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }


    onDealChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.state = item.state === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }





    handleOverOff = () => {
        let item = this.state.itemData
        let values = {}
        values.spu_id = item.spu_id
        values.permanent_state = item.permanent_state === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.setState({
                visible: false,
                closed: ""
            })
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    handleMustOff = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.force_state = item.force_state === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }

    render() {
        let { opt_type,LanguageData,shopList, meta, url, closed,  order_fixed_fee,order_fee_total,   activity_service_rate, eat_moon_qrcode, partner_id, sortValue , url_1,url_2,url_3,url_4,url_5} = this.state
        return (
            <div>
                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none', marginBottom: "10px" }}>
                   

                     {
                        LanguageData ? <Select
                            style={{ width: "100px" }}
                            defaultValue={LanguageData[1].name}
                            onChange={(e) => {
                                 this.setState({  language_id:e}, 
                                      () => {  this.HandleGetData(e)    } 
                                 )  
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select> : <div />
                    } 

                    {
                        shopList &&
                        <Select showSearch
                            placeholder="请选择店铺"
                            style={{ width: 300, marginLeft: "10px", marginRight: "10px" }}
                            onChange={(e, value) => {
                                this.setState({
                                    partner_id: e,
                                    url_1:"",
                                    url_2:"",
                                    url_3:"",
                                    url_4:"",
                                    url_5:"",
                                    page:1,
                                    
                                }, () => this.HandleGetData())

                            }}
                            /*value={partner_id}*/ 
                        >
                            {
                                shopList.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.title}</Option>
                                })
                            }
                        </Select>

                         

                    }
                    
                    <Tooltip title={'请选择店铺'}>
                        <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip>




                    {/* <Button type="primary" onClick={() => this.props.history.push("/newpromotionalgoods")} style={{ marginRight: "30px", marginLeft: '30px' }}>
                        添加促销商品
                    </Button> */}
                </div>


                 <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "10px"}}>


                        <Button
                        style={{ marginRight: "5px", backgroundColor: url === "" ? "#1DA57A" : "", color: url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                url: "",
                                url_1:"",
                                url_2:"",
                                url_3:"",
                                url_4:"",
                                url_5:"",
                                page:1,

                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        全部
                    </Button>


 

                    {/*

                    <Button
                        style={{ marginRight: "5px", backgroundColor: url === "&type=0" ? "#1DA57A" : "", color: url === "&type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                url: "&type=0"
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        内部
                    </Button>

                    <Button
                        style={{ marginRight: "5px", backgroundColor: url === "&type=1" ? "#1DA57A" : "", color: url === "&type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                url: "&type=1"
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        全站
                    </Button>

                    <Button
                        style={{ marginRight: "5px", backgroundColor: url === "&type=2" ? "#5867dd" : "", color: url === "&type=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                url: "&type=2"
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        神秘
                    </Button>
                    */}


                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_1 === "&state=1" ? "#1DA57A" : "", color: url_1 === "&state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                page:1,
                                url: "&state=1" + url_2 +  url_3 + url_4 + url_5 ,
                                url_1:"&state=1",
                            }, () => {
                                this.HandleGetData(1, this.state.url)
                            })
                        }}>
                        已上架
                    </Button>



                     <Button
                        style={{ marginRight: "5px", backgroundColor: url_1 === "&state=0" ? "#1DA57A" : "", color: url_1 === "&state=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                page:1,
                                url: "&state=0" + url_2 +  url_3 + url_4 + url_5 ,
                                url_1:"&state=0",
                            }, () => {
                                this.HandleGetData(1, this.state.url)
                            })
                        }}>
                        已下架
                    </Button>
 
                 </div>



                


                 <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "10px"}}>

                     <Button
                        style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                        onClick={() => {
                            this.setState({

                                //url: ""
                            }, () => {
                                //this.HandleGetData()
                            })
                        }}>
                        运营类型
                    </Button>


                      <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&operation_type=0" ? "#1DA57A" : "", color: url_3 === "&operation_type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&operation_type=0"+ url_1 +  url_2 + url_4 + url_5 ,
                                    url_3:"&operation_type=0",
                                    opt_type:0
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            店铺
                        </Button>




                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&operation_type=1" ? "#1DA57A" : "", color: url_3 === "&operation_type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&operation_type=1"+ url_1 +  url_2 + url_4 + url_5 ,
                                    url_3:"&operation_type=1",
                                    opt_type:1
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            平台
                        </Button> 
                 </div>



                 { 
                        opt_type == 1 && 
                         <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "10px"}}>

                         <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    //url: ""
                                }, () => {
                                    //this.HandleGetData()
                                })
                            }}>
                            平台
                        </Button>


                         <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&is_eatmoon_show=1" ? "#1DA57A" : "", color: url_2 === "&is_eatmoon_show=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({ 
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&is_eatmoon_show=1"+ url_1 +  url_3 + url_4 + url_5 ,
                                    url_2:"&is_eatmoon_show=1",

                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                             平台显示
                        </Button>





                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&is_system_sort=1" ? "#1DA57A" : "", color: url_2 === "&is_system_sort=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({ 
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&is_system_sort=1"+ url_1 +  url_3 + url_4 + url_5 ,
                                    url_2:"&is_system_sort=1",
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            平台精选
                        </Button> 
                    </div> 
                 }

                 






                  <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "10px"}}>

                    <Button
                        style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                        onClick={() => {
                            this.setState({
                                //url: ""
                            }, () => {
                               // this.HandleGetData()
                            })
                        }}>
                        行为
                    </Button>


                    


                        {/*
                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&end_status=1" ? "#1DA57A" : "", color: url === "&end_status=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&end_status=1"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已结束
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&end_status=0" ? "#1DA57A" : "", color: url === "&end_status=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&end_status=0"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            正常
                        </Button>
                        */}


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&is_expire=1" ? "#1DA57A" : "", color: url_4 === "&is_expire=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&is_expire=1"+ url_1 +  url_2 + url_3 + url_5 ,
                                    url_4:"&is_expire=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已过期
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&is_stock_empty=1" ? "#1DA57A" : "", color: url_4 === "&is_stock_empty=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&is_stock_empty=1"+ url_1 +  url_2 + url_3 + url_5 ,
                                    url_4:"&is_stock_empty=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已售罄
                        </Button>


 
                         <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&permanent_state=1" ? "#1DA57A" : "", color: url_4 === "&permanent_state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    page:1,
                                    url: "&permanent_state=1"+ url_1 +  url_2 + url_3 + url_5 ,
                                    url_4:"&permanent_state=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已终止
                        </Button>




                </div>







                 <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "15px"}}>

                    <Button
                        style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                        onClick={() => {
                            this.setState({
                                //url: ""
                            }, () => {
                                //this.HandleGetData()
                            })
                        }}>
                        排序
                    </Button>

                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=1" ? "#1DA57A" : "", color: url_5 === "&orderby=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                page:1,
                                url: "&orderby=1"+ url_1 +  url_2 + url_3 + url_4 ,
                                url_5:"&orderby=1",
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        创建时间
                    </Button>


                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=2" ? "#1DA57A" : "", color: url_5 === "&orderby=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                page:1,
                                url: "&orderby=2"+ url_1 +  url_2 + url_3 + url_4 ,
                                url_5:"&orderby=2",
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        开始时间
                    </Button>


                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=3" ? "#1DA57A" : "", color: url_5 === "&orderby=3" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                partner_id: partner_id,
                                page:1,
                                url: "&orderby=3"+ url_1 +  url_2 + url_3 + url_4 ,
                                url_5:"&orderby=3",
                            }, () => {
                                this.HandleGetData()
                            })
                        }}>
                        平台服务费
                    </Button>
                       




                 </div>



                 <div  className="waperBox">

                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    scroll={{ x: 1600 }}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.setState({
                            page: e.current
                        }, () => {
                            this.HandleGetData()
                        })

                    }}
                />
                </div>
                
                <Modal
                    title="确认终止"
                    visible={this.state.visible}
                    onOk={() => {
                        if (closed === "closed") {
                            this.handleOverOff()
                        } else {
                            message.error("输入错误！")
                        }
                    }}
                    onCancel={() => this.setState({ visible: false, closed: "" })}
                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ marginBottom: "15px" }}>请在输入框中输入"CLOSED"确认</span>
                        <Input
                            style={{ width: "200px", textAlign: 'center' }}
                            value={closed}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    closed: e.target.value.toLowerCase()
                                })
                            }} />
                    </div>

                </Modal>
                <Modal
                    title="活动服务费"
                    visible={this.state.rateVisible}
                    footer={null}
                    onCancel={() => this.setState({ rateVisible: false })}
                    /*
                    onOk={() => {
                        let item = this.state.itemData
                        if (parseFloat(activity_service_rate) === parseFloat(item.activity_service_rate) * 100) {
                            message.success("活动服务费未改动，不需要提交！")
                        } else {
                            let values = {}
                            values.spu_id = item.spu_id
                            values.activity_service_rate = parseFloat(activity_service_rate) / 100
                            putSpuDiscount(values, item.id).then(res => {
                                this.setState({
                                    rateVisible: false,
                                    activity_service_rate: ""
                                })
                                this.HandleGetData()
                            }).catch(error => {
                                console.log(error.response)
                            })
                        }



                    }}
                    onCancel={() => this.setState({ rateVisible: false, activity_service_rate: "" })}
                    */

                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", alignItems: "center",marginBottom:"10px" }}>
                        <span>固定费用:</span>
                        <Input min={0}
                            disabled
                            suffix="$"
                            style={{ width: "200px", marginLeft: "30px" }}
                            value={order_fixed_fee}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    order_fixed_fee: e.target.value
                                })
                            }} />
                    </div>


                    <div style={{ display: "flex", alignItems: "center" ,marginBottom:"10px"}}>
                        <span>活动服务费:</span>
                        <Input min={0}
                            disabled
                            suffix="%"
                            style={{ width: "200px", marginLeft: "15px" }}
                            value={activity_service_rate}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    activity_service_rate: e.target.value
                                })
                            }} />
                    </div>


                    <div style={{ display: "flex", alignItems: "center",marginBottom:"10px" }}>
                        <span>服务费合计:</span> 
                        <span style={{ paddingLeft:"15px"}}>${order_fee_total}</span> 
                    </div>



                </Modal>



                <Modal
                    title="编辑排序"
                    visible={this.state.sortVisible}
                    onCancel={() => this.setState({ sortVisible: false, itemData: "" })}
                    style={{ marginTop: "100px" }}
                    onOk={() => {
                        let { itemData, sortValue } = this.state
                        let data = { "system_sort": sortValue }
                        data.spu_id = itemData.spu_id
                        putSpuDiscount(data, itemData.id).then(res => {
                            this.HandleGetData()
                            this.setState({ sortVisible: false, itemData: "" })
                        }).catch(error => {
                            console.log(error.response)
                        })
                    }}
                >
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        <span>排序：</span>
                        <InputNumber
                            style={{ width: "300px", textAlign: 'center' }}
                            value={sortValue}
                            onChange={(e) => {
                                this.setState({
                                    sortValue: e
                                })
                            }} />
                    </div>

                </Modal>
                {
                    this.state.imgVisible &&
                    <Modal
                        visible={this.state.imgVisible}
                        width={300}
                        footer={null}
                        onCancel={() => this.setState({ imgVisible: false })}
                        style={{ marginTop: "100px" }}
                    >
                        <img src={eat_moon_qrcode} style={{ width: "250px", height: "250px" }} />

                    </Modal>
                }

            </div>
        );
    }
}

export default withRouter(Form.create()(PromotionalGoodsList))

