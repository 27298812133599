import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider } from 'antd';
import { mySubscrDetail } from "../../action/authAction";

const serviceStatus = {
    'setup': '开通服务',
    'year': '年服务',
    'month': '月服务'
}

class SubscriptionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: {} };
    }

    async componentDidMount() {
        const id = this.props.location.search.substring(1);
        if (id) {
            let res = await mySubscrDetail(id);
            this.setState({ data: res.data })
        }

    }


    render() {
        const { data } = this.state;

        return (
            <Card>
                <Descriptions title="基本信息">
                    <Descriptions.Item label="id">{data?.id}</Descriptions.Item>
                    <Descriptions.Item label="价格">${data?.price}</Descriptions.Item>
                    <Descriptions.Item label="状态">{data?.status === 0 ? '已过期或终止' : data?.status === 1 ? "服务中" : ''}</Descriptions.Item>
                    <Descriptions.Item label="类型">{data?.prod_type === 'package' ? 'package套餐' : data?.prod_type === 'addon' ? 'addon插件' : ''}</Descriptions.Item>
                    <Descriptions.Item label="服务类型">{serviceStatus[data?.service_type]}</Descriptions.Item>
                    <Descriptions.Item label="创建时间">{data?.created_at?.date}</Descriptions.Item>
                    <Descriptions.Item label="服务起始时间">{data?.service_start_at}</Descriptions.Item>
                    <Descriptions.Item label="服务终止时间">{data?.service_end_at}</Descriptions.Item>
                    <Descriptions.Item label="订单是否过期">{data?.is_expire === 1 ? "过期" : "未过期"}</Descriptions.Item>
                </Descriptions>
                <Divider />
                {data?.prod_type === 'package' && <Descriptions title="产品套餐">
                    <Descriptions.Item label="名称">{data?.prod?.name}</Descriptions.Item>
                    <Descriptions.Item label="套餐百分比">{data?.prod?.rate}%</Descriptions.Item>
                    <Descriptions.Item label="收费减免周期">{data?.prod?.day_of_fee_reduction}%</Descriptions.Item>
                    <Descriptions.Item label="收费减免费率">{data?.prod?.fee_reduction_rate}</Descriptions.Item>
                    <Descriptions.Item label="设置费">{data?.prod?.setup_fee}</Descriptions.Item>
                    <Descriptions.Item label="技术服务费">{data?.prod?.service_fee}元/年</Descriptions.Item>
                    <Descriptions.Item label="月固定费">{data?.prod?.monthly_fixed_fee}</Descriptions.Item>
                    <Descriptions.Item label="体验期">{data?.prod?.trial_period_days}天</Descriptions.Item>
                    <Descriptions.Item label="是否支持小程序">{data?.prod?.have_weapp === 1 ? '是' : '否'}</Descriptions.Item>
                    <Descriptions.Item label="是否支持h5">{data?.prod?.have_h5 === 1 ? '是' : '否'}</Descriptions.Item>
                    <Descriptions.Item label="是否支持独立域名">{data?.prod?.have_domain === 1 ? '是' : '否'}</Descriptions.Item>
                </Descriptions>}
                {data?.prod_type === 'addon' && <Descriptions title="产品插件">
                    <Descriptions.Item label="名称">{data?.prod?.name}</Descriptions.Item>
                    <Descriptions.Item label="适应端">{data?.prod?.client}</Descriptions.Item>
                    <Descriptions.Item label="价格">${data?.prod?.price}</Descriptions.Item>
                    <Descriptions.Item label="路由">{data?.prod?.route}</Descriptions.Item>
                    <Descriptions.Item label="排序">{data?.prod?.sort}</Descriptions.Item>
                </Descriptions>}
                <Divider />
            </Card>

        );
    }
}

export default withRouter(SubscriptionDetail)

