import React from 'react'
import { withRouter } from 'react-router-dom'
import {Checkbox, Form, Input, Upload, Icon, message, Button, List, Select, Modal, Card, Switch } from 'antd';

import { NewShop, QueryRegion, queryAddress, getPartnerList, getSalesAreaList, getSalesAreaUnionList,getSalesAreaSubList ,getStateList} from "../../action/shopAction"
import { getPackageList, getLanguageList } from "../../action/authAction"
import { API } from '../../util/api';
const { Search } = Input
function ChangeBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


class NewShopComponent extends React.Component {

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        is_use: true,
        is_pack_charge: true,
        is_line_up_pay: 0,
        address: "",
        suburbsList: "",
        isShow: true,
        loading: false,
        imageUrl: "",
        logo_file: "",
        foodLicenseLoading: false,
        weChatLoading: false,
        businessLicenseImageUrl: "",
        businessLicenseImage: "",
        businessLicenseLoading: false,
        foodLicenseFileList: "",
        weChatFileList: "",
        packageData: "",
        LanguageData: "",
        SalesAreaData: "",
        SalesAreaUnionData:"",
        regionList: [],
        is_in_store: true,
        is_charge_packing_fee: true,
        is_status: false,
        is_open_goods_annex: true,
        packageItem: "",
        SalesAreaSubList: "",
        emailCodeVisible: false,
        verify_code_key: "",
        verify_code: "",
        is_system_shop:false,
        shop_type:"",//店铺类型
        shop_type_data: [
            {
                value: 0,
                label: "网店",
                disabled: false
            },
            {
                value: 1,
                label: "实体店"
            },

        ],
        isRequired:false,



    };


    async componentDidMount() {
        let LanguageData = await getLanguageList()
        let SalesAreaData = await getSalesAreaList()
        let SalesAreaUnionData = await getSalesAreaUnionList()
        let StateData = await getStateList()
        let EmpartnerData = await getPartnerList() 
        let emptyObj = [{id:'',name:"请选择"}];
        

        //let SalesAreaUnionDataNew = emptyObj.concat(SalesAreaUnionData.data.data); // 将newObj与原始数组进行拼接

         

        this.setState({
            LanguageData: LanguageData.data.data,
            SalesAreaData: emptyObj.concat(SalesAreaData.data.data),
            SalesAreaUnionData: emptyObj.concat(SalesAreaUnionData.data.data),//SalesAreaUnionData.data.data,
            StateData: StateData.data.data,
            EmpartnerData: EmpartnerData.data.data

        }, () => this.handleGetPackageData(LanguageData.data.data[0].id))

        //获取地区
        let RegionList = await QueryRegion();
        this.setState({
            regionList: RegionList.data.data
        })
    }

    HandleGetSalesAreaSub = (id) => {
        let url = "?sales_area_id=" + id
        getSalesAreaSubList(url).then(res => {

            let emptyObj = [{id:'',name:"请选择"}];
            this.setState({
                SalesAreaSubList: emptyObj.concat(res.data.data),
            })
        })
    }

    handleGetPackageData = async (id) => {
        let packageData = await getPackageList(id)
        this.setState({
            packageData: packageData.data.data,
        })
    }


    handleChangeBusinessLicense = info => {
        if (info.file.status === 'uploading') {
            this.setState({ businessLicenseLoading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            ChangeBase64(info.file.originFileObj || info.file, (businessLicenseImageUrl, img) =>
                this.setState({
                    businessLicenseImageUrl,
                    businessLicenseLoading: false,
                    businessLicenseImage: img
                }),
            );
        }
    };

    handleChangeLogo = async info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            ChangeBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    logo_file: img
                }),
            );
        }
    };

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    foodLicenseFileList: info.fileList
                })
            }
        }
    };
    handleUploadWeChat = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    weChatFileList: info.fileList
                })
            }
        }
    };

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            foodLicenseLoading: true,
            imageUrl: file.url || file.preview
        })
    };
    handlePreviewWeChat = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            weChatLoading: true,
            imageUrl: file.url || file.preview
        })
    };

    handleCancel = () => {
        this.setState({
            foodLicenseLoading: false,
            weChatLoading: false
        });
    };


    HandleChoosePayType = (e) => {
        this.setState({
            pay_type: e.target.value,
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.is_system_shop = this.state.is_system_shop ? "1" : "0"
                values.shop_type = this.state.shop_type.toString()
                values.pay_type = "0"
                values.is_in_store = this.state.is_in_store ? "1" : "0"
                values.is_pack_charge = this.state.is_pack_charge ? "1" : "0"
                values.is_charge_packing_fee = this.state.is_charge_packing_fee ? "1" : "0"
                values.status = this.state.is_status ? "1" : "0"
                values.is_open_goods_annex = this.state.is_open_goods_annex ? "1" : "0"
                values.request_language_id = values.request_language_id
                values.verify_code_key = this.state.verify_code_key
                values.verify_code = this.state.verify_code
                values["partner_bank_account[name]"] = values.partner_bank_account_name ? values.partner_bank_account_name : ""
                values["partner_bank_account[bsb]"] = values.partner_bank_account_bsb ? values.partner_bank_account_bsb : ""
                values["partner_bank_account[number]"] = values.partner_bank_account_number ? values.partner_bank_account_number : ""
            }
            this.SubmitData(values)
        });
    };

    async SubmitData(data) {
        const { businessLicenseImage, businessLicenseImageUrl, logo_file, foodLicenseFileList, weChatFileList } = this.state
        let formData = new FormData()
        let imgposition = [];
        let wechatPosition = [];
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (foodLicenseFileList && foodLicenseFileList.length > 0) {
            for (let i in foodLicenseFileList) {
                imgposition.push(i);
                formData.append('partner_food_license[' + i + ']', foodLicenseFileList[i].originFileObj);
            }
        }
        if (weChatFileList && weChatFileList.length > 0) {
            for (let i in weChatFileList) {
                wechatPosition.push(i);
                formData.append('partner_wechat_image[' + i + ']', weChatFileList[i].originFileObj);
            }
        }
        if (imgposition.length > 0) {
            formData.append('food_license_img_data', imgposition.toString());
        }
        if (wechatPosition.length > 0) {
            formData.append('partner_wechat_image_data', wechatPosition.toString());
        }
        businessLicenseImage && formData.append("business_license_file", businessLicenseImage)
        await NewShop(formData)
        this.props.history.push("/shop")
    }



    handleChangeEditor = (editorState) => {
        this.setState({ editorState })
    }

    checkPsd(rule, value, callback) {
        let password2 = this.props.form.getFieldValue('password2');
        if (password2 && password2 !== value) {
            callback(new Error('两次密码输入不一致'));
        } else {
            callback();
        }
    }

    checkPsd2(rule, value, callback) {
        let password = this.props.form.getFieldValue('password');
        if (password && password !== value) {
            callback(new Error('两次密码输入不一致'));
        } else {
            callback();
        }
    }


     checkCustomerCode(rule, value, callback) { 
        if ( ! /[0-9a-zA-Z]{5}/.test(value)) {
             callback(new Error("请输入5位数字或者字母"));
        }else if(value.length > 5){
             callback(new Error("请输入5位数字或者字母"));   
        }else {
            callback();
        }
    }





    render() {

        const {  shop_type_data,isRequired } = this.state
        const { getFieldDecorator } = this.props.form;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const uploadBusinessLicenseButton = (
            <div>
                <Icon type={this.state.businessLicenseLoading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );


        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const {is_system_shop, LanguageData, isShow, suburbsList, imageUrl, businessLicenseImageUrl, packageData, foodLicenseLoading, weChatLoading, loading, businessLicenseLoading, regionList, packageItem, foodLicenseFileList, weChatFileList, EmpartnerData ,  StateData,SalesAreaData,  SalesAreaUnionData, SalesAreaSubList, is_status, is_open_goods_annex, emailCodeVisible } = this.state;
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <Card style={{ margin: "30px" }}>
                    <Form.Item
                        label="用户名"
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                max: 16,
                                min: 3,
                                message: '请输入长度3-16位的用户名!'
                                //  message: '请输入首位为英文字母长度3-16位的用户名!' 
                            }],
                            getValueFromEvent: (event) => {
                                // let isStr = /^[a-zA-Z]+$/.test(event.target.value[0])
                                // if (isStr) {
                                //     return event.target.value.replace(/\W/g, '')
                                // }
                                return event.target.value.toLowerCase()
                            }
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        style={{ margin: "0px" }}
                    >
                        {getFieldDecorator('password', {
                            rules: [{ 
                                required: true,
                                pattern: new RegExp(/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,32}$/, "g") , 
                                message: "请输入6-32位数字和字母，允许特殊符号"
                             },  
                              //{ max: 16, min: 6, message: "请保持密码长度为6-16位" }
                            ],
                            getValueFromEvent: (event) => {
                                return event.target.value //.replace(/\W/g, '')
                            }
                        })(<Input />)}
                    </Form.Item>
                    {/* <Form.Item
                    label="确认密码"
                >
                    {getFieldDecorator('password_confirmation', {
                        rules: [{ required: true, message: 'Please input your password confirmation!', whitespace: true },
                        { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }],
                    })(<Input />)}
                </Form.Item> */}

                </Card>

                <Card style={{ margin: "30px" }}>
                    <Form.Item
                        label={<span>选择录入的语言</span>}
                    >
                        {getFieldDecorator('request_language_id', {
                            rules: [{ required: true, message: '请选择语言!' }],
                        })(
                            <Select
                                onChange={(e) => {
                                    this.handleGetPackageData(e)
                                }}
                            >
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        label={<span>店铺后台默认语言</span>}
                    >
                        {getFieldDecorator('manage_language_id', {
                            rules: [{ required: true, message: '请选择语言!', whitespace: true }],
                        })(
                            <Select>
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id.toString()}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={<span>店铺前台默认语言</span>}
                        style={{ margin: "0px" }}
                    >
                        {getFieldDecorator('front_language_id', {
                            rules: [{ required: true, message: '请选择语言!', whitespace: true }],
                        })(
                            <Select>
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id.toString()}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                </Card>





                <Card style={{ margin: "30px" }}>
                    <Form.Item
                        label="选择店铺套餐"
                        style={{ margin: "0px" }}
                    >
                        {getFieldDecorator('package_id', {
                            rules: [{ required: true, message: '请选择套餐!' }],
                        })(<Select onChange={(e) => {
                            for (let i in packageData) {
                                if (packageData[i].id === e) {
                                    this.setState({
                                        packageItem: packageData[i]
                                    })
                                }
                            }
                        }}>
                            {packageData && packageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>)}
                    </Form.Item>
                </Card>






                <Card style={{ margin: "30px" }}>
                    <Form.Item
                        label="店铺名称"
                    >
                        {getFieldDecorator('title', {
                            rules: [{ required: true, message: '请输入店铺名称', whitespace: true }],
                        })(<Input />)}
                    </Form.Item>


                    <Form.Item label="是否官方店铺">
                        <Switch checked={is_system_shop} onChange={() => {
                            this.setState({
                                is_system_shop: !is_system_shop
                            })
                        }} />
                    </Form.Item>

                    <Form.Item
                        label='店铺类型'
                    >
                        {getFieldDecorator('shop_type', {
                            rules: [{ required: true, message: '请选择店铺类型' }],
                            initialValue: [0]
                        })(
                            <Checkbox.Group
                                options={shop_type_data}
                                onChange={(e) => { 
                                    this.setState({
                                        shop_type: e.length > 1 ? 2 : e[0]
                                    })

                                    let e_data = e.length > 1 ? 2 : e[0];
                                    if(e_data == 1 || e_data == 2){
                                         this.setState({
                                            isRequired: true
                                        })
                                    }else{
                                         this.setState({
                                            isRequired: false
                                        })
                                    }
                                    console.log('2222',e_data);
                                }} />
                        )}
                    </Form.Item>





                    <Form.Item
                        label="Customer Code"
                    >
                        {getFieldDecorator('customer_code', {
                              rules: [{ 
                                required: true,  
                                message: "请输入5位数字或者字母", 
                                whitespace: true 
                                },

                                { validator: (rule, value, callback) => { this.checkCustomerCode(rule, value, callback) } }


                                ],
                            
                            // })(<Input disabled={!IsSuper} />)}
                        })(<Input />)}
                    </Form.Item>





                    <Form.Item
                        label="电话"
                    >
                        {getFieldDecorator('phone', {
                            // rules: [{ required: true, message: '请输入电话!', whitespace: true }],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="备用电话"
                    >
                        {getFieldDecorator('reserve_phone', {
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>Street No.</span>}
                    >
                        {getFieldDecorator('streets', {
                            rules: [{ required: false, message: '请输入street!' }],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>suburb</span>}
                        style={{ margin: "0px" }}
                    >
                        {getFieldDecorator('suburbs', {
                            rules: [{ required: isRequired, message: '请输入suburbs' }],
                        })(<Input onChange={(e) => {
                            e.persist()
                            let key = e.target.value
                            if (key.length > 2) {
                                queryAddress(key).then(res => {
                                    this.setState({
                                        suburbsList: res.data,
                                        isShow: true
                                    })
                                })
                            }
                        }}
                        />)}
                        {
                            suburbsList && isShow && <List
                                size="small"
                                bordered
                                dataSource={suburbsList}
                                renderItem={item =>
                                    <List.Item onClick={() => {
                                        this.props.form.setFieldsValue({
                                            suburbs: item.name + ", " + item.state.abbreviation + ", " + item.postcode
                                        })
                                        this.setState({ isShow: false })
                                    }}>{item.name}, {item.state.abbreviation}, {item.postcode}</List.Item>
                                }
                            />
                        }

                    </Form.Item>
                </Card>



                <Card style={{ margin: "30px" }}>
                    



                    {/*
                    <Form.Item label="邮箱"> 
                        <div> 
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    { required: true, message: '请输入邮箱!' }
                                ],
                            })(
                                <Search
                                    placeholder="请输入邮箱"
                                    enterButton="发送"
                                    onSearch={value => {
                                        let data = { "provider": "email", "email": value }
                                        fetch(API.EmailBASEURL + API.verifyCode, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify(data),
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                this.setState({
                                                    emailCodeVisible: true,
                                                    verify_code_key: data.verify_code_key
                                                })
                                            })
                                            .catch((error) => {
                                                console.error('Error:', error);
                                            });

                                    }}
                                />
                            )}
                            {
                                emailCodeVisible &&
                                <Input
                                    placeholder='请输入验证码'
                                    style={{ width: "300px" }}
                                    onChange={(e) => {
                                        e.persist()
                                        this.setState({
                                            verify_code: e.target.value
                                        })
                                    }}
                                />
                            }

                        </div>

                    </Form.Item> */}



                    <Form.Item
                        label={<span>邮箱</span>}
                    >
                        {getFieldDecorator('email', {
                            rules: [ {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },{ required: true, message: '请输入邮箱' }],
                        })(<Input />)}
                    </Form.Item>




                    <Form.Item
                        label={<span>二级域名前缀</span>}
                    >
                        {getFieldDecorator('sld_domain', {
                            rules: [{ required: true, message: '请输入二级域名前缀!' }],
                        })(<Input />)}
                    </Form.Item>






                    <Form.Item
                        label={<span>独立域名</span>}
                    >
                        {getFieldDecorator('domain', {
                        })(<Input />)}
                    </Form.Item>

 

                </Card>


                {/* <Card style={{ margin: "30px" }}>
                    <Form.Item label="店铺状态">
                        <Switch checked={is_status} onChange={() => {
                            this.setState({
                                is_status: !is_status
                            })
                        }} />
                    </Form.Item> */}


                {/* <Form.Item label="开启附加商品">
                        <Switch checked={is_open_goods_annex} onChange={() => {
                            this.setState({
                                is_open_goods_annex: !is_open_goods_annex
                            })
                        }} />
                    </Form.Item> */}
                {/* </Card> */}



 

                <Card style={{ margin: "30px" }}> 
                    <Form.Item
                        label={<span>所属国家</span>}
                    >
                        {getFieldDecorator('region_id', {
                            rules: [{ required: true, message: '请选择国家!' }],
                            initialValue: 1
                        })(
                            <Select disabled>
                                {regionList && regionList.map(el => <Select.Option key={el.id} value={el.id}>{el.full_name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>



                    {/*
                        
                        <Form.Item
                            label={<span>州</span>}
                        >
                            <Input value="NSW" disabled />
                        </Form.Item>

                    */}



                    



                    <Form.Item
                        label={<span>州</span>}
                    >
                        {getFieldDecorator('state_id', {
                            rules: [{ required: true, message: '请选择州' }],
                        })(
                            <Select
                                onChange={(e) => {
                                    this.setState({
                                        //SalesAreaSubList: ""
                                    }, () => {
                                        //this.HandleGetSalesAreaSub(e)
                                    })

                                }}
                            >
                                {StateData && StateData.map(el => <Select.Option key={el.id} value={el.id}>{el.state}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>







                    <Form.Item
                        label={<span>所在联合大区</span>}
                    >
                        {getFieldDecorator('sales_area_union_id', {
                            rules: [{ required: isRequired, message: '请选择联合大区' }],
                        })(
                            <Select
                                onChange={(e) => {
                                    this.setState({
                                        //SalesAreaSubList: ""
                                    }, () => {
                                        //this.HandleGetSalesAreaSub(e)
                                    })

                                }}
                            >
                                {SalesAreaUnionData && SalesAreaUnionData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>



                    <Form.Item
                        label={<span>所在销售大区</span>}
                    >
                        {getFieldDecorator('sales_area_id', {
                            rules: [{ required: isRequired, message: '请选择销售大区' }],
                        })(
                            <Select
                                onChange={(e) => {
                                    this.setState({
                                        SalesAreaSubList: ""
                                    }, () => {
                                        this.HandleGetSalesAreaSub(e)
                                    })

                                }}
                            >
                                {SalesAreaData && SalesAreaData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>



                    {
                        SalesAreaSubList &&
                        <Form.Item
                            label="销售小区"
                        >
                            {getFieldDecorator('sales_area_sub_id',

                                {rules: [{ required: isRequired, message: '请选择销售小区' }],}

                                )(
                                <Select placeholder="请选择销售小区">
                                    {SalesAreaSubList && SalesAreaSubList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                    }


                    
                </Card>











              <Card style={{ margin: "30px" }}>
                   <Form.Item
                        label={<span>合作伙伴</span>}
                    >
                        {getFieldDecorator('empartner_id', {
                            rules: [{ required: false, message: '请选择合作伙伴' }],
                        })(
                            <Select
                                onChange={(e) => {
                                    

                                }}
                            >
                                {EmpartnerData && EmpartnerData.map(el => <Select.Option key={el.id} value={el.id}>{el.title}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>


                  







                </Card>











                <Card style={{ margin: "30px" }}>
                    <Form.Item
                        label={<span>公司名称</span>}
                    >
                        {getFieldDecorator('company', {
                            // rules: [{ required: true, message: '请输入公司名称!' }],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>ABN</span>}
                    >
                        {getFieldDecorator('abn', {
                            // rules: [{ required: true, message: '请输入ABN!' }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item
                        label={<span>银行名称</span>}
                    >
                        {getFieldDecorator('partner_bank_account_name', {
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>BSB</span>}
                    >
                        {getFieldDecorator('partner_bank_account_bsb', {
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>银行账号</span>}
                    >
                        {getFieldDecorator('partner_bank_account_number', {
                        })(<Input />)}
                    </Form.Item>

                    {/* 
                <Form.Item
                    label={<span>订单固定服务费 $</span>}
                >
                    <Input disabled value={packageItem.order_fixed_fee} />
                </Form.Item>
                <Form.Item
                    label={<span>订单服务费</span>}
                >
                    <Input disabled value={packageItem.rate} />
                </Form.Item> */}

                    <Form.Item label={<span>营业执照</span>}>
                        {getFieldDecorator('business_license_file', {
                            rules: [{ required: false }],
                        })(
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload}
                                onChange={this.handleChangeBusinessLicense}
                            >
                                {businessLicenseImageUrl && !businessLicenseLoading ? <img src={businessLicenseImageUrl} alt="avatar" style={{ width: '100%', objectFit: 'cover' }} /> : uploadBusinessLicenseButton}
                            </Upload>)}
                    </Form.Item>

                    <Form.Item
                        label="许可证"
                    >
                        <div>
                            <Upload
                                listType="picture-card"
                                fileList={foodLicenseFileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleUpload}
                                beforeUpload={() => {
                                    return false;
                                }}
                            >
                                {foodLicenseFileList.length >= 10 ? null : (
                                    <div>
                                        <div className="ant-upload-text">Add Picture</div>
                                    </div>
                                )}
                            </Upload>
                            <Modal
                                visible={foodLicenseLoading}
                                footer={null}
                                onCancel={this.handleCancel}
                            >
                                <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                            </Modal>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="微信二维码"
                    >
                        <div>
                            <Upload
                                listType="picture-card"
                                fileList={weChatFileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleUploadWeChat}
                                beforeUpload={() => {
                                    return false;
                                }}
                            >
                                {weChatFileList.length >= 10 ? null : (
                                    <div>
                                        <div className="ant-upload-text">Add Picture</div>
                                    </div>
                                )}
                            </Upload>
                            <Modal
                                visible={weChatLoading}
                                footer={null}
                                onCancel={this.handleCancel}
                            >
                                <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                            </Modal>
                        </div>
                    </Form.Item>

                    {/* <Form.Item
                    label={<span>店铺logo</span>}
                >
                    {getFieldDecorator('logo_file', {
                        rules: [{ required: true }],
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangeLogo}
                        >
                            {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%', objectFit: 'cover' }} /> : uploadButton}
                        </Upload>)}
                </Form.Item> */}
                </Card>







                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form >
        );

    }
}

export default withRouter(Form.create()(NewShopComponent))
