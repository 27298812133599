import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Popconfirm, Form, Button, Modal, Select, Switch } from 'antd';
import { getPartnerLangList, AddPartnerLang, editPartnerLang, deletePartnerLanguage, getShopList } from "../../action/shopAction";
import { getLanguageList } from "../../action/authAction";

const { Option } = Select;

const type = {
    1: true,
    0: false
}

class LinkBussness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editRow: '',
            visible: false,
            langData: [],
            shopList: []
        };
    }

    async componentDidMount() {
        this.getData();
        getLanguageList().then(res => {
            this.setState({ langData: res.data.data })
        })
        getShopList().then(res => {
            let data = res.data.data
            this.setState({
                shopList: data,
            })
        })

    }

    getData = () => {
        getPartnerLangList().then(res => {
            this.setState({ data: res.data.data })
        })

    }

    edit(key) {
        this.setState({ editRow: key, visible: true });
    }

    async handleDelete(data) {
        await deletePartnerLanguage(data.id);
        this.getData();
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            editRow: ''
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { editRow } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const val = {
                    ...values
                }
                val.is_default = values.is_default ? 1 : 0;
                val.is_use = values.is_use ? 1 : 0;
                if (editRow) {
                    editPartnerLang(editRow.id, val).then(res => {
                        this.getData()
                        this.setState({
                            visible: false,
                            editRow: ''
                        });
                    })
                } else {
                    AddPartnerLang(values).then(res => {
                        this.getData()
                        this.setState({
                            visible: false,
                            editRow: ''
                        });
                    })
                }
            }
        });
    };


    render() {

        const { getFieldDecorator } = this.props.form;
        const { shopList, langData, editRow } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                align: "center",
            },
            {
                title: '商家名称',
                dataIndex: 'partner_id',
                align: "center",
                render: text => {
                    const { shopList } = this.state;
                    const obj = shopList.find(el => el.id === text);
                    return obj && obj.name
                }
            }, {
                title: '语言',
                dataIndex: 'language_id',
                align: "center",
                render: text => {
                    const { langData } = this.state;
                    const obj = langData.find(el => el.id === text);
                    return obj && obj.name
                }
            }, {
                title: '是否使用',
                dataIndex: 'is_use',
                align: "center",
                render: text => <span>{text === 1 ? '是' : '否'}</span>
            }, {
                title: '是否默认',
                dataIndex: 'is_default',
                align: "center",
                render: text => <span>{text === 1 ? '是' : '否'}</span>
            },
            {
                title: '操作',
                dataIndex: 'operation',
                align: "center",
                render: (text, record) => {
                    return <>
                        <Button onClick={() => this.edit(record)} style={{ marginRight: 10 }}>
                            编辑
                        </Button>
                        <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                            <Button type="danger" ghost>
                                删除
                            </Button>
                        </Popconfirm>
                    </>
                },
            },

        ];

        return (
            <div>
                <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "flex-end", alignItems: "center", backgroundColor: '#fff' }}>
                    <Button type="primary" onClick={() => this.showModal()} style={{ marginRight: "100px" }}>
                        <PlusOutlined /> 新建
                    </Button>
                </div>
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={columns}
                    className="waperBox"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
                <Modal
                    destroyOnClose
                    title="选择语言"
                    visible={this.state.visible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    width='600px'
                >
                    <Form onSubmit={this.handleSubmit} className="waperBox FormView" {...formItemLayout}>
                        <Form.Item
                            label="商家"
                        >
                            {getFieldDecorator('partner_id', {
                                rules: [{ required: true, message: '请选择商家!' }],
                                initialValue: editRow && editRow.partner_id
                            })(
                                <Select style={{ width: '100%' }}>
                                    {shopList && shopList.map(el => <Option value={el.id} key={el.id}>{el.name}</Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="语言"
                        >
                            {getFieldDecorator('language_id', {
                                rules: [{ required: true, message: '请选择语言!' }],
                                initialValue: editRow && editRow.language_id
                            })(
                                <Select style={{ width: '100%' }}>
                                    {langData && langData.map(el => <Option value={el.id} key={el.id}>{el.name}</Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="是否使用"
                        >
                            {getFieldDecorator('is_use', {
                                valuePropName: 'checked',
                                initialValue: editRow ? type[editRow.is_use] : true
                            })(<Switch checkedChildren="是" unCheckedChildren="否" />)}
                        </Form.Item>

                        <Form.Item
                            label="是否默认"
                        >
                            {getFieldDecorator('is_default', {
                                valuePropName: 'checked',
                                initialValue: editRow ? type[editRow.is_default] : true
                            })(<Switch checkedChildren="是" unCheckedChildren="否" />)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(LinkBussness))

