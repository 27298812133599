import React from 'react'
import { Form, Button } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { getUserAgreement, UpdateUserAgreement } from "../../action/authAction";

class Agreement extends React.Component {
    state = {
        editorState: "",
    };

    async componentDidMount() {
        let data = await getUserAgreement()
        this.setState({
            editorState: BraftEditor.createEditorState(data.data.content),
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let { editorState } = this.state
        UpdateUserAgreement({ "content": editorState.toHTML() }).then(res => {

        })
    };

    handleChange = (editorState) => {
        this.setState({ editorState })
    }


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item
                    label="提示"
                >
                    <div>
                        请使用“ $partner_title ”代替公司名称
                    </div>
                </Form.Item>
                <Form.Item
                    label="内容"
                >
                    <div style={{
                        border: "1px solid #dedede",
                    }}>
                        <BraftEditor
                            value={this.state.editorState}
                            onChange={this.handleChange} />
                    </div>
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form >
        );
    }
}

export default Agreement