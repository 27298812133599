import React from "react";
import { Table, Popconfirm, Button, Select } from 'antd';
import { getApplyPackageList, UpdateApplyPackage,DeleteApplyPackage } from "../../action/authAction";

class ApplyPackageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                editable: false,
                align: "center",
            },
            {
                title: '套餐名称',
                dataIndex: ['package', 'name'],
                width: '10%',
                editable: true,
                align: "center",
            },
            // {
            //     title: '订单服务费固定抽成',
            //     dataIndex: 'order_fixed_fee',
            //     align: "center",
            // },
            {
                title: '初始费',
                dataIndex: ['package', 'setup_fee'],
                width: '10%',
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>${record}</span>
                    }
                }
            },
            {
                title: '订单固定服务费',
                dataIndex: ['package', 'order_fixed_fee'],
                width: '10%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>${record}</span>
                    }

                }
            },
            {
                title: '活动订单服务费',
                dataIndex: ['package', 'activity_service_rate'],
                width: '10%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>{record}%</span>
                    }

                }
            },
            {
                title: '商品订单服务费',
                dataIndex: ['package', 'rate'],
                width: '10%',
                editable: true,
                align: "center",
                render: (record) => {
                    if (record || record === 0) {
                        return <span>{record}%</span>
                    }
                }
            },
            {
                title: '申请商家',
                dataIndex: ['partner', 'name'],
                width: '10%',
                editable: true,
                align: "center",
            },

            {
                title: '操作',
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            {/*
                                record.is_dispose === 1 ?
                                    <Button style={{ backgroundColor: "#dedede" }}>
                                        已处理
                                    </Button> :
                                    <Button type="primary" onClick={() => this.edit(record)}>
                                        同意
                                    </Button>
                                */ 
                                }

                            {/*<Button  style={{ marginLeft: "10px" }}  onClick={() => this.delete(record)} >
                                删除
                            </Button>*/}


                            <Popconfirm title={"确认删除"} onConfirm={() => this.delete(record)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>





                        </div>
                    );
                },
            },
        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getApplyPackageList();
        this.setState({ data: res.data.data })
    }



    edit(key) {
        UpdateApplyPackage(key.id, { "is_dispose": 1 }).then(res => {
            this.HandleGetData()
        })
    }


     delete(key) {
        DeleteApplyPackage(key.id, { }).then(res => {
            this.HandleGetData()
        })
    }




    render() {
        return (
            <div>
                <Table
                    rowKey={(record, index) => record.id.toString()}
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default ApplyPackageList

