import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Select } from 'antd';
import { GetConfigSet, UpdateConfigSet } from "../../action/authAction";
import { getRegionList } from "../../action/shopAction";

class SysTemSet extends React.Component {

    state = {
        data: "",
    };

    async componentDidMount() {
        let res = await GetConfigSet()
        let regionList = await getRegionList()
        let allData = res.data.data
        let data = []
        data.region_id = allData.find(el => el.key === "region_id").value
        this.setState({
            data: data,
            regionList: regionList.data.data
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let data = []
                data.push({
                    "key": "region_id",
                    "value": values.region_id,
                })
                UpdateConfigSet(data)
            }
        });
    };


    render() {
        const { data, regionList } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                <Form.Item
                    label="站点所属国家"
                >
                    {getFieldDecorator('region_id', {
                        rules: [{ required: false, message: 'Please select region!' }],
                        initialValue: data && parseInt(data.region_id)
                    })(
                        <Select disabled>
                            {regionList && regionList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
