import React from 'react'
import { withRouter } from 'react-router-dom'
import {Form, Descriptions, Card, Divider, Table, Button, message, Modal, Input, Radio,Popconfirm ,Upload ,Icon ,Tooltip ,DatePicker, } from 'antd';
import { getOrderDetail,putOrder, confirmOrderOrder , StripeRefundOrder , putOrderStatus} from "../../action/goodAction"
import {  InputNumber,  Switch,   Select, Popover   } from 'antd';
import { GetPartnerStatementDetail ,  createStatement, putPartnerStatementPay,putPartnerStatementSettle} from "../../action/authAction";
import { getShopList, putShop } from "../../action/shopAction";
import intl from 'react-intl-universal';
import moment from 'moment';
const { TextArea } = Input;

let currency_symbol = "$"; 

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}




class OrderDetail extends React.Component {
    //constructor(props) {
    //    super(props); //this.state = {}
        state = { 
             
            is_show: false, 
            order_gst: 0 ,

            order_gst_inc : 0,//含gst 
            order_gst_excl:0,//不含gst 
            order_gst_total:0,//总计gst
            weight_total:0,

            deliveryVisible:false,//发货管理
            refundvisible:false,
            refundvisibleGoods:false,
            refund_goods_price:0,//部分商品退款的价格
            ItemData: "",
            partner_message:"",
            imageUrl: "",

            delivery_company_name:"",
            delivery_partner_message:"",
            delivery_partner_note:"",
            delivery_no:"",
            delivery_image:"",
            delivery_track:"",
            delivery_at:"",
            refund_message:"",
            refund_message_part:"",
            refund_spu_id:null,
            refund_spu_num:1,

            statementvisible:false,//更改清单状态



            total_system_order_price:"",//【订单收入】系统收款：平台给商家的订单费用（包含订单服务费+用户手续费）合计
            total_system_order_charge_money:"",//【服务费支出】系统收款：商家给平台的订单服务费合计
            total_system_order_usercharge_money:"",//【服务费支出】系统收款：商家给平台的用户手续费合计

            total_own_order_charge_money:"",//【服务费支出】商家收款：商家给平台的服务费合计
            total_own_order_usercharge_money:"",//【服务费支出】商家收款：商家给平台的用户手续费合计

            total_system_attach_money:"",//【杂费收入】平台未给商家打的钱
            total_own_attach_money:"",//【杂费支出】商家未支付给平台的钱

            statement_note:"",//管理员备注
            settle_status:0,  
            pay_status:0,





            data: "",
            meta: "",
            page: 1,
            LanguageData: "",
            language_id: "",
            visible: false,
            passwordVisible: false,
            newPassword: "",
            confirmPassword: "",
            isError: false,
            ItemId: "",
            is_open_spu_many_help: false,
            is_disable_goods_annex: false,
            is_open_recommend: false,
            is_open_moon_enable: false,
            is_open_partner_seat: false,
            is_eatmoon_show: false,
            is_open_deal_eatmoon_show:false,
            is_open_in_store:false,
            is_open_on_delivery: false,
            is_open_on_delivery_goods: false,
            is_open_on_delivery_sys: false,
            is_open_logistics: false,
            is_authorization_code: false,
            is_open_printer: false,
            is_open_order_receiving: false,
            is_discount: false,
            is_update_activity_service_rate: false,
            is_coupon: false,
            is_open_up_live: false,
            is_open_sku: false,
            is_system_delivery_free: false,
            is_open_gst: false,
            is_open_full_delivery_free: false,
            is_open_spu_many_help_system: false,
            is_self_pay: false,
            up_live_udid: "",
            up_live_udid_null: false,
            up_live_token: "",
            up_live_token_null: false,
            up_live_visible: false,
            confirmPasswordError: false,
            chooseAll: true,
            auditVisible: false,
            auditValue: "",
            packageVisible: false,
            ItemPackageData: "",
            ItemData: "",
            activity_order_fixed_fee: "",
            order_rate: "",
            activity_service_rate: ""






        };
    //}


    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView(); }
        }
      }



    async componentDidMount() { 
        //this.getDetail();
    }

    async getDetail(){
        const id = this.props.location.pathname.substring(15)

        
        let res = await GetPartnerStatementDetail(id);


     

        this.setState({ 
            data: res.data,  

             })
    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };


     priceDeal(value){

        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price = "-" + currency_symbol + value.slice(1)
        } else {
            price = currency_symbol + value
        }

         
        
        return price;
    }


      priceDeal2(value){

        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price =   currency_symbol + value.slice(1)
        }   
        
        return price;
    }





    SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        } 

        if (this.state.logo_file) {
            formData.append("delivery_image", this.state.logo_file)
        }

 
       //特殊处理  
       /*
       if(data.order_status == 'be_delivery'){ 
            formData.append("status", 10)
       }else if(data.order_status == 'wait_delivery'){
            formData.append("status", 11)
       }
       */



       formData.append("status", 11);
       //formData.append("delivery_at", moment(data.delivery_at).format("YYYY-MM-DD") )
        
         
        const id = this.props.location.pathname.substring(13)

         

    };




    render() {
        const { getFieldDecorator } = this.props.form;
        const { 
            data, 
            order_gst ,
            order_gst_excl,
            order_gst_inc,
            order_gst_total,
            weight_total, 
            partner_message,  
            deliveryVisible, 
            imageUrl ,
            delivery_company_name,
            delivery_partner_message,
            delivery_partner_note,
            delivery_no,
            delivery_image,
            delivery_track,
            delivery_at, 
            refundvisible,
            refundvisibleGoods,
            refund_goods_price,
            refund_message,
            refund_message_part,
            refund_spu_id,
            refund_spu_num,
            statementvisible,
            statement_note,
            settle_status,  
            pay_status,

             meta,  LanguageData, is_open_spu_many_help,is_open_moon_enable, is_disable_goods_annex, is_open_recommend,is_open_partner_seat, is_eatmoon_show, is_open_deal_eatmoon_show,   is_open_in_store, is_open_on_delivery,is_open_on_delivery_goods, is_open_on_delivery_sys,is_open_logistics, is_authorization_code, is_open_printer, is_open_order_receiving, is_discount, is_update_activity_service_rate, is_coupon, is_open_up_live, is_open_sku, is_system_delivery_free, is_open_gst,is_open_full_delivery_free, language_id, ItemId, is_open_spu_many_help_system, newPassword, confirmPassword, is_self_pay, up_live_token, up_live_token_null, up_live_udid, auditVisible, auditValue, chooseAll, ItemPackageData



         } = this.state;

         


        let order_status = ""
        let scan_code_type = ""
        switch (data.order_status) {
            case "un_pay":
                order_status = intl.get("home.table.useStatus.un_pay");
                break;
            case "wait_order_receiving":
                order_status = intl.get("home.table.useStatus.wait_order_receiving");
                break;
            case "un_order_receiving":
                order_status = intl.get("home.table.useStatus.un_order_receiving");
                break;
            case "for_consumption":
                order_status = intl.get("home.table.useStatus.for_consumption");
                break;

            case "wait_delivery":
                order_status = "待配送";
                break;

            case "be_delivery":
                order_status = "配送中";
                break;
 

            case "finished":
                order_status = intl.get("home.table.useStatus.finished");
                break;
            case "canceled":
                order_status = intl.get("home.table.useStatus.canceled");
                break;
            case "rejected":
                order_status = intl.get("home.table.useStatus.rejected");
                break;
            case "refunded":
                order_status = intl.get("home.table.useStatus.refunded");
                break;
        }

        switch (data.scan_code_type) {
            case 1:
                scan_code_type = intl.get("nav.setting.seat")
                break;
            case 2:
                scan_code_type = intl.get("nav.goodsManagement.sales_promotion")
                break;
            case 3:
                scan_code_type = intl.get("nav.setting.seat") + "、" + intl.get("nav.goodsManagement.sales_promotion")
                break;
        }


        let spu_discount = data?.orderSpu?.data[0]?.attach_data?.spu_discount
        let oneUserWithdrawLog = data.oneUserWithdrawLog
        let userCoupon = data.userCoupon && data.userCoupon.data[0]
        let coupon_status = ""
     

   

        let orderSource = ""
        if (data?.order_source == 1) {
            orderSource = "店铺"
        } else if (data?.order_source == 2) {
            orderSource = "平台"
        }  

 

 
        return ( 

           
            <div>
                 


                        <div style={{ display:"flex",flexDirection:"column", justifyContent: "space-between",overflow:"auto" }}  >

 
                        <Card style={{ marginBottom: "10px" }}>

                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v0 核心
                                </div>

                            </div>


                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    功能
                                </div>
                                <div>
                                    <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{   }}>开启授权码：</span>
                                        <Switch
                                             
                                            checked={is_authorization_code}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_authorization_code: e
                                                })
                                                if (is_authorization_code) {
                                                    this.setState({
                                                        is_open_on_delivery: false,
                                                        is_open_logistics: false,
                                                        is_system_delivery_free: false,
                                                        is_open_full_delivery_free: false,
                                                        is_discount: false,
                                                    })
                                                }
                                                putShop(ItemId, { is_authorization_code: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                              
                                         <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{   }}>开启打印机：</span>
                                        <Switch checked={is_open_printer}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_printer: e
                                                })
                                                putShop(ItemId, { is_open_printer: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                     <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{   }}>开启接单：</span>
                                        <Switch checked={is_open_order_receiving}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_order_receiving: e
                                                })
                                                putShop(ItemId, { is_open_order_receiving: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



                                  

                                      <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{  }}>开启GST：</span>
                                        <Switch
                                            checked={is_open_gst} 
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_gst: e
                                                })
                                                putShop(ItemId, { is_open_gst: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



                                    





                                </div>
                            </div>


                           <div style={{ display: "flex",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}>

                                
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    定制化功能
                                </div>
                                <div> 
                                     
                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>定制化功能1：</span>
                                        <Switch checked={is_open_sku}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_sku: e
                                                })
                                                putShop(ItemId, { is_open_sku: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                     <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>定制化功能2：</span>
                                        <Switch checked={is_open_recommend}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_recommend: e
                                                })
                                                putShop(ItemId, { is_open_recommend: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
                                </div>






                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    免费插件
                                </div>
                                <div> 
                                     
                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启sku：</span>
                                        <Switch checked={is_open_sku}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_sku: e
                                                })
                                                putShop(ItemId, { is_open_sku: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                     <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启精选商品：</span>
                                        <Switch checked={is_open_recommend}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_recommend: e
                                                })
                                                putShop(ItemId, { is_open_recommend: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
                                </div>
                            


                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    收费插件
                                </div>
                                <div> 
                                      
                                     <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启附加商品：</span>
                                        <Switch checked={is_disable_goods_annex}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
                                </div>
                             





                                </div>


                        </Card>















                        <Card style={{ marginBottom: "10px" }}>

                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v1 到店
                                </div>

                            </div>


                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    功能
                                </div>

                                <div>
                                    
 

                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启到店：</span>
                                        <Switch checked={is_open_in_store}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_in_store: e
                                                })
                                                putShop(ItemId, { is_open_in_store: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>店内消费：</span>
                                        <Switch checked={is_open_in_store}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_in_store: e
                                                })
                                                putShop(ItemId, { is_open_in_store: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>自提：</span>
                                        <Switch checked={is_open_in_store}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_in_store: e
                                                })
                                                putShop(ItemId, { is_open_in_store: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



 

                                </div>
                            </div>


                            <div style={{ display: "flex",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}>

                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    收费插件
                                </div>
                                <div>
                                     
 
                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启桌号：</span>
                                        <Switch checked={is_open_partner_seat}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_partner_seat: e
                                                })
                                                putShop(ItemId, { is_open_partner_seat: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    

                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启招牌菜：</span>
                                        <Switch checked={is_open_partner_seat}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_partner_seat: e
                                                })
                                                putShop(ItemId, { is_open_partner_seat: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
                                </div>


                                <div style={{ width: "100px",display:"none" }}>
                                    收费插件
                                </div>
                                <div> 
                                      
                                     
                                </div>





                            </div>





                        </Card>






                        <Card style={{ marginBottom: "10px" }}>

                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v2 配送
                                </div>

                            </div>



                            <div style={{ display: "flex",  flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px"  }}>
                                    功能
                                </div>
                                <div>
                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启配送：</span>
                                        <Switch checked={is_open_on_delivery}
                                            disabled={!is_authorization_code}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_on_delivery: e,

                                                    is_open_logistics: true,
                                                    is_system_delivery_free: true,
                                                    is_open_on_delivery_goods:true,


                                                })
                                                if (is_open_on_delivery) {
                                                    this.setState({
                                                        is_open_logistics: false,
                                                        is_system_delivery_free: false,
                                                        is_open_on_delivery_goods:false,
                                                    })
                                                }
                                                putShop(ItemId, { is_open_on_delivery: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{ width: "150px"  }}>配送模式</span>
                                         
                                    </div>



                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启送货：</span>
                                        <Switch
                                            checked={is_open_on_delivery_goods}
                                            disabled={!is_open_on_delivery}
                                            disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_on_delivery_goods: e,
                                                })
                                                if (is_open_on_delivery_goods) {
                                                    this.setState({
                                                        is_system_delivery_free: false,
                                                        is_open_logistics:false,
                                                    })
                                                }
                                                putShop(ItemId, { is_open_on_delivery_goods: e ? "1" : "0",is_open_logistics:0 }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>






                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启商家自行送货：</span>
                                        <Switch
                                            checked={is_open_logistics}
                                            disabled = {!is_open_on_delivery || !is_open_on_delivery_goods }
                                            disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_logistics: e,
                                                })
                                                if (is_open_logistics) {
                                                    this.setState({
                                                        is_system_delivery_free: false,
                                                    })
                                                }
                                                putShop(ItemId, { is_open_logistics: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启系统送货：</span>
                                        <Switch
                                            checked={is_open_on_delivery_sys}
                                            disabled 
                                            onChange={(e) => { 
                                            }} />
                                    </div>




                                    <div style={{  float:"left" ,  margin:"10px",   }}>
                                        <span style={{  }}>开启商家运费：</span>
                                        <Switch
                                            checked={is_system_delivery_free}
                                            disabled={!is_open_logistics || !is_open_on_delivery || !is_self_pay}
                                            disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_system_delivery_free: e
                                                })
                                                putShop(ItemId, { is_system_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    <div style={{  float:"left" ,  margin:"10px"  }}>
                                        <span style={{  }}>开启系统运费：</span>
                                        <Switch
                                            checked= {false}
                                            disabled = {true}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_system_delivery_free: e
                                                })
                                                putShop(ItemId, { is_system_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>





                                   





                                    

                                </div>
                            </div>





                            <div style={{ display: "flex",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px",margin:"5px 10px"}}>
                                    插件
                                </div>
                                <div>
                                     

                                   <div style={{ float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启满包邮：</span>
                                        <Switch
                                            checked={is_open_full_delivery_free}
                                            disabled={!is_system_delivery_free}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_full_delivery_free: e
                                                })
                                                putShop(ItemId, { is_open_full_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
 
                                </div>


                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    收费插件
                                </div>
                                <div>
                                     

                                   <div style={{ float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>打包费：</span>
                                        <Switch
                                            checked={is_open_full_delivery_free}
                                            disabled={!is_system_delivery_free}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_full_delivery_free: e
                                                })
                                                putShop(ItemId, { is_open_full_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
 
                                </div>






                            </div>




                        </Card>


                         


                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v3 Deal
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    功能
                                </div>
                                <div>

                                    <div style={{ float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>开启Deal：</span>
                                        <Switch
                                            checked={is_discount}
                                            disabled={!is_authorization_code}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_discount: e
                                                })
                                                putShop(ItemId, { is_discount: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



                                    <div style={{ float:"left" ,  margin:"10px"    }}>
                                        <span style={{  }}>开启平台显示：</span>
                                        <Switch checked={is_open_deal_eatmoon_show}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_deal_eatmoon_show: e
                                                })
                                                putShop(ItemId, { is_open_deal_eatmoon_show: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>




                                    {/*
                                    <div style={{  float:"left" ,   }}>
                                        <span style={{  }}>是否修改服务费：</span>
                                        <Switch
                                            checked={is_update_activity_service_rate}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_update_activity_service_rate: e
                                                })
                                                putShop(ItemId, { is_update_activity_service_rate: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
                                    */}



                                </div>
                            </div>


                            <div style={{ borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    收费插件
                                </div>
                                <div>
                                     

                                    {
                                        is_open_spu_many_help_system &&
                                        <div style={{  float:"left" ,  margin:"10px" }}>
                                            <span style={{  }}>开启砍一刀：</span>
                                            <Switch checked={is_open_spu_many_help}
                                                onChange={(e) => {
                                                    this.setState({
                                                        is_open_spu_many_help: e
                                                    })
                                                    putShop(ItemId, { is_open_spu_many_help: e ? "1" : "0" }).then(res => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                }} />
                                        </div>
                                    }


                                    
 
                                </div>
                            </div>



                        </Card>


                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v4 优惠券
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px"}}>
                                    功能
                                </div>
                                <div>

                                    <div style={{  float:"left" , margin:"10px"   }}>
                                        <span style={{  }}>开启优惠券：</span>
                                        <Switch checked={is_coupon}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_coupon: e
                                                })
                                                putShop(ItemId, { is_coupon: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    

                                     <div style={{ float:"left" , margin:"10px" }}>
                                        <span style={{  }}>开启平台显示：</span>
                                        <Switch checked={is_eatmoon_show}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_eatmoon_show: e
                                                })
                                                putShop(ItemId, { is_eatmoon_show: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>




                                    {/*
                                    <div style={{  float:"left" , margin:"10px"  }}>
                                        <span style={{  }}>开启小月亮：</span>
                                        <Switch checked={is_open_moon_enable}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_moon_enable: e
                                                })
                                                putShop(ItemId, { is_open_moon_enable: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
                                    */}



     






                                </div>
                            </div>


                            <div style={{ display: "none",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    插件
                                </div>
                                <div>
                                     

                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>Demo：</span>
                                        <Switch checked={is_disable_goods_annex}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
 
                                </div>
                            </div>



                        </Card>




                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v5 会员
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    功能
                                </div>
                                <div>

                                    <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{  }}>成长值：</span>
                                        <Switch checked={is_coupon}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_coupon: e
                                                })
                                                putShop(ItemId, { is_coupon: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    

                                     <div style={{ float:"left" , margin:"10px" }}>
                                        <span style={{  }}>会员等级：</span>
                                        <Switch checked={is_eatmoon_show}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_eatmoon_show: e
                                                })
                                                putShop(ItemId, { is_eatmoon_show: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>





                                    <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{  }}>积分：</span>
                                        <Switch checked={is_open_moon_enable}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_moon_enable: e
                                                })
                                                putShop(ItemId, { is_open_moon_enable: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



     






                                </div>
                            </div>


                            <div style={{  borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    收费插件
                                </div>
                                <div>
                                     

                                    <div style={{  float:"left" ,  margin:"10px" }}>
                                        <span style={{  }}>会员卡：</span>
                                        <Switch checked={is_disable_goods_annex}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
 
                                </div>
                            </div>



                        </Card>















                        </div>


                        <div style={{ display:"flex",flexDirection:"row", justifyContent: "space-between"  }}  >

                        <Card style={{ marginBottom: "10px", marginTop: "10px" , width:"100%"}}>
                            <div style={{ display: "flex",alignItems: "flex-start",flexDirection: "column" }}>
                                <div style={{ width: "100px",margin:"5px 10px" }}>
                                    第三方视频平台
                                </div>
                                <div>

                                    <div style={{  float:"left" , margin:"10px" }}>
                                        <span style={{  }}>开启第三方视频平台：</span>
                                        <Switch checked={is_open_up_live}
                                            onChange={(e) => {
                                                if (!e) {
                                                    this.setState({
                                                        is_open_up_live: e
                                                    })
                                                    putShop(ItemId, { is_open_up_live: "0" }).then(res => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                } else {
                                                    this.setState({
                                                        up_live_visible: true
                                                    })
                                                }
                                            }} />
                                    </div>
                                    {/* {
                                        up_live_udid &&
                                        <div style={{  float:"left" ,  marginTop: "15px" }}>
                                            <span style={{  }}>udid：</span>
                                            <span>{up_live_udid}</span>
                                        </div>
                                    }

                                    {
                                        up_live_token &&
                                        <div style={{  float:"left" ,  marginTop: "10px" }}>
                                            <span style={{  }} >token：</span>
                                            <span>{up_live_token}</span>
                                        </div>
                                    } */}

                                    <Modal
                                        title="第三方视频平台"
                                        visible={this.state.up_live_visible}
                                        centered
                                        width={435}
                                        onOk={() => {
                                            this.setState({
                                                up_live_token_null: !up_live_token ? true : false,
                                                up_live_udid_null: !up_live_udid ? true : false
                                            })
                                            if (up_live_token && up_live_udid) {
                                                putShop(ItemId, { is_open_up_live: "1", up_live_token, up_live_udid }).then(res => {
                                                    this.setState({
                                                        is_open_up_live: true,
                                                        up_live_visible: false
                                                    }, () => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                })
                                            }

                                        }}
                                        onCancel={() => this.setState({ up_live_visible: false })}
                                    >
                                        {/* <div style={{ height: "60px" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span style={{ width: "140px", textAlign: "right", marginRight: 5 }}>第三方视频平台uuid:</span>
                                                <Input
                                                    value={up_live_udid}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value
                                                        this.setState({
                                                            up_live_udid: value,
                                                            up_live_udid_null: false
                                                        })
                                                    }} />
                                            </div>
                                            {
                                                this.state.up_live_udid_null &&
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ width: "140px", textAlign: "right", marginRight: 5 }} />
                                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>第三方视频平台uuid不能为空</span>
                                                </div>
                                            }
                                        </div> */}
                                        <div style={{ height: "60px" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ width: "140px", textAlign: "right", marginBottom: "5px" }}>第三方视频平台token:</span>
                                                <Input
                                                    value={up_live_token}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value
                                                        this.setState({
                                                            up_live_token: value,
                                                            up_live_token_null: false
                                                        })
                                                    }} />
                                            </div>
                                            {
                                                this.state.up_live_token_null &&
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ width: "140px", textAlign: "right", marginRight: 5 }} />
                                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>第三方视频平台token不能为空</span>
                                                </div>
                                            }
                                        </div>
                                    </Modal>


                                </div>
                            </div>
                        </Card>

                        </div>

           
 
                   
                

               

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" , alignItems:"center"}}>

                    

                    <div> 
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => { 
                            this.setState({
                                statementvisible: true, 
                                //refund_message: ""
                            })


                        }}
                    >
                        保存
                    </Button>


 

                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            返回
                        </Button>
                    </Popconfirm>


                    </div>


                </div>
            </div>
           

        );
    }
}

//export default withRouter(OrderDetail)

export default withRouter(Form.create()(OrderDetail))