import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, Popconfirm, Form, Button, Select, Modal } from 'antd';
import { getAddressSubList, AddAddressSub, deleteAddressSub, editAddressSub, getRegionList } from "../../action/shopAction";

class LangList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            row: '',
            visible: false,
            stateData: ["NSW", "QLD", "SA", "TAS", "VIC", "WA"]
        };
    }

    async componentDidMount() {
        getRegionList().then(res => {
            this.setState({
                regionList: res.data.data
            })
        })
        this.getData()
    }

    getData = () => {
        getAddressSubList().then(res => {
            this.setState({ data: res.data.data })
        })

    }


    edit(record) {
        this.setState({ visible: true, row: record });
    }

    async handleDelete(data) {
        await deleteAddressSub(data.id);
        this.getData();
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { row } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (row && row.id) {
                    //修改-注意参数
                    editAddressSub(row.id, values).then(res => {
                        this.getData()
                        this.setState({
                            visible: false,
                            row: ''
                        });
                    })
                } else {
                    //新增
                    AddAddressSub(values).then(res => {
                        this.getData()
                        this.setState({
                            visible: false,
                            row: ''
                        });
                    })
                }
            }
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            row: ''
        });
    };


    render() {
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                align: "center",
            },

            {
                title: "国家",
                dataIndex: 'region[name]',
                width: '10%',
                editable: true,
                align: "center",
            },

            {
                title: 'suburb',
                dataIndex: 'suburb',
                align: "center",
            },
            {
                title: '州',
                dataIndex: 'state',
                align: "center",
            },
            {
                title: '邮编',
                dataIndex: 'postcode',
                align: "center",
            },

            /*
            {
                title: 'latitude',
                dataIndex: ['coordinates','latitude'],
                align: "center",
            },
            {
                title: 'longitude',
                dataIndex: ['coordinates','longitude'],
                align: "center",
            },
            */
            {
                title: '操作',
                dataIndex: 'operation',
                align: "center",
                render: (text, record) => {
                    return <>
                        <Button onClick={() => this.edit(record)} style={{ marginRight: "10px" }}>
                            编辑
                        </Button>
                        <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                            <Button type="danger" ghost>
                                删除
                            </Button>
                        </Popconfirm>
                    </>
                },
            },

        ];

        const { row, stateData, regionList } = this.state;
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        return (
            <div>
                <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "flex-end", alignItems: "center", backgroundColor: '#fff' }}>
                    <Button type="primary" onClick={() => this.showModal()} style={{ marginRight: "10px" }}>
                        <PlusOutlined /> 新建
                    </Button>
                </div>
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={columns}
                    className="waperBox"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
                <Modal
                    destroyOnClose
                    title={row.id ? '编辑Sub' : '新建Sub'}
                    visible={this.state.visible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="waperBox FormView" {...formItemLayout}>
                        <Form.Item
                            label={<span>选择国家</span>}
                        >
                            {getFieldDecorator('region_id', {
                                rules: [{ required: true, message: '请选择国家!' }],
                                initialValue: 1
                            })(
                                <Select disabled>
                                    {regionList && regionList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="州"
                        >
                            {getFieldDecorator('state', {
                                rules: [{ required: true, message: "请输入state", whitespace: true }],
                                initialValue: row.state
                            })(<Select>
                                {stateData.map(el => <Select.Option key={el} value={el}>{el}</Select.Option>)}
                            </Select>)}
                        </Form.Item>

                        <Form.Item
                            label="suburb"
                        >
                            {getFieldDecorator('suburb', {
                                rules: [{ required: true, message: '请输入sub!' }],
                                initialValue: row && row.suburb
                            })(
                                <Input />
                            )}
                        </Form.Item>

                        <Form.Item
                            label="邮编"
                        >
                            {getFieldDecorator('postcode', {
                                rules: [{ required: true, message: '请输入邮编!' }],
                                initialValue: row && row.postcode
                            })(<Input />)}
                        </Form.Item>

                        {/*
                        <Form.Item
                            label="latitude"
                        >
                            {getFieldDecorator('latitude', {
                                initialValue: row&&row.coordinates.latitude
                            })(<Input/>)}
                        </Form.Item>
                        <Form.Item
                            label="longitude"
                        >
                            {getFieldDecorator('longitude', {
                                initialValue: row&&row.coordinates.longitude
                            })(<Input/>)}
                        </Form.Item>
                        */}
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(LangList))

