import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Input, Modal } from 'antd';
import { GetConfigSet, UpdateConfigSet, sendUpdatePayParamCodeMail } from "../../action/authAction";

class SysTemSet extends React.Component {

    state = {
        data: "",
        visible: false,
        code: ""
    };

    async componentDidMount() {
        let res = await GetConfigSet()
        let allData = res.data.data
        let data = []
        data.PAYPAL_CLIENTID = allData && allData.find(el => el.key === "PAYPAL_CLIENTID").value
        data.PAYPAL_SECRET = allData && allData.find(el => el.key === "PAYPAL_SECRET").value
        this.setState({ data: data })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let data = []
                for (let key in values) {
                    data.push({
                        "key": key,
                        "value": values[key],
                        "name": key,
                        "desc": key
                    })
                }
                data.push({
                    "key": "update_pay_param_code",
                    "value": this.state.code
                })
                UpdateConfigSet(data)
            }
        });
    };

    handleGetPayParamCodeMail = () => {
        sendUpdatePayParamCodeMail().then(res => {
            this.setState({
                visible: true,
            });
        })
    }


    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { data } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>
                <Form.Item
                    label="paypal clieneid"
                >
                    {getFieldDecorator('PAYPAL_CLIENTID', {
                        rules: [{ required: false }],
                        initialValue: data.PAYPAL_CLIENTID
                    })(<Input disabled />)}
                </Form.Item>

                <Form.Item
                    label="paypal secret"
                >
                    {getFieldDecorator('PAYPAL_SECRET', {
                        rules: [{ required: false }],
                        initialValue: data.PAYPAL_SECRET
                    })(<Input disabled />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary"
                        onClick={() => this.handleGetPayParamCodeMail()}
                    >
                        提交
                    </Button>
                </Form.Item>
                <Modal
                    title="填入验证码"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ width: "80px", textAlign: "right" }}>验证码：</span>
                        <Input onChange={(e) => {
                            e.persist()
                            this.setState({
                                code: e.target.value
                            })
                        }} />
                    </div>

                </Modal>
            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
