import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd';
import { GetSysTemSet, UpdateSysTemSet, GetgodPassword } from "../../action/authAction";

class SysTemSet extends React.Component {

    state = {
        data: "",
        godpassword: ""
    };

    async componentDidMount() {
        let res = await GetSysTemSet()
        let password = await GetgodPassword()
        this.setState({ data: res.data, godpassword: password.data.partner })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                UpdateSysTemSet(values).then(res => {

                })
            }
        });
    };


    render() {
        const { godpassword } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };



        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                {/*
                <Form.Item
                    label="最小提现金额"
                >
                    {getFieldDecorator('least_extract_money', {
                        rules: [{ required: false }],
                        initialValue: data.least_extract_money
                    })(<Input />)}
                </Form.Item>

                */}

                {/* <Form.Item
                    label="银行手续费百分比"
                >
                    {getFieldDecorator('bank_charge_rate', {
                        rules: [{ required: false }],
                        initialValue: data.bank_charge_rate
                    })(<Input />)}
                </Form.Item> */}

                {/*
                <Form.Item
                    label="提现发送邮件通知"
                >
                    {getFieldDecorator('withdraw_send_mail', {
                        rules: [{ required: false }],
                        initialValue: data.withdraw_send_mail
                    })(<Input />)}
                    <span style={{}}>多个邮件使用逗号“ , ”隔开</span>
                </Form.Item>
                */}

                <Form.Item
                 label="商家用户名"
                >
                    <span>{"admin->商家ID"}</span>
                </Form.Item>
                <Form.Item
                    label="上帝密码"
                >
                    <span>{godpassword}</span>
                </Form.Item>
                {/*
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
                */}

            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
