import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Modal, Upload } from 'antd';
import { getHolidayList, deleteHoliday, ImportHoliday } from "../../action/shopAction";

class HolidayList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: "",
        };
        this.columns = [
            {
                title: "id",
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: "名称",
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: "详情",
                dataIndex: 'detail',
                width: '30%',
                align: "center",
            },
            {
                title: "州",
                dataIndex: 'state',
                width: '10%',
                align: "center",
            },
            {
                title: "开始时间",
                width: '20%',
                align: "center",
                render: (record) => {
                    return record.start_at && record.start_at.date.substring(0, 10)
                }
            },
            {
                title: "操作",
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return <div className='deleteView'>
                        <Button style={{ marginRight: "5px" }} onClick={() => {
                            this.props.history.push("/EditHoliday/" + record.id)
                        }}>
                            编辑
                        </Button>
                        <Popconfirm title="确认删除？" onConfirm={() => this.handleDelete(record)}>
                            <Button>
                                删除
                            </Button>
                        </Popconfirm>
                    </div >
                },
            },

        ];
    }

    async componentDidMount() {
        this.getData("&page=1")
    }

    getData = (url) => {
        getHolidayList(url).then(res => {
            this.setState({ data: res.data.data, meta: res.data.meta.pagination })
        })

    }

    async handleDelete(data) {
        await deleteHoliday(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    handleChange = (file) => {
        let formData = new FormData()
        formData.append("file", file.file)
        ImportHoliday(formData).then(res => {
            this.getData()
        })


    }

    render() {
        let { meta } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <div style={{ display: "flex" }}>
                        <Upload
                            showUploadList={false}
                            beforeUpload={() => {
                                return false;
                            }}
                            onChange={this.handleChange}
                        >
                            <Button
                                type="primary"
                                style={{ marginRight: "20px" }}
                            >
                                <PlusOutlined />导入
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={() => {
                            this.props.history.push("/NewHoliday")
                        }}>
                            <PlusOutlined /> 新建
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.setState({
                            page: e.current
                        }, () => this.getData("&page=" + e.current))

                    }}
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(HolidayList))

