import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, InputNumber, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon } from 'antd';
import moment from 'moment';
import { getSpuDiscountDetail, putSpuDiscount, getNoSkuGoodsList, getGoodsDetail } from "../../action/goodAction";

const { Option } = Select;
const { RangePicker } = DatePicker;


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt2M;
}

class EditPromotionalGoods extends React.Component {
    state = {
        name: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        price_type: "rrp",
        date: "",
        data: "",
        imageUrl: "",
        logo_file: "",
        loading: false,
        is_show: true,
        is_lock: true
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(22)
        getSpuDiscountDetail(id).then(res => {
            this.HandleGetSpuProduct(res.data.spu_id)
            this.setState({
                data: res.data,
                imageUrl: res.data.image,
                discount_type: res.data.discount_type,
                price_type: res.data.price_type,
                spu_id: res.data.spu_id,
                // is_show: res.data.state === 1 ? true : false,
                is_lock: res.data.is_lock === 1 ? true : false,
                date: [moment(res.data.start_at, "YYYY-MM-DD HH:mm:ss"), moment(res.data.end_at, "YYYY-MM-DD HH:mm:ss")]
            })
        })
    }

    HandleGetSpuProduct = (id) => {
        getGoodsDetail(id).then(res => {
            this.setState({
                name: res.data.name
            })
        })
    }

    handleSubmit = (e) => {
        const id = this.props.location.pathname.substring(22)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let formData = new FormData()
            let { spu_id, discount_type, date, price_type, logo_file, is_show, is_lock } = this.state
            values.spu_id = spu_id
            // values.state = is_show ? "1" : "0"
            values.is_lock = is_lock ? "1" : "0"
            values.discount_type = discount_type
            values.price_type = price_type
            values.start_at = date ? moment(date[0]).format("YYYY-MM-DD HH:mm:ss") : ""
            values.end_at = date ? moment(date[1]).format("YYYY-MM-DD HH:mm:ss") : ""
            values.discount_on_percentage = parseInt(values.discount_on_percentage) / 100
            if (!err) {
                if (values.spu_id) {
                    if (Number(values.quota) >= 1) {
                        for (let i in values) {
                            if (values[i]) {
                                formData.append(i, values[i])
                            }
                        }
                        logo_file && formData.append("image_file", logo_file)
                        putSpuDiscount(formData, id)
                    } else {
                        message.error("限额最小为1")
                    }

                } else {
                    message.error("请选择一个商品")
                }
            }


        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    onSelectSearch = (val) => {
        if (val.length > 1) {
            getNoSkuGoodsList(1, val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    logo_file: img
                }),
            );
        }
    };

    checkPsd(rule, value, callback) {
        let price = this.state.data.spu.price;
        if (price && parseInt(price) < parseInt(value)) {
            callback(new Error("折扣价格不能大于商品价格"));
        } else {
            callback();
        }
    }

    render() {
        const { SearchData, discount_type, data, date, price_type, imageUrl, loading, is_lock, is_show } = this.state;
        let goods = data.spu
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                {
                    this.state.name &&
                    <Form.Item
                        label="选择商品"
                    >
                        <Select
                            showSearch
                            defaultValue={this.state.name}
                            placeholder={"选择商品"}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.onSelectSearch}
                            onChange={(value) => {
                                this.setState({
                                    spu_id: value
                                })
                            }}
                            notFoundContent={null}
                        >
                            {
                                SearchData.length > 0 && SearchData.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }



                <Form.Item
                    label="促销类型"
                >
                    <Radio.Group
                        onChange={(e) => {
                            this.setState({
                                discount_type: e.target.value
                            })
                        }}
                        value={discount_type}>
                        <Radio value={"fixed"}>fixed</Radio>
                        <Radio value={"percentage"}>percentage</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    discount_type === "fixed" ?
                        <Form.Item
                            label="折扣"
                        >
                            {getFieldDecorator('discount_on_fixed', {
                                rules: [{ required: true, message: '请输入折扣!' }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                                initialValue: data.discount_on_fixed
                            })(<Input prefix="$" style={{ width: "200px" }} />)}
                        </Form.Item> :
                        <Form.Item
                            label="打折(%)"
                        >
                            {getFieldDecorator('discount_on_percentage', {
                                rules: [{ required: true, message: '请输入折扣!' }],
                                initialValue: data.discount_on_percentage * 100
                            })(<InputNumber formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')} min={1} style={{ width: "200px" }} />)}
                        </Form.Item>
                }

                <Form.Item
                    label="价格类型"
                >
                    <Radio.Group
                        onChange={(e) => {
                            this.setState({
                                price_type: e.target.value
                            })
                        }}
                        value={price_type}
                        style={{ marginTop: "10px" }}
                    >
                        <Radio style={{ display: "block", marginBottom: "10px" }} value={"rrp"}>
                            rrp:${goods && goods.rrp}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {
                                discount_type === "fixed" ? "-$" + ` ${goods && parseFloat(goods.rrp) - (goods.price - parseFloat(this.props.form.getFieldValue('discount_on_fixed')))}` :
                                    `${goods && (goods.price * this.props.form.getFieldValue('discount_on_percentage') / goods.rrp).toFixed(2)}` + "%"
                            }
                            （该价格仅作展示）
                        </Radio>
                        <Radio value={"price"} >
                            price:${goods && goods.price}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {
                                discount_type === "fixed" ? "-$" + ` ${this.props.form.getFieldValue('discount_on_fixed')}` :
                                    `${this.props.form.getFieldValue('discount_on_percentage')}` + "%"
                            }
                        </Radio>
                    </Radio.Group>
                </Form.Item >
                <Form.Item
                    label="折扣价格"
                >
                    <span>
                        ${
                            (goods && goods.rrp) || (goods && goods.price) ?
                                discount_type === "fixed" ?
                                    goods && goods.price - this.props.form.getFieldValue('discount_on_fixed') :
                                    goods && goods.price * this.props.form.getFieldValue('discount_on_percentage') / 100 : 0
                        }
                    </span>
                </Form.Item>
                <Form.Item
                    label="商品图片"
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                    >
                        {!!imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="销售时间"
                >
                    {
                        data.start_at ?
                            <div>
                                <RangePicker
                                    defaultValue={date}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    onOk={(val) => {
                                        this.setState({
                                            date: val
                                        })
                                    }}
                                />
                            </div> :
                            <RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onOk={(val) => {
                                    this.setState({
                                        date: val
                                    })
                                }}
                            />
                    }

                </Form.Item>
                <Form.Item
                    label="库存"
                >
                    {getFieldDecorator('stock', {
                        rules: [{ required: true, message: '请输入库存!' }],
                        initialValue: data.stock
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="限额"
                >
                    {getFieldDecorator('quota', {
                        rules: [{ required: true, message: '请输入限额!' }],
                        initialValue: data.quota
                    })(<Input />)}
                </Form.Item>
                {/* <Form.Item
                    label="是否显示"
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item> */}
                <Form.Item
                    label="eatmoon锁"
                >
                    <Switch checked={is_lock} onChange={() => {
                        this.setState({
                            is_lock: !is_lock
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="Qr Code"
                >
                    <img src={data.qrcode_url} style={{ width: "100px" }} />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form >
        );
    }
}

export default withRouter(Form.create()(EditPromotionalGoods))
