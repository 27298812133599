import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button } from 'antd';
import { getPhoneLogList } from "../../action/authAction";



class PhoneLogList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: "",
            meta:""
        };
        this.columns = [

            {
                title: '店铺名称',
                dataIndex: 'name',
                width: '20%',
                align:"center"
            }, {
                title: '手机号码',
                dataIndex: 'phone',
                width: '20%',
                align:"center"
            },{
                title: '拨打次数',
                dataIndex: 'call_phone_count',
                width: '10%',
                align:"center"
            },
            {
                title: '商家地址',
                dataIndex: 'address',
                width: '35%',
                align:"center"
            },
            {
                title: '操作',
                width: '15%',
                align:"center",
                render: (record) => {
                    return (
                        <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                            <Button type="danger" ghost>
                                删除
                                </Button>
                        </Popconfirm>
                    );
                },
            },

        ];
    }

    async componentDidMount() {
        this.GetRecommendData(1)
    }

    async GetRecommendData(num) {
        let res = await getPhoneLogList(num)
        this.setState({ 
            data: res.data.data,
            meta:res.data.meta.pagination
         })
    }

    async handleDelete(data) {
        // await deleteRecommendShop(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    async onChange(record) {
        let { data } = this.state
        let NewData = data
        let is_show = 0
        if (record.is_show === 1) {
            is_show = 0
        } else {
            is_show = 1
        }
        for (let i in NewData) {
            if (NewData[i].id === record.id) {
                NewData[i].is_show = is_show
                this.setState({
                    data: NewData
                })
                break
            }
        }
        // await putRecommendShop(record.id, { is_show })
    }

    render() {
        const { data,meta } = this.state
        if (data) {
            return (
                <Table
                    rowKey='id'
                    // bordered
                    className="waperBox"
                    dataSource={data}
                    columns={this.columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.GetRecommendData(e.current)
                    }}
                />

            );
        } else {
            return (<div />)
        }


    }
}


export default withRouter(Form.create()(PhoneLogList))

