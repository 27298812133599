import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Form, Button } from 'antd';
import { GetUserRefund } from "../../action/authAction";

class UserWithdrawLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], url: "", meta: "" };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '店铺名称',
                dataIndex: ["order", "partner", "title"],
                width: '10%',
                align: "center",
            },
            {
                title: '提现人名称',
                dataIndex: 'user_name',
                width: '10%',
                align: "center",
            },
            {
                title: '退款金额',
                dataIndex: 'money',
                width: '10%',
                align: "center",
                render: (record) => {
                    return "$" + record
                }
            },
            {
                title: '银行名称',
                dataIndex: 'account_name',
                width: '10%',
                align: "center",
            },
            {
                title: '银行账号',
                dataIndex: 'account',
                width: '10%',
                align: "center",
            },

            {
                title: 'bsb',
                dataIndex: 'bsb',
                width: '10%',
                align: "center",
            },


            {
                title: '处理结果',
                dataIndex: 'sort',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            {
                                record.is_finish === 1 ?
                                    <span style={{ color: "green" }}>已处理</span> :
                                    <div>
                                        {record.is_finish === 3 ? <span style={{ color: "#ff0000" }}>拒绝</span> : <span style={{ color: "blue" }}>审核中</span>}
                                    </div>
                            }
                        </div>

                    );
                },
            },
            {
                title: '申请时间',
                dataIndex: "created_at",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {

                    return (
                        <div>
                            <Button onClick={() => this.props.history.push("/userRefundDetail/" + record.id)}>
                                查看
                            </Button>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        this.HandleGetData(1)
    }

    HandleGetData = async (page) => {
        let res = await GetUserRefund(this.state.url, page);
        this.setState({ data: res.data.data, meta: res.data.meta.pagination, })
    }




    render() {
        let { meta } = this.state
        return (
            <div>
                <div style={{ marginBottom: "5px" }}>
                    <Button
                        style={{ marginRight: "5px", }}
                        onClick={() => {
                            this.setState({
                                url: ""
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        全部
                    </Button>
                    <Button
                        style={{ marginRight: "5px", }}
                        onClick={() => {
                            this.setState({
                                url: "&is_finish=0"
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        审核中
                    </Button>
                    <Button
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                            this.setState({
                                url: "&is_finish=1"
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        已处理
                    </Button>
                    <Button
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                            this.setState({
                                url: "&is_finish=3"
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        已拒绝
                    </Button>
                </div>

                <Table
                    rowKey='id'
                    // components={components}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    className="waperBox"
                    rowClassName="editable-row"
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData()
                    }}
                />

            </div>
        );
    }
}

export default withRouter(Form.create()(UserWithdrawLog))

