import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Spin, Switch } from 'antd';
import { getSalesAreaSubDetail, getSalesAreaList, editSalesAreaSub } from "../../action/shopAction";
let { Option } = Select

class EditSalesAreaSub extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            SalesAreaList: [],
            Subs: [],
            is_use: false
        }
    }

    componentDidMount() {
        const id = this.props.location.pathname.substring(18)
        getSalesAreaList().then(res => {

            this.setState({
                SalesAreaList: res.data.data
            })
        })
        getSalesAreaSubDetail(id).then(res => {
            let data = res.data
            this.setState({
                data: data,
                Subs: data.salesArea.subs.split(","),
                is_use: data.is_use === 1
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_use = this.state.is_use ? "1" : "0"
                this.SubmitData(values)
            }

        });
    };

    async SubmitData(data) {
        const id = this.props.location.pathname.substring(18)
        await editSalesAreaSub(id, data).then(res => {
            this.props.history.goBack()
        })

    }

    render() {
        let { data, SalesAreaList, is_use, Subs } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                {/* <Form.Item
                    label="名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: "请输入名称", whitespace: true }],
                        initialValue: data.name
                    })(<Input placeholder='请输入名称' />)}
                </Form.Item> */}

                <Form.Item
                    label="销售大区"
                >
                    {getFieldDecorator('sales_area_id', {
                        rules: [{ required: true, message: '请选择销售大区!' }],
                        initialValue: data.sales_area_id
                    })(
                        <Select placeholder="请选择销售大区">
                            {SalesAreaList && SalesAreaList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="Sub"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请选择sub!' }],
                        initialValue: data.name
                    })(
                        <Select placeholder="请选择sub">
                            {Subs && Subs.map(el => <Select.Option key={el} value={el}>{el}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="是否启用">
                    <Switch checked={is_use} onChange={() => {
                        this.setState({
                            is_use: !is_use
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: data.sort
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditSalesAreaSub))
