import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Upload, Icon, Switch, message, Select, Radio, Tooltip } from 'antd';
import { getAdvertisementDetail, editAdvertisement } from "../../action/shopAction";
import { getLanguageList } from "../../action/authAction"


function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

class EditAdvertisement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            imageUrl: "",
            goodImage: "",
            loading: false,
            show: true,
            disable_language: true,
            type: 0,
            LanguageData: ""
        }
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData
        }, () => {
            this.handleGetDetail(LanguageData[1].id)
        })
    }

    handleGetDetail = (language_id) => {
        const id = this.props.location.pathname.substring(19)
        if (id) {
            getAdvertisementDetail(id, language_id).then(res => {
                this.setState({
                    data: res.data,
                    show: res.data.show === 1,
                    disable_language: res.data.disable_language === 0,
                    type: res.data.type,
                    imageUrl: res.data.image,
                })
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.show = this.state.show ? "1" : "0"
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.type = this.state.type.toString()
            if (!err) {
                this.SubmitData(values)
            }

        });
    };

    async SubmitData(data) {
        let formData = new FormData();
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (this.state.goodImage) {
            formData.append("image", this.state.goodImage)
        }
        const id = this.props.location.pathname.substring(19)
        await editAdvertisement(id, formData).then(res => {
            this.props.history.push("/Advertisement")
        })

    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            getBase64(info.file.originFileObj, (imageUrl, img) => {
                this.setState({
                    imageUrl,
                    loading: false,
                    goodImage: img
                })
            }

            );
        }
    };




    render() {
        let { data, imageUrl, type, loading, show, disable_language, LanguageData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        if (data) {

            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                    <Form.Item
                        label="选择录入的语言"
                    >
                        {getFieldDecorator('request_language_id', {
                            rules: [{ required: true, message: '请选择语言!' }],
                            initialValue: LanguageData && LanguageData[1].id
                        })(
                            <Select
                                onChange={(e) => {
                                    this.handleGetDetail(e)
                                }}
                            >
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="标题"
                    >
                        {getFieldDecorator('title', {
                            rules: [{ required: false }],
                            initialValue: data.title
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="链接"
                    >
                        {getFieldDecorator('link', {
                            rules: [{ required: false }],
                            initialValue: data.link
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item
                        label="排序"
                    >
                        {getFieldDecorator('sort', {
                            initialValue: data.sort
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="是否显示">
                        <Switch checked={show} onChange={() => {
                            this.setState({
                                show: !show
                            })
                        }} />
                    </Form.Item>
                    <Form.Item label="激活语言">
                        <Switch checked={disable_language} onChange={() => {
                            this.setState({
                                disable_language: !disable_language
                            })
                        }} />
                    </Form.Item>
                    <Form.Item label="广告类型">
                        <Radio.Group
                            onChange={(e) => {
                                this.setState({
                                    type: e.target.value
                                })
                            }}
                            value={type}
                        >
                            <Radio value={0}>EM广告</Radio>
                            <Radio value={1}>商家广告</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>图片
                                <Tooltip title={type === 0 ? "请上传尺寸为：16:9的图片" : "请上传尺寸为：5:1的图片"}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={this.handleUpload}
                        >
                            {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>


                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            );

        } else {
            return null
        }
    }
}

export default withRouter(Form.create()(EditAdvertisement))
