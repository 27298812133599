import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Upload, Icon, Switch } from 'antd';
import { getStyleDetail, editStyle } from "../../action/shopAction";



function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

class EditStyle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imageUrl: "",
            imageUrl2: "",
            goodImage: "",
            goodImage2: "",
            loading: false,
            loading2: false,
            data: "",
            status: true,
            is_default: true,
        }
    }

    componentDidMount() {
        const id = this.props.location.pathname.substring(11)
        getStyleDetail(id).then(res => {
            this.setState({
                data: res.data,
                imageUrl: res.data.image,
                imageUrl2: res.data.static_1nd,
                status: res.data.status === 1,
                is_default: res.data.is_default === 1,
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.status = this.state.status ? "1" : "0"
            values.is_default = this.state.is_default ? "1" : "0"
            if (!err) {
                if (values.name) {
                    this.SubmitData(values)
                }
            }

        });
    };

    async SubmitData(data) {
        const id = this.props.location.pathname.substring(11)
        let formData = new FormData();
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (this.state.goodImage) {
            formData.append("image_file", this.state.goodImage)
        }
        if (this.state.goodImage2) {
            formData.append("static_1nd_file", this.state.goodImage2)
        }
        await editStyle(id, formData).then(res => {
            this.props.history.push("/styleList")
        })

    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            getBase64(info.file.originFileObj, (imageUrl, img) => {
                this.setState({
                    imageUrl,
                    loading: false,
                    goodImage: img
                })
            }

            );
        }
    };

    handleUpload2 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file) {
            getBase64(info.file.originFileObj, (imageUrl2, img) => {
                this.setState({
                    imageUrl2,
                    loading2: false,
                    goodImage2: img
                })
            }

            );
        }
    };


    render() {
        let { imageUrl, imageUrl2, loading, loading2, data, status, is_default } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const uploadButton2 = (
            <div>
                <Icon type={this.state.loading2 ? 'loading2' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );




        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label="名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: "请输入名称", whitespace: true }],
                        initialValue: data.name
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: data.sort
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="状态">
                    <Switch checked={status} onChange={() => {
                        this.setState({
                            status: !status
                        })
                    }} />
                </Form.Item>
                <Form.Item label="是否默认">
                    <Switch checked={is_default} onChange={() => {
                        this.setState({
                            is_default: !is_default
                        })
                    }} />
                </Form.Item>


                <Form.Item
                    label="颜色"
                >
                    {getFieldDecorator('color', {
                        rules: [{ required: true, message: '请输入颜色!' }],
                        initialValue: data.color
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label="副颜色1"
                >
                    {getFieldDecorator('color_1nd', {
                        initialValue: data.color_1nd
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="副颜色2"
                >
                    {getFieldDecorator('color_2nd', {
                        initialValue: data.color_2nd
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>图片</span>}
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={this.handleUpload}
                    >
                        {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label={<span>附加图片</span>}
                >
                    <Upload
                        name="avatar2"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={this.handleUpload2}
                    >
                        {imageUrl2 && !loading2 ? <img src={imageUrl2} alt="avatar" style={{ width: '100%' }} /> : uploadButton2}
                    </Upload>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditStyle))
