import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Select,DatePicker } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { AddDocument ,getDocumentcategoryList} from "../../action/shopAction";
import { getLanguageList } from "../../action/authAction"
import moment from 'moment';

class NewDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true,
            disable_language: true,
            LanguageData: "",
            editorState: BraftEditor.createEditorState(null),
            documentCategoryData:"",
            published_at:"",
        }
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        let documentCategoryData = await getDocumentcategoryList()

        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData,
            documentCategoryData:documentCategoryData.data.data,
        })


        

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.show = this.state.show ? "1" : "0"
            values.disable_language = this.state.disable_language ? 0 : 1
            values.content = this.state.editorState.toHTML() 
            values.published_at = this.state.published_at;



            if (!err) {
                AddDocument(values).then(res => {
                    this.props.history.push("/documentList")
                })
            }

        });
    };


    handleChange = (editorState) => {
        this.setState({ editorState })
    }



    handleGetDetail = async (language_id) => { 
        
        let documentCategoryData = await getDocumentcategoryList(language_id)
        this.setState({ 
            documentCategoryData:documentCategoryData.data.data, 
        }) 
    }






    render() {
        let { show, disable_language, LanguageData,documentCategoryData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label="选择录入的语言"
                >
                    {getFieldDecorator('request_language_id', {
                        rules: [{ required: true, message: '请选择语言!' }],
                        initialValue: LanguageData && LanguageData[1].id
                    })(
                        <Select

                         onChange={(e) => {
                            this.handleGetDetail(e)
                        }}


                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>




                <Form.Item
                        label={<span>分类</span>}
                    >
                        {getFieldDecorator('documentcategory_id', {
                            rules: [{ required: false, message: '请选择分类' }],
                        })(
                            <Select
                                onChange={(e) => {
                                    

                                }}
                            >
                                {documentCategoryData && documentCategoryData.map(el => <Select.Option key={el.id} value={el.id}>{el.title}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>





                <Form.Item
                    label="标题"
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: false }],
                    })(<Input />)}
                </Form.Item>

                
                {/*
                <Form.Item
                    label="发布日期"
                >

                    {getFieldDecorator('published_at', {
                        rules: [{ required: false }],
                    })(<DatePicker 
                      showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD"
 
                        onOk={(val) => {
                            this.setState({ 
                                date: moment(val).format("YYYY-MM-DD")
                            })
                        }} 
                     />
                    ) }

                    
                </Form.Item>*/}


                <Form.Item
                    label="发布日期"
                >
                    <DatePicker
                        format='YYYY-MM-DD'
                        onChange={(value) => {
                            this.setState({
                                published_at: moment(value).format("YYYY-MM-DD")
                            })
                        }}
                    />
                </Form.Item>







                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input type="number"/>)}
                </Form.Item>



                <Form.Item label="是否显示">
                    <Switch checked={show} onChange={() => {
                        this.setState({
                            show: !show
                        })
                    }} />
                </Form.Item>
                <Form.Item label="激活语言">
                    <Switch checked={disable_language} onChange={() => {
                        this.setState({
                            disable_language: !disable_language
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="内容"
                >
                    <div style={{
                        border: "1px solid #dedede",
                    }}>
                        <BraftEditor
                            value={this.state.editorState}
                            onChange={this.handleChange} />
                    </div>

                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewDocument))
