import React, { Component } from 'react'
import { Row, Dropdown, Menu } from 'antd'
import { connect } from 'react-redux'
import './index.less'
// import {getAdminDetail} from "../../action/authAction";
import { removeToken } from "../../util/function";

class Header extends Component {
  state = {
    userName: '',
  }

  async componentDidMount() {
    let username = localStorage.getItem("username")

    this.setState({
      userName: username
    })
  }

  handleMenuClick = ({ key }) => {
    removeToken()
    localStorage.removeItem("username")
    sessionStorage.clear();
    window.location.href = '/'
  }


  render() {

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">退出登录</Menu.Item>
      </Menu>
    );

    return (
      <>
        <div className='header'>
          <div className='logo-name'>系统后台</div>
          <Row className='header-top'>

            <Dropdown overlay={menu} overlayClassName="languageSel">
              <span>
                {/* <Avatar style={{ backgroundColor: '#87d068' }} icon="user" /> */}
                {/* <span>Username:</span> */}
                <span>{this.state.userName}</span>
              </span>
            </Dropdown>
          </Row>
        </div>
        <div className='breadcrumb'>{this.props.menuName}</div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuName: state.name.menuName
  }
}

export default connect(mapStateToProps)(Header)
