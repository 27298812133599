import React from 'react'
import { withRouter } from 'react-router-dom'
import {Form, Descriptions, Card, Divider, Table, Button, message, Modal, Input, Radio,Popconfirm ,Upload ,Icon ,Tooltip ,DatePicker, } from 'antd';
import { getOrderDetail,putOrder, confirmOrderOrder , StripeRefundOrder , putOrderStatus} from "../../../action/goodAction"

import { GetPartnerStatementDetail ,  createStatement, putPartnerStatementPay,putPartnerStatementSettle} from "../../../action/authAction";

import intl from 'react-intl-universal';
import moment from 'moment';
const { TextArea } = Input;

let currency_symbol = "$"; 

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}




class OrderDetail extends React.Component {
    //constructor(props) {
    //    super(props); //this.state = {}
        state = { 
            data: "", 
            is_show: false, 
            order_gst: 0 ,

            order_gst_inc : 0,//含gst 
            order_gst_excl:0,//不含gst 
            order_gst_total:0,//总计gst
            weight_total:0,

            deliveryVisible:false,//发货管理
            refundvisible:false,
            refundvisibleGoods:false,
            refund_goods_price:0,//部分商品退款的价格
            ItemData: "",
            partner_message:"",
            imageUrl: "",

            delivery_company_name:"",
            delivery_partner_message:"",
            delivery_partner_note:"",
            delivery_no:"",
            delivery_image:"",
            delivery_track:"",
            delivery_at:"",
            refund_message:"",
            refund_message_part:"",
            refund_spu_id:null,
            refund_spu_num:1,

            statementvisible:false,//更改清单状态
            feeVisible:false, 

            total_system_order_price:"",//【订单收入】系统收款：平台给商家的订单费用（包含订单服务费+用户手续费）合计
            total_system_order_charge_money:"",//【服务费支出】系统收款：商家给平台的订单服务费合计
            total_system_order_usercharge_money:"",//【服务费支出】系统收款：商家给平台的用户手续费合计

            total_own_order_charge_money:"",//【服务费支出】商家收款：商家给平台的服务费合计
            total_own_order_usercharge_money:"",//【服务费支出】商家收款：商家给平台的用户手续费合计

            total_system_attach_money:"",//【杂费收入】平台未给商家打的钱
            total_own_attach_money:"",//【杂费支出】商家未支付给平台的钱

            statement_note:"",//管理员备注
            settle_status:0,  
            pay_status:0,


        };
    //}


    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView(); }
        }
      }



    async componentDidMount() { 
        this.getDetail();
    }

    async getDetail(){
        const id = this.props.location.pathname.substring(17)
        let res = await GetPartnerStatementDetail(id);


     

        this.setState({ 
            data: res.data,  

             })
    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };


     priceDeal(value){

        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price = "-" + currency_symbol + value.slice(1)
        } else {
            price = currency_symbol + value
        }

         
        
        return price;
    }


      priceDeal2(value){

        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price =   currency_symbol + value.slice(1)
        }   
        
        return price;
    }





    SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        } 

        if (this.state.logo_file) {
            formData.append("delivery_image", this.state.logo_file)
        }

 
       //特殊处理  
       /*
       if(data.order_status == 'be_delivery'){ 
            formData.append("status", 10)
       }else if(data.order_status == 'wait_delivery'){
            formData.append("status", 11)
       }
       */



       formData.append("status", 11);
       //formData.append("delivery_at", moment(data.delivery_at).format("YYYY-MM-DD") )
        
         
        const id = this.props.location.pathname.substring(13)

         

    };




    render() {
        const { getFieldDecorator } = this.props.form;
        const { 
            data, 
            order_gst ,
            order_gst_excl,
            order_gst_inc,
            order_gst_total,
            weight_total, 
            partner_message, 
            ItemData,
            deliveryVisible, 
            imageUrl ,
            delivery_company_name,
            delivery_partner_message,
            delivery_partner_note,
            delivery_no,
            delivery_image,
            delivery_track,
            delivery_at, 
            refundvisible,
            refundvisibleGoods,
            refund_goods_price,
            refund_message,
            refund_message_part,
            refund_spu_id,
            refund_spu_num,
            statementvisible,
            feeVisible,
            statement_note,
            settle_status,  
            pay_status,
         } = this.state;

         


        let order_status = ""
        let scan_code_type = ""
        switch (data.order_status) {
            case "un_pay":
                order_status = intl.get("home.table.useStatus.un_pay");
                break;
            case "wait_order_receiving":
                order_status = intl.get("home.table.useStatus.wait_order_receiving");
                break;
            case "un_order_receiving":
                order_status = intl.get("home.table.useStatus.un_order_receiving");
                break;
            case "for_consumption":
                order_status = intl.get("home.table.useStatus.for_consumption");
                break;

            case "wait_delivery":
                order_status = "待配送";
                break;

            case "be_delivery":
                order_status = "配送中";
                break;
 

            case "finished":
                order_status = intl.get("home.table.useStatus.finished");
                break;
            case "canceled":
                order_status = intl.get("home.table.useStatus.canceled");
                break;
            case "rejected":
                order_status = intl.get("home.table.useStatus.rejected");
                break;
            case "refunded":
                order_status = intl.get("home.table.useStatus.refunded");
                break;
        }

        switch (data.scan_code_type) {
            case 1:
                scan_code_type = intl.get("nav.setting.seat")
                break;
            case 2:
                scan_code_type = intl.get("nav.goodsManagement.sales_promotion")
                break;
            case 3:
                scan_code_type = intl.get("nav.setting.seat") + "、" + intl.get("nav.goodsManagement.sales_promotion")
                break;
        }


        let spu_discount = data?.orderSpu?.data[0]?.attach_data?.spu_discount
        let oneUserWithdrawLog = data.oneUserWithdrawLog
        let userCoupon = data.userCoupon && data.userCoupon.data[0]
        let coupon_status = ""
     

   

        let orderSource = ""
        if (data?.order_source == 1) {
            orderSource = "店铺"
        } else if (data?.order_source == 2) {
            orderSource = "平台"
        }  

 

 
        return ( 

           
            <div>
                
 

                <Card>

                    <Descriptions title="账单信息">
                        
                         <Descriptions.Item label="账单金额">{this.priceDeal(data?.free)}

                        <Tooltip title={data?.free > 0 ? "待平台付款":"待商家付款" } >
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip>


                         </Descriptions.Item>
   
                         <Descriptions.Item label="结算周期">{data?.statement_date}</Descriptions.Item>


                         <Descriptions.Item label="是否结清">
                             {data?.settle_status == 0 ? '未结清':''}
                             {data?.settle_status == 1 ? '已结清':''}
                             {data?.settle_status == 2 ? '/':''}
                             {data?.settle_status == 3 ? '校验中':''}
                         </Descriptions.Item>

                        {
                            
                         <Descriptions.Item label="生成时间">{data && data.created_at && data.created_at.date.substring(0, 19)}  </Descriptions.Item>
                        }


                        {
                           data?.statement_note &&  

                            <Descriptions.Item label="管理员备注">{data && data.statement_note}  </Descriptions.Item>
                        }    
 

                    </Descriptions>

                     <Divider /> 


                    <Descriptions title='平台订单详情'>
                        {
                             
                            <Descriptions.Item label="系统收款：平台给商家的订单费用">
                                {
                                     
                                     this.priceDeal(data?.total_system_order_price)
                                }
                            </Descriptions.Item>
                        } 
                    </Descriptions>


                     <Divider /> 


                    <Descriptions title='服务费详情'>
                        

                        { 
                            
                            <Descriptions.Item label="系统收款：商家给平台的订单服务费合计">
                                { 
                                    this.priceDeal(data?.total_system_order_charge_money)

                                }
                            </Descriptions.Item>
                        
                        }


                        { 
                            
                            <Descriptions.Item label="系统收款：商家给平台的用户手续费合计">
                                {
                                     
                                     this.priceDeal(data?.total_system_order_usercharge_money)
                                }
                            </Descriptions.Item>
                        
                        }



                        { 
                            
                            <Descriptions.Item label="商家收款：商家给平台的服务费合计">
                                {
                                    

                                      this.priceDeal(data?.total_own_order_charge_money)

                                }
                            </Descriptions.Item>
                        
                        }


                        { 
                            
                            <Descriptions.Item label="商家收款：商家给平台的用户手续费合计">
                                {
                                     
                                      this.priceDeal(data?.total_own_order_usercharge_money)

                                }
                            </Descriptions.Item>
                        
                        } 
                    </Descriptions>

                     <Divider /> 


                    <Descriptions title='其他费用详情'>
                         
 

                         { 
                            
                            <Descriptions.Item label="平台未给商家支付的费用">
                                {
                                     

                                      this.priceDeal(data?.total_system_attach_money)

                                }
                            </Descriptions.Item>
                        
                        }


                         { 
                            
                            <Descriptions.Item label="商家未给平台支付的费用">
                                {
                                     

                                      this.priceDeal(data?.total_own_attach_money)

                                }
                            </Descriptions.Item>
                        
                        }



                         { 
                            
                            <Descriptions.Item label="GST">
                                { 
                                      this.priceDeal(data?.total_own_gst)
                                }
                            </Descriptions.Item>
                        
                        } 

                    </Descriptions>



                     
                   



                    {data?.total_own_gst != 0 &&  <Divider /> }

                    {data?.total_own_gst != 0 &&  <Descriptions title='GST费用'> 

                         { 
                            
                            <Descriptions.Item label="GST">
                                {
                                    
                                     this.priceDeal(data?.total_own_gst)
                                }
                            </Descriptions.Item>
                        
                        }

 

                    </Descriptions>
                    }





                     <Divider />

                    <Descriptions title="付款信息">
                        
 
   
                        <Descriptions.Item label="支付状态">

                        {data?.pay_status === 0 ? "未支付" : ""}
                        {data?.pay_status === 1 ? "已支付" : ""}
                        {data?.pay_status === 2 ? "/" : ""}
                        {data?.pay_status === 3 ? "校验中" : ""}


                        </Descriptions.Item>
                        <Descriptions.Item label="支付时间">{data && data.pay_at && data.pay_at}</Descriptions.Item>

                       
                        
                        
                        
                        <Descriptions.Item label="收款方">{data && data.payee && data.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>
                        

                        {
                            
                            <Descriptions.Item label="付款方式">{data && data.pay_type}</Descriptions.Item>
                        }
                         
                         
                         <Descriptions.Item label="支付凭证">{data && data.stripe_pay_no}</Descriptions.Item>



                        <Descriptions.Item label={<span>支付通道手续费 <Icon type="question-circle" style={{ marginLeft: "5px" }} 
                        onClick={() => {
                            this.setState({
                                feeVisible: true
                            })
                        }} 
                        /> </span>}> 

                            {data.pay_type == 'auto'? currency_symbol + "0.00" : currency_symbol + data?.transaction_fee }
                         </Descriptions.Item>


                          <Descriptions.Item label="实际付款金额"> 
                            {data.pay_type == 'auto'? this.priceDeal2(data?.free) : currency_symbol + data?.free_total } 
                          </Descriptions.Item>
                          



                    </Descriptions>
                  
 
                   
                </Card>



                


                <Modal
                    title="手续费说明"
                    visible={this.state.feeVisible}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            feeVisible: false
                        })
                    }} 
                    >  
                    <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" ,flexDirection: 'column' }} > 
                         <span>1.75% + AUD $0.30 针对国内 (澳大利亚) 银行卡</span>
                         <span>2.9% + AUD $0.30 适用于 International 银行卡</span>
                         <span>Stripe GST 10%</span>
                    </div>  

                 </Modal> 



                <Modal
                    title="更改清单状态"
                    visible={statementvisible}

                    onCancel={() => {
                        this.setState({
                             statementvisible: false, 
                            //refund_message: ""
                        })
                    }}
                    onOk={() => {

 
                         putPartnerStatementPay(data.id, 
                                 { 
                                    settle_status: settle_status , 
                                    pay_status :   pay_status ,
                                    statement_note: statement_note,
                                    pay_type:'auto',
                                    payee:'system',
                                 } ).then(res => {  
                                 
                               setTimeout(() => {
                                 this.setState({
                                    statementvisible: false
                                }, () =>  
                                    this.getDetail() 
                                ) 
                             }, 3000) 
                          }) .catch(error => {
                            this.setState({ 
                                statementvisible: false
                            })
                        })




                        // OrderRefund(data.id, { refund_message }).then(res => {
                              

                        //     setTimeout(() => {
                        //          this.setState({
                        //             refundvisible: false
                        //         }, () =>  
                        //             this.getDetail() 
                        //         ) 
                        //      }, 3000)


                                 
     

                        // }).catch(error => {
                        //     this.setState({ 
                        //         refundvisible: false
                        //     })
                        // })


                    }}
                >
                    

                        <Form.Item
                            label="结算状态"
                        >
                            {getFieldDecorator('settle_status', {
                                rules: [{ required: false }],
                                initialValue:data.settle_status
                            })(<Radio.Group
                                 onChange={(e) => {
                                    this.setState({
                                        settle_status: e.target.value,
                                         
                                    })
                                }} 
                            >


                                <Radio  value={0}>未结清</Radio>
                                <Radio  value={1}>已结清</Radio>
                                <Radio  value={2}>/</Radio>
                                <Radio  value={3}>校验中</Radio>
                            </Radio.Group>)}
                        </Form.Item> 



                    <Form.Item
                            label="支付状态"
                        >
                            {getFieldDecorator('pay_status', {
                                rules: [{ required: false }],
                                initialValue:data.pay_status
                            })(<Radio.Group
                                onChange={(e) => {
                                    this.setState({
                                        pay_status: e.target.value,
                                         
                                    })
                                }} 



                            >
                                <Radio  value={0}>未付款</Radio>
                                <Radio value={1}>已付款</Radio>
                                <Radio  value={2}>/</Radio>
                                <Radio  value={3}>校验中</Radio>
                            </Radio.Group>)}
                        </Form.Item> 




                    <TextArea
                        value={statement_note}
                        placeholder="请输入管理员备注(仅限英文，商家可见)"
                        onChange={(e) => {
                            this.setState({
                                statement_note: e.target.value
                            })
                        }}
                    />


                </Modal>


                {/*  //////////////////////////////////////////// */}

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" , alignItems:"center"}}>

                   {/*
                    <div>
                    *澳洲银行卡第三方支付平台手续费 
                    </div>

                   */} 



                    <div>




                     
                    {/*  
                        <Popconfirm
                            title="确认已付款？"
                            onConfirm={() => {
                                 putPartnerStatementSettle(data.id, { pay_status:1 } ).then(res => {  
                                       this.getDetail()
                                })
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    
                                }}
                            >
                                已付款
                            </Button>
                        </Popconfirm>
                    */}
 


                    {/*  
                        <Popconfirm
                            title="确认已结清？"
                            onConfirm={() => {
                                 putPartnerStatementPay(data.id, { settle_status:1 } ).then(res => {  
                                       this.getDetail() 
                                  }) 
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    
                                }}
                            >
                                已结清
                            </Button>
                        </Popconfirm>

                    */}





                    {  

                    //(data.pay_status == 1 || data.settle_status == 1)  && 

                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => { 
                            this.setState({
                                statementvisible: true, 
                                //refund_message: ""
                            })


                        }}
                    >
                        更改清单状态
                    </Button>
                    }




                    {  // (data.pay_status == 1 || data.settle_status == 1)  && data.free < 0 && 
                    
                        <Popconfirm
                            title="确认下载结算清单？"
                            onConfirm={() => {
                                //  confirmOrderOrder({ "unique_code": data.unique_code }).then(res => {
                                //     this.getDetail() 
                                // })
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    //this.props.history.goBack()
                                }}
                            >
                                下载清单
                            </Button>
                        </Popconfirm>
                     }


                     

                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            返回
                        </Button>
                    </Popconfirm>


                    </div>


                </div>
            </div>
           

        );
    }
}

//export default withRouter(OrderDetail)

export default withRouter(Form.create()(OrderDetail))