import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Input, Switch, Modal } from 'antd';
import { GetConfigSet, UpdateConfigSet, sendUpdatePayParamCodeMail } from "../../action/authAction";

class SysTemSet extends React.Component {

    state = {
        data: "",
        stripe: true,
        paypal: true,
        royal_pay: true,
        visible: false,
        code: ""
    };

    async componentDidMount() {
        let res = await GetConfigSet()
        let allData = res.data.data
        let data = []
        data.payment_type = allData.find(el => el.key === "payment_type").value
        this.setState({
            data: data,
            stripe: data.payment_type.stripe == 1 ? true : false,
            paypal: data.payment_type.paypal == 1 ? true : false,
            royal_pay: data.payment_type.royal_pay == 1 ? true : false
        })
    }

    handleOk = (e) => {
        let { stripe, paypal, royal_pay, code } = this.state
        let data = []
        data.push(
            {
                "key": "payment_type",
                "value": { "stripe": stripe ? 1 : "0", "paypal": paypal ? 1 : "0", "royal_pay": royal_pay ? 1 : "0" }
            })
        data.push({
            "key": "update_pay_param_code",
            "value": code
        })
        UpdateConfigSet(data).then(res => {
            this.setState({
                visible: false,
            });
        })
    };

    handleGetPayParamCodeMail = () => {
        sendUpdatePayParamCodeMail().then(res => {
            this.setState({
                visible: true,
                code: ""
            });
        })
    }


    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { stripe, paypal, royal_pay, code } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                <Form.Item
                    label="stripe"
                >
                    <Switch checked={stripe} onChange={() => {
                        if (!stripe) {
                            this.setState({
                                stripe: !stripe,
                                royal_pay: false,
                                paypal: false
                            })
                        } else {
                            this.setState({
                                stripe: !stripe,
                                paypal: true,
                            })
                        }

                    }} />
                </Form.Item>


                {/*
                <Form.Item
                    label="royal pay"
                >
                    <Switch checked={royal_pay} onChange={() => {
                        if (!royal_pay) {
                            this.setState({
                                royal_pay: !royal_pay,
                                stripe: false,
                                paypal: false
                            })
                        } else {
                            this.setState({
                                royal_pay: !royal_pay,
                                paypal: true,
                            })
                        }
                    }} />
                </Form.Item>
                */}




                <Form.Item
                    label="paypal"
                >
                    <Switch checked={paypal} onChange={() => {
                        if (!paypal) {
                            this.setState({
                                paypal: !paypal,
                                stripe: false,
                                royal_pay: false
                            })
                        } else {
                            this.setState({
                                paypal: !paypal,
                                royal_pay: true
                            })
                        }
                    }} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary"
                        onClick={() => this.handleGetPayParamCodeMail()}
                    >
                        提交
                    </Button>
                </Form.Item>
                <Modal
                    title="填入验证码"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ width: "80px", textAlign: "right" }}>验证码：</span>
                        <Input
                            value={code}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    code: e.target.value
                                })
                            }} />
                    </div>

                </Modal>
            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
