import React from 'react'
import { withRouter } from 'react-router-dom'

import { Form, Input, Button, Select, Upload, Icon, message } from 'antd';
import { GetWithdrawDetail, DisposeWithdraw } from "../../action/authAction";


function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

class WithdrawLogDetail extends React.Component {

    state = {
        data: "",
        disposeType: "",
        imageUrl: "",
        goodImage: "",
        loading: false,
        dispose: [{
            id: 1,
            name: "同意"
        }, {
            id: 3,
            name: "拒绝"
        }]
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(19)
        let res = await GetWithdrawDetail(id)
        this.setState({
            data: res.data,
        })
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_finish = this.state.disposeType
                this.SubmitData(values)
            }



        });
    };

    async SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        }

        const id = this.props.location.pathname.substring(19)
        if (data.is_finish === 1) {
            if (this.state.goodImage) {
                formData.append("ticket_file", this.state.goodImage)
                await DisposeWithdraw(formData, id)
            } else {
                message.error("请上传票据信息")
            }

        } else {
            await DisposeWithdraw(formData, id)
        }



    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    goodImage: img
                }),
            );
        }
    };


    handleChooseType = (e) => {
        this.setState({
            disposeType: e
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        const { money, is_finish, name, bank_name, bank_number, abn, bsb, dispose_message, ticket_image, order_ids } = this.state.data;
        const { imageUrl, dispose, loading } = this.state
        const { Option } = Select;

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">

                <div style={{ height: 30 }}></div>
                <Form.Item
                    label="提现人名称"
                >
                    <span style={{ marginLeft: "10px" }}>{name}</span>
                </Form.Item>


                <Form.Item
                    label="银行名称"
                >
                    <span style={{ marginLeft: "10px" }}>{bank_name}</span>
                </Form.Item>


                <Form.Item label="银行帐号">
                    <span style={{ marginLeft: "10px" }}>{bank_number}</span>
                </Form.Item>


                <Form.Item
                    label={<span>abn</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{abn}</span>
                </Form.Item>


                <Form.Item
                    label={<span>bsb</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{bsb}</span>
                </Form.Item>

                <Form.Item
                    label="提现总额"
                >
                    <span style={{ marginLeft: "10px" }}>{money}</span>
                </Form.Item>
                <Form.Item
                    label="提现包含的订单编号"
                >
                    <div style={{ maxWidth: "800px" }}>
                        <span style={{ marginLeft: "10px", wordBreak: "break-word" }}>{order_ids}</span>
                    </div>
                </Form.Item>

                {
                    is_finish !== 1 ?
                        <div>
                            <Form.Item
                                label="处理留言"
                            >
                                {getFieldDecorator('dispose_message', {
                                    // rules: [{required: true, message: '请输入商品名称', whitespace: true}],  
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="处理类型"
                            >
                                <Select defaultValue="请选择" style={{ width: "100%" }} onChange={this.handleChooseType}>
                                    {
                                        dispose.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={<span>票据图片</span>}
                            >
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={this.handleUpload}
                                >
                                    {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </Form.Item>
                        </div> :
                        <div>
                            <Form.Item
                                label={<span>处理留言</span>}
                            >
                                <span style={{ marginLeft: "10px" }}>{dispose_message}</span>
                            </Form.Item>
                            <Form.Item
                                label={<span>处理结果</span>}
                            >
                                <span style={{ marginLeft: "10px" }}>
                                    {is_finish === 1 ? "已处理" :
                                        <span>{is_finish === 3 ? "拒绝" : "审核中"}</span>
                                    }
                                </span>
                            </Form.Item>
                            <Form.Item
                                label={<span>票据图片</span>}
                            >
                                {
                                    ticket_image ? <img src={ticket_image} alt="avatar" style={{ width: '300px' }} /> : <span>无</span>
                                }

                            </Form.Item>
                        </div>
                }


            </Form>
        );
    }
}

export default withRouter(Form.create()(WithdrawLogDetail))
