import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Modal, Switch } from 'antd';
import { AddAdmin, GetAdminList, putAdmin, deleteAdmin } from "../../action/authAction";

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing, 
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps} >
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class AdminList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingKey: '',
            visible: false,
            name: "",
            password: "",
            passwordVisible: false,
            itemId: "",
            email: "",
            newPassword: "",
            confirmPassword: "",
            is_supervisor: false,
            passwordError: false,
            newPasswordError: false,
            confirmPasswordError: false,
        };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                editable: false,
                align: "center",
            },
            {
                title: '管理员名称',
                dataIndex: 'name',
                width: '10%',
                editable: true,
                align: "center",
            },

            {
                title: '邮箱',
                dataIndex: 'email',
                width: '30%',
                editable: true,
                align: "center",
            },
            {
                title: "状态",
                width: '5%',
                align: "center",
                render: (record) => {
                    if (record.name === "admin") {
                        return
                    }
                    return (
                        <Switch checked={record.status === 1} onChange={() => {
                            const { id, status } = record
                            let data = { "name": record.name, "status": status === 1 ? "0" : "1" }
                            putAdmin(data, id).then(res => {
                                this.getData()
                            })
                        }} />
                    )
                }
            },
            {
                title: "超级管理",
                width: '5%',
                align: "center",
                render: (record) => {
                    if (record.name === "admin") {
                        return
                    }
                    return (
                        <Switch checked={record.is_supervisor === 1} onChange={() => {
                            const { id, is_supervisor } = record
                            let data = { "name": record.name, "is_supervisor": is_supervisor === 1 ? "0" : "1" }
                            putAdmin(data, id).then(res => {
                                this.getData()
                            })
                        }} />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    if (record.name === "admin") {
                        return
                    }
                    return editable ? (
                        <span>
                            <EditableContext.Consumer>
                                {form => (
                                    <Button
                                        onClick={() => this.save(form, record)}
                                        style={{ marginRight: 8 }}
                                    >
                                        保存
                                    </Button>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm title="确定取消?" onConfirm={() => this.cancel(record.key)}>
                                <Button>取消</Button>
                            </Popconfirm>
                        </span>
                    ) : (
                        <div className='deleteView'>
                            <Button style={{ marginRight: "5px" }} onClick={() => this.showPasswordModal(record)}>
                                修改密码
                            </Button>
                            <Button style={{ marginRight: 15 }} disabled={editingKey !== ''} onClick={() => this.edit(record)}>
                                编辑
                            </Button>
                            <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                                <Button type="danger" ghost>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        this.getData()
    }

    getData = () => {
        GetAdminList().then(res => {
            this.setState({ data: res.data.data })
        })

    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.SubmitData(newData, newData[index])


            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    async SubmitData(newData, item) {
        const { id, name, email } = item
        let data = { name, email }
        await putAdmin(data, id)
        this.setState({ data: newData, editingKey: '' });
    }


    edit(key) {
        this.setState({ editingKey: key.id });
    }

    async handleDelete(data) {
        await deleteAdmin(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    showModal = () => {
        this.setState({
            visible: true,
            name: "",
            password: "",
            email: "",
            passwordError: false,
        });
    };


    handleOk = e => {
        const { name, password, email, is_supervisor, passwordError } = this.state
        if (!passwordError) {
            AddAdmin({ name, password, email, is_supervisor: is_supervisor ? "1" : "0" }).then(res => {
                this.getData()
                this.setState({
                    visible: false,
                });
            })
        }
    };
    handlePasswordOk = e => {
        const { newPassword, confirmPassword, itemId, newPasswordError, confirmPasswordError } = this.state
        if (newPassword !== confirmPassword) {
            this.setState({
                isError: true
            })
        } else {
            this.setState({
                isError: false
            })
            if (newPassword && confirmPassword && !newPasswordError && !confirmPasswordError) {
                putAdmin({ "password": newPassword }, itemId).then(res => {
                    this.setState({
                        passwordVisible: false,
                    });
                })
            }



        }

    };

    handleCancel = e => {
        this.setState({
            visible: false,
            passwordVisible: false,
        });
    };

    showPasswordModal = (item) => {
        this.setState({
            passwordVisible: true,
            itemId: item.id,
            newPassword: "",
            confirmPassword: "",
            newPasswordError: false,
            confirmPasswordError: false,
        });
    };


    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });
        let { is_supervisor, name, password, email, confirmPassword, newPassword } = this.state
        return (
            <div>
                <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "flex-end", alignItems: "center", backgroundColor: '#fff' }}>
                    <Button type="primary" onClick={() => this.showModal()} style={{ marginRight: "20px" }}>
                        <PlusOutlined /> 新建
                    </Button>
                </div>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                        rowKey='id'
                        components={components}
                        // bordered
                        dataSource={this.state.data}
                        columns={columns}
                        className="waperBox"
                        rowClassName="editable-row"
                        pagination={{
                            // onChange: this.cancel,
                            hideOnSinglePage: true
                        }}
                    />
                </EditableContext.Provider>
                <Modal
                    title="新建管理员"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "23px" }}>
                        <span style={{ width: "120px", textAlign: "right" }}>管理员姓名：</span>
                        <Input
                            value={name}
                            onChange={(e) => {
                                e.persist()
                                let value = e.target.value.replace(/[^\w\.\/]/ig, '')
                                if (value) {
                                    this.setState({
                                        name: value
                                    })
                                } else {
                                    this.setState({ name: "" })
                                }
                            }} />
                    </div>
                    <div style={{ height: "55px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ width: "120px", textAlign: "right" }}>密码：</span>
                            <Input
                                value={password}
                                onChange={(e) => {
                                    e.persist()
                                    //let value = e.target.value.replace(/\W/g, '')
                                    let value = e.target.value

                                    // if (value.length > 16 || value.length < 6) {
                                    //     this.setState({
                                    //         passwordError: true
                                    //     })
                                    // } else {
                                    //     this.setState({
                                    //         passwordError: false
                                    //     })
                                    // }

                                    

                                    //密码正则校验
                                    var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                    var result=patt.exec(value);    
                                    if(result == null){  
                                        this.setState({
                                            passwordError: true
                                        }) 
                                    }else {
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                passwordError: true
                                            })

                                        } else {
                                            this.setState({
                                                passwordError: false
                                            })
                                        }   
                                    }




                                    this.setState({
                                        password: value
                                    })

                                }} />
                        </div>
                        {
                            this.state.passwordError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                            </div>
                        }
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "23px" }}>
                        <span style={{ width: "120px", textAlign: "right" }}>邮箱：</span>
                        <Input
                            value={email}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    email: e.target.value
                                })
                            }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ width: "120px", textAlign: "right" }}>超级管理：</span>
                        <Switch checked={is_supervisor} onChange={() => {
                            this.setState({
                                is_supervisor: !is_supervisor
                            })
                        }} />
                    </div>
                </Modal>
                <Modal
                    title="修改密码"
                    visible={this.state.passwordVisible}
                    onOk={this.handlePasswordOk}
                    onCancel={this.handleCancel}
                >
                    <div style={{ height: "60px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>密码:</span>
                            <Input
                                value={newPassword}
                                onChange={(e) => {
                                    e.persist()
                                    //let value = e.target.value.replace(/\W/g, '')
                                    let value = e.target.value;

                                    // if (value.length > 16 || value.length < 6) {
                                    //     this.setState({
                                    //         newPasswordError: true
                                    //     })
                                    // } else {
                                    //     this.setState({
                                    //         newPasswordError: false
                                    //     })
                                    // }

                                    //密码正则校验
                                    var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                    var result=patt.exec(value);    
                                    if(result == null){  
                                        this.setState({
                                            newPasswordError: true
                                        }) 
                                    }else {
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                newPasswordError: true
                                            })

                                        } else {
                                            this.setState({
                                                newPasswordError: false
                                            })
                                        }   
                                    }






                                    this.setState({
                                        newPassword: value
                                    })
                                }} />
                        </div>
                        {
                            this.state.newPasswordError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                            </div>
                        }
                    </div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>确认密码:</span>
                            <Input
                                value={confirmPassword}
                                onChange={(e) => {
                                    e.persist()
                                    //let value = e.target.value.replace(/\W/g, '')

                                    let value = e.target.value


                                    // if (value.length > 16 || value.length < 6) {
                                    //     this.setState({
                                    //         confirmPasswordError: true
                                    //     })
                                    // } else {
                                    //     this.setState({
                                    //         confirmPasswordError: false
                                    //     })
                                    // }


                                    //密码正则校验
                                    var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                    var result=patt.exec(value);    
                                    if(result == null){
                                        this.setState({
                                            confirmPasswordError: true
                                        })
                                    }else{
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                confirmPasswordError: true
                                            })
                                        } else {
                                            this.setState({
                                                confirmPasswordError: false
                                            })
                                        }
                                    } 




                                    this.setState({
                                        confirmPassword: value
                                    })
                                }} />
                        </div>
                        {
                            this.state.isError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>两次密码不一致</span>
                            </div>
                        }
                        {
                            this.state.confirmPasswordError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                            </div>
                        }

                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(AdminList))

