import { Axios } from "../util/request";
import { API } from "../util/api";
import { getLangType } from '../util/function';


// 新建菜单
export const AddMenu = (data) => {
    return Axios.ajax({
        url: API.Menu,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取菜单列表
export const getMenuList = () => {
    return Axios.ajax({
        url: API.Menu,
        method: "get"
    })
}

// 删除菜单
export const deleteMenu = (id) => {
    return Axios.ajax({
        url: API.Menu + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}
// 获取广告详情
export const getMenuDeatil = (id) => {
    return Axios.ajax({
        url: `${API.Menu}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改菜单
export const UpdateMenu = (data, id) => {
    return Axios.ajax({
        url: API.Menu + "/" + id,
        method: "post",
        data
    })
}




// 新建打折方式
export const AddDiscount = (data) => {
    return Axios.ajax({
        url: API.partnerServiceTypeDiscount,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取打折方式列表
export const getDiscountList = () => {
    return Axios.ajax({
        url: `${API.partnerServiceTypeDiscount}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 删除打折方式
export const deleteDiscount = (id) => {
    return Axios.ajax({
        url: API.partnerServiceTypeDiscount + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

// 获取打折方式详情
export const getDiscountDeatil = (id) => {
    return Axios.ajax({
        url: `${API.partnerServiceTypeDiscount}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 修改打折方式
export const UpdateDiscount = (data, id) => {
    return Axios.ajax({
        url: API.partnerServiceTypeDiscount + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

// 获取导航列表
export const getPartnerNavList = () => {
    return Axios.ajax({
        url: `${API.partnerNav}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取商家打印机列表
export const getPrinterList = (page, url, language_id) => {
    return Axios.ajax({
        url: API.printer + "?page=" + page + url + "&request_language_id=" + language_id,
        method: "get",
    })
}



// 新建商家打印机
export const AddPrinter = (data) => {
    return Axios.ajax({
        url: API.printer,
        method: "post",
        tips: "提交成功",
        data
    })
}


// 修改商家打印机
export const UpdatePrinter = (id, data) => {
    return Axios.ajax({
        url: API.printer + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}
// 删除商家打印机
export const deletePrinter = (id) => {
    return Axios.ajax({
        url: API.printer + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

// 获取打印机状态
export const getPrinterStatus = (sn) => {
    return Axios.ajax({
        url: API.queryPrinterStatus + sn,
        method: "get"
    })
}

// 获取打印机类别详情
export const getPrinterDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.printer + "/" + id + "?request_language_id=" + language_id,
        method: "get"
    })
}


// 获取打印机类别列表
export const getPrinterCategoryList = (language_id) => {
    return Axios.ajax({
        url: API.printerCategory + "?request_language_id=" + language_id,
        method: "get"
    })
}
// 获取打印机类别详情
export const getPrinterCategoryDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.printerCategory + "/" + id + "?request_language_id=" + language_id,
        method: "get"
    })
}

// 新建打印机类别
export const AddPrinterCategory = (data) => {
    return Axios.ajax({
        url: API.printerCategory,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 修改打印机类别
export const UpdatePrinterCategory = (id, data) => {
    return Axios.ajax({
        url: API.printerCategory + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}
// 删除打印机类别
export const deletePrinterCategory = (id) => {
    return Axios.ajax({
        url: API.printerCategory + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}