import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, Modal, Input, InputNumber, Popconfirm, Select } from 'antd';
import { getPackageFeeLog, NewPackageFeeLog, UpdatePackageFeeLog, deletePackageFeeLog, getPackageFeeLogDetail } from "../../../action/shopAction";
import { getLanguageList } from "../../../action/authAction"

class AnnualFeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packageLogData: [],
            packageLogMeta: "",
            visible: false,
            id: "",
            type: ["monthly", "annual"],
            itemData: "",
            incomeType: [{ name: "收入", type: "+" }, { name: "支出", type: "-" }],
            payType: [ { name: "未支付", type: 0 },{ name: "已支付", type:  1  }],
            url: "&income_type=" + encodeURIComponent('+'),
            // date: ""
        };

        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '2%',
            },
            {
                title: "名称",
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: "费用",
                width: '10%',
                align: "center",
                render: (record) => {
                    return <span>${record.free}</span>
                }
            },


            {
                title: "付款状态",
                width: '10%',
                align: "center",
                dataIndex: "pay_status",
                render: (record) => {
                    return record  == "1" ?  
                    <span style={{color:"green"}} >已付款</span> :  <span style={{color:'red'}} >未付款</span>
                    
                }
            },

            



            // {
            //     title: "类型",
            //     dataIndex: 'type',
            //     width: '10%',
            //     align: "center",
            // },
            // {
            //     title: "备注",
            //     dataIndex: 'explain',
            //     width: '15%',
            //     align: "center",
            // },
            // {
            //     title: "店铺名称",
            //     dataIndex: ["partner", "title"],
            //     width: '10%',
            //     align: "center",
            // },
            {
                title: '创建时间',
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '15%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => this.edit(record)}>
                                编辑
                            </Button>
                            <Popconfirm title="确定删除" onConfirm={() => this.handleDelete(record.id)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },
        ];

    }



    async componentDidMount() {

        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData,
            language_id: LanguageData[1].id
        }, () => {
            this.GetPackageFeeLogData(1)
        })
    }


    async GetPackageFeeLogData(page) {
        const shop_id = this.props.location.pathname.substring(15)
        let res = await getPackageFeeLog(page, shop_id, this.state.language_id, this.state.url)
        this.setState({
            packageLogData: res.data.data,
            packageLogMeta: res.data.meta.pagination
        })
    }

    handleGetPackageFeeLogDetail = (language_id) => {
        let { id } = this.state
        getPackageFeeLogDetail(id, language_id).then(res => {
            this.setState({
                visible: true,
                itemData: res.data
            });
        })
    }

    edit(record) {
        let { LanguageData } = this.state
        getPackageFeeLogDetail(record.id, LanguageData[1].id).then(res => {
            this.setState({
                visible: true,
                id: res.data.id,
                itemData: res.data
            });
        })

    }

    handleCancel = () => {
        this.setState({
            visible: false,
            itemData: ''
        });
    };
    async handleDelete(id) {
        await deletePackageFeeLog(id)
        this.GetPackageFeeLogData(1)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { itemData } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const id = this.props.location.pathname.substring(15)
                values.type = "annual"
                values.request_language_id = values.request_language_id
                if (itemData && itemData.id) {
                    //修改-注意参数
                    UpdatePackageFeeLog(itemData.id, values).then(res => {
                        this.GetPackageFeeLogData(1)
                        this.setState({
                            visible: false,
                            itemData: ''
                        });
                    })
                } else {
                    //新增
                    values.partner_id = id
                    NewPackageFeeLog(values).then(res => {
                        this.GetPackageFeeLogData(1)
                        this.setState({
                            visible: false,
                            itemData: ''
                        });
                    })
                }
            }
        });
    };



    render() {
        const { packageLogData, packageLogMeta, itemData, LanguageData, visible, incomeType,payType, url } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div>
                        {
                            LanguageData ? <Select
                                style={{ width: "100px" }}
                                defaultValue={LanguageData[1].name}
                                onChange={(e) => {
                                    this.setState({
                                        language_id: e
                                    }, () => this.GetPackageFeeLogData(1))

                                }}
                            >
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select> : <div />
                        }
                        <Button
                            style={{ margin: "0px 5px", backgroundColor: url === "&income_type=" + encodeURIComponent('+') ? "#1DA57A" : "", color: url === "&income_type=" + encodeURIComponent('+') ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&income_type=" + encodeURIComponent('+')
                                }, () => {
                                    this.GetPackageFeeLogData(1)
                                })
                            }}>
                            收入
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&income_type=-" ? "#1DA57A" : "", color: url === "&income_type=-" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&income_type=-"
                                }, () => {
                                    this.GetPackageFeeLogData(1)
                                })
                            }}>
                            支出
                        </Button>
                    </div>
                    <Button type="primary"
                        onClick={() => this.setState({ visible: true })}
                    >
                        新建
                    </Button>
                </div>


                <Table
                    rowKey='id'
                    className="waperBox"
                    dataSource={packageLogData}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: packageLogMeta.per_page,
                        total: packageLogMeta.total
                    }}
                    onChange={(e) => {
                        this.GetPackageFeeLogData(e.current)
                    }}
                />
                {
                    visible && <Modal
                        destroyOnClose
                        title={itemData.id ? '编辑费用' : '新建费用'}
                        visible={visible}
                        onOk={this.handleSubmit}
                        onCancel={this.handleCancel}
                    >
                        <Form onSubmit={this.handleSubmit}  {...formItemLayout}>
                            <Form.Item
                                label="选择录入的语言"
                            >
                                {getFieldDecorator('request_language_id', {
                                    rules: [{ required: true, message: '请选择语言!' }],
                                    initialValue: LanguageData[1].id
                                })(
                                    <Select
                                        onChange={(e) => {
                                            this.handleGetPackageFeeLogDetail(e)
                                        }}
                                    >
                                        {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            {
                                itemData.id ?
                                    <Form.Item
                                        label="收入类型"
                                    >
                                        {getFieldDecorator('income_type', {
                                            rules: [{ required: true, message: '请选择收入类型!' }],
                                            initialValue: itemData.income_type
                                        })(
                                            <Select
                                            >
                                                {incomeType && incomeType.map(el => <Select.Option key={el.type} value={el.type}>{el.name}</Select.Option>)}
                                            </Select>
                                        )}
                                    </Form.Item> :
                                    <div>
                                        <Form.Item
                                            label="收入类型"
                                        >
                                            {getFieldDecorator('income_type', {
                                                rules: [{ required: true, message: '请选择收入类型!' }],
                                                initialValue: incomeType[0].type
                                            })(
                                                <Select>
                                                    {incomeType && incomeType.map(el => <Select.Option key={el.type} value={el.type}>{el.name}</Select.Option>)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>
                            }

                            <Form.Item
                                label="名称"
                            >
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入名称!' }],
                                    initialValue: itemData && itemData.name
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="金额"
                            >
                                {getFieldDecorator('free', {
                                    rules: [{ required: true, message: '请输入金额!' }],
                                    initialValue: itemData && itemData.free
                                })(<Input prefix="$" />)}
                            </Form.Item>



                            {
                                itemData.id ?
                                    <Form.Item
                                        label="是否已经支付"
                                    >
                                        {getFieldDecorator('pay_status', {
                                            rules: [{ required: true, message: '请选择' }],
                                            initialValue: itemData.pay_status
                                        })(
                                            <Select
                                            >
                                                {payType && payType.map(el => <Select.Option key={el.type} value={el.type}>{el.name}</Select.Option>)}
                                            </Select>
                                        )}
                                    </Form.Item> :
                                    <div>
                                        <Form.Item
                                            label="是否已经支付"
                                        >
                                            {getFieldDecorator('pay_status', {
                                                rules: [{ required: true, message: '请选择' }],
                                                initialValue: payType[0].type
                                            })(
                                                <Select>
                                                    {payType && payType.map(el => <Select.Option key={el.type} value={el.type}>{el.name}</Select.Option>)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>
                            }






                            <Form.Item
                                label="备注"
                            >
                                {getFieldDecorator('explain', {
                                    rules: [{ required: false }],
                                    initialValue: itemData && itemData.explain
                                })(<Input />)}
                            </Form.Item>
                        </Form>
                    </Modal>
                }

            </div>
        );

    }
}

export default withRouter(Form.create()(AnnualFeeList))

