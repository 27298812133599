import { type } from "../util/varibales";

export const SetMenuName = (name) => {
    return (dispatch) => {
        dispatch({
            type: type.SWITCH_MENU,
            payload: name
        });
    }
}
