import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Modal,InputNumber,message } from 'antd';
import { getUserPointsExchangeOptionDeletedList,addPointsExchangeOption,putPointsExchangeOption,  delPointsExchangeOption,recoverPointsExchangeOption,  delPlugIn,GetConfigSet } from "../../action/authAction";

class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: []  ,
            pointsVisible:false ,
            points:0, 
            money:0,
            points_value:0,
            editData:[],
            system_points_exchange_set:0  
        };


        this.columns = [
             
             
             {
                title: 'ID',
                dataIndex: 'id',
                width: '10%',
                render: (record) => {
                    return <span>{record}</span>
                }
            } ,{
                title: '兑换',
                dataIndex: 'money',
                width: '10%',
                render: (record) => {
                    return <span>${record}</span>
                }
            } ,

            {
                title: '所需金币',
                //dataIndex: 'points_value',
                width: '15%',
                render: (record) => {
                    return <span>${record.money * this.state.system_points_exchange_set }</span>
                }
            }, 
            

            {
                title: '操作人', 
                width: '15%',
                render: (record) => {
                    return "partner"
                }
             }, 



              {
                title: '日期', 
                width: '15%',
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
             }, 


              {
                title: '操作',
                dataIndex: 'operation',
                width: '8%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div  > 
                            <Popconfirm title="确定恢复?" onConfirm={() => this.handleDelete(record)}>
                                <Button type="danger" ghost>
                                    恢复 
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
                },

 

           

        ];
    }

    async componentDidMount() {
        this.getUserPointsExchangeOptionDeletedList();

        let configData = await GetConfigSet()
          this.setState({ 
            system_points_exchange_set:configData.data.data.find(el => el.key === "system_points_exchange_set").value, 
        })


    }


    async getUserPointsExchangeOptionDeletedList() {
        let res = await getUserPointsExchangeOptionDeletedList()
        this.setState({ data: res.data.data })
    }




    async handleDelete(data) {
        await recoverPointsExchangeOption(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {

         const { editData,data,points,pointsVisible,money,points_value } = this.state
        return (

        <div>
                 

            <Table
                rowKey='id'
                // bordered
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }}
                className="waperBox"
            />


            <Modal
                title="请输入金币兑换项"
                visible={this.state.pointsVisible}
                onOk={() => { 

                    //编辑
                    if(editData.id){
                        if (money && points_value) {
                            putPointsExchangeOption({ money,points_value },editData.id).then(res => {
                                this.setState({
                                    money: "",
                                    points_value:"",
                                    pointsVisible: false
                                }, () => {
                                    this.getUserPointsExchangeOptionDeletedList()
                                })
                            })
                        } else {
                            message.error("请先填入要充值的金币")
                        } 
                    }else{ 
                        //添加
                        if (money && points_value) {
                            addPointsExchangeOption({ money,points_value }).then(res => {
                                this.setState({
                                    money: "",
                                    points_value:"",
                                    pointsVisible: false
                                }, () => {
                                    this.getUserPointsExchangeOptionDeletedList()
                                })
                            })
                        } else {
                            message.error("请先填入要充值的金币")
                        }

                    }
                    

                }}
                onCancel={() => {
                    this.setState({
                        //points: "",
                        pointsVisible: false
                    })
                }}


                >


                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} >
                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>兑换金额:</span>
                    <InputNumber
                        max={10000} 
                        formatter={value => `$${value}`}
                        parser={value => value.replace("$", '')}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            this.setState({
                                money: e
                            })
                        }} /> 
                </div>

                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} >
                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>所需金币:</span>
                    <InputNumber
                        max={10000} 
                        formatter={value => `${value}`}
                        parser={value => value.replace("$", '')}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            this.setState({
                                points_value: e
                            })
                        }} /> 
                </div>


             </Modal>




            </div>

        );
    }
}

export default withRouter(Form.create()(PlugInList))

