import React from "react";
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Table, Input, InputNumber, Popconfirm, Form, Button, Switch } from 'antd';
import { getSalesAreaSubList, getSalesAreaList, deleteSalesAreaSub, editSalesAreaSub } from "../../action/shopAction";

class SalesAreaSubList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: "Id",
                dataIndex: 'id',
                width: '5%',
                editable: false,
                align: "center",
            },


            {
                title: "销售大区",
                dataIndex: 'salesArea[name]',
                width: '5%',
                align: "center",
            },

            {
                title: "小区名称",
                dataIndex: 'name',
                width: '5%',
                align: "center",
            },


            {
                title: "Link",
                dataIndex: 'eatmoon_url',
                width: '15%',
                align: "center",
            },
            {
                title: "二维码",
                width: '5%',
                align: "center",
                render: (record) => {
                    return <img src={record.eatmoon_url_two_code} style={{ width: "100px" }} />
                }
            },
            {
                title: "EM显示",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_use === 1} onChange={() => {
                            const { id, is_use } = record
                            let data = { "is_use": is_use === 1 ? "0" : "1" }
                            editSalesAreaSub(id, data).then(res => {
                                this.HandleGetData()
                            })
                        }} />
                    )
                }
            },
            {
                title: "排序",
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },

            {
                title: "操作",
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    if (record.is_default !== 1) {
                        return <div className='deleteView'>
                            <Button style={{ marginRight: "10px" }} onClick={() => {
                                this.props.history.push("/EditSalesAreaSub/" + record.id)
                            }}>
                                编辑
                            </Button>
                            <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    删除
                                </Button>
                            </Popconfirm>
                        </div>

                    }

                },
            },

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getSalesAreaSubList("");
        let InitData = res.data.data
        for (let i in InitData) {
            let res = await axios.get(InitData[i].eatmoon_url_two_code);
            InitData[i].eatmoon_url_two_code = res.data
        }
        this.setState({ data: InitData })
    }
    async HandleGetImg(url) {
        let res = await axios.get(url);
        return res.data

    }

    async handleDelete(data) {
        await deleteSalesAreaSub(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginTop: "-50px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/NewSalesAreaSub")}
                    >
                        新建
                    </Button>
                </div>
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(SalesAreaSubList))

