import React from 'react'
import 'braft-editor/dist/index.css'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Upload, message, Icon, Popconfirm, DatePicker } from 'antd';
import { putHoliday, getHolidayDetail } from "../../action/shopAction";
import moment from 'moment';

class EditHoliday extends React.Component {

    state = {
        data: "",
        date: ""
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(13)
        let data = await getHolidayDetail(id)
        this.setState({
            data: data.data,
            date: data.data.start_at.date.substring(0, 10).replace(/\//g, "-")
        })

    }




    SubmitData(data) {
        const id = this.props.location.pathname.substring(13)
        data.start_at = this.state.date.split("-").reverse().join("-")
        putHoliday(data, id).then(res => {
            this.props.history.goBack()
        })
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const { data, date } = this.state;
        if (data) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                    <Form.Item
                        label="名称"
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true }],
                            initialValue: data.name
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item
                        label="详情"
                    >
                        {getFieldDecorator('detail', {
                            rules: [{ required: true }],
                            initialValue: data.detail
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="州"
                    >
                        {getFieldDecorator('state', {
                            rules: [{ required: true }],
                            initialValue: data.state
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="开始日期"
                    >
                        <DatePicker
                            defaultValue={moment(date, "DD-MM-YYYY")}
                            // defaultValue={[moment(data.start_at, "DD-MM-YYYY"), ""]}
                            format='DD-MM-YYYY'
                            onChange={(value) => {
                                this.setState({
                                    date: moment(value).format("YYYY-MM-DD")
                                })
                            }}
                        />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>

                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>

            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(EditHoliday))
