import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider } from 'antd';
import { myOrderDetail } from "../../action/authAction";

const statusParam = {
    0: '未付款',
    1: '已付款',
    2: '退款',
    3: '终止(服务意外终止)',
    4: '完成',
}

const serviceStatus = {
    'setup': '开通服务',
    'year': '年服务',
    'month': '月服务'
}

class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: {} };
    }

    async componentDidMount() {
        const id = this.props.location.search.substring(1);
        if (id) {
            let res = await myOrderDetail(id);
            this.setState({ data: res.data })
        }

    }


    render() {
        const { data } = this.state;

        return (
            <Card>
                <Descriptions title="订单详情">
                    <Descriptions.Item label="id">{data && data.id}</Descriptions.Item>
                    <Descriptions.Item label="订单编号">{data && data.order_sn}</Descriptions.Item>
                    <Descriptions.Item label="订单状态">{statusParam[data && data.status] || '未付款'}</Descriptions.Item>
                    <Descriptions.Item label="付款方式">{data && data.pay_type}</Descriptions.Item>
                    <Descriptions.Item label="价格">${data && data.price}</Descriptions.Item>
                    <Descriptions.Item label="实际支付">${data && data.real_price}</Descriptions.Item>
                    <Descriptions.Item label="使用余额">${data && data.use_money}</Descriptions.Item>
                    <Descriptions.Item label="是否试用订单">{data && data.is_trial === 1 ? "试用" : "正式"}</Descriptions.Item>
                    <Descriptions.Item label="订单是否过期">{data && data.is_expire === 1 ? "过期" : "未过期"}</Descriptions.Item>
                    <Descriptions.Item label="支付时间">{data && data.pay_at}</Descriptions.Item>
                    <Descriptions.Item label="备注">{data && data.remark}</Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="订阅信息">
                    <Descriptions.Item label="订阅id">{data && data.partnerSubscription && data.partnerSubscription.id}</Descriptions.Item>
                    <Descriptions.Item label="价格">${data && data.partnerSubscription && data.partnerSubscription.price}</Descriptions.Item>
                    <Descriptions.Item label="类型">{data && data.partnerSubscription && data.partnerSubscription.prod_type === 'package' ? 'package套餐' : data && data.partnerSubscription && data.partnerSubscription.prod_type === 'addon' ? 'addon插件' : ''}</Descriptions.Item>
                    <Descriptions.Item label="状态">{data && data.partnerSubscription && data.partnerSubscription.status === 0 ? '已过期或终止' : data && data.partnerSubscription && data.partnerSubscription.status === 1 ? '服务中' : ''}</Descriptions.Item>
                    <Descriptions.Item label="服务类型">{serviceStatus[data && data.partnerSubscription && data.partnerSubscription.service_type]}</Descriptions.Item>
                    <Descriptions.Item label="服务起始时间">{data && data.partnerSubscription && data.partnerSubscription.service_start_at}</Descriptions.Item>
                    <Descriptions.Item label="服务终止时间">{data && data.partnerSubscription && data.partnerSubscription.service_end_at}</Descriptions.Item>
                </Descriptions>
                <Divider />
            </Card>

        );
    }
}

export default withRouter(PlugInList)

