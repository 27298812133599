import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Upload, message, Icon } from 'antd';
import { EditRegion, getRegionDetail } from "../../action/shopAction";

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === "image/svg+xml";
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/SVG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

class EditRegionComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            currency_state: true,
            area_code_state: true,
            state: true,
            imageUrl: "",
            goodImage: "",
            loading: false,
        }
    }

    async componentDidMount() {
        const id = this.props.location.pathname.substring(12)
        let res = await getRegionDetail(id)
        this.setState({
            data: res.data,
            currency_state: res.data.currency_state === 1,
            area_code_state: res.data.area_code_state === 1,
            state: res.data.state === 1,
            imageUrl: res.data.flag
        })
    }




    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.currency_state = this.state.currency_state ? "1" : "0"
            values.area_code_state = this.state.area_code_state ? "1" : "0"
            values.state = this.state.state ? "1" : "0"
            if (!err) {
                if (values.name) {
                    this.SubmitData(values)
                }
            }

        });
    };

    async SubmitData(data) {
        const id = this.props.location.pathname.substring(12)
        let formData = new FormData();
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (this.state.goodImage) {
            formData.append("flag_file", this.state.goodImage)
        }
        await EditRegion(id, formData).then(res => {
            this.props.history.push("/regionList")
        })

    }


    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            getBase64(info.file.originFileObj, (imageUrl, img) => {
                this.setState({
                    imageUrl,
                    loading: false,
                    goodImage: img
                })
            }

            );
        }
    };

    render() {
        let { area_code_state, currency_state, state, imageUrl, loading, data } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label="中文名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: "请输入名称" }],
                        initialValue: data && data.name
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label="英文名称"
                >
                    {getFieldDecorator('full_name', {
                        rules: [{ required: true, message: "请输入全称" }],
                        initialValue: data && data.full_name
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label="英文缩写"
                >
                    {getFieldDecorator('short_name', {
                        rules: [{ required: true, message: "请输入简称" }],
                        initialValue: data && data.short_name
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label="国旗"
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={this.handleUpload}
                    >
                        {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>



                <Form.Item
                    label="货币"
                >
                    {getFieldDecorator('currency', {
                        rules: [{ required: true, message: '请输入货币名称!' }],
                        initialValue: data && data.currency
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label="货币符号"
                >
                    {getFieldDecorator('currency_symbol', {
                        rules: [{ required: true, message: '请输入货币符号!' }],
                        initialValue: data && data.currency_symbol
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="货币开关">
                    <Switch checked={currency_state} onChange={() => {
                        this.setState({
                            currency_state: !currency_state
                        })
                    }} />
                </Form.Item>


                <Form.Item
                    label="国际区号"
                >
                    {getFieldDecorator('area_code', {
                        rules: [{ required: true, message: '请输入国际区号!' }],
                        initialValue: data && data.area_code
                    })(<Input />)}
                </Form.Item>

                <Form.Item label="国际区号开关">
                    <Switch checked={area_code_state} onChange={() => {
                        this.setState({
                            area_code_state: !area_code_state
                        })
                    }} />
                </Form.Item>
                <Form.Item label="状态">
                    <Switch checked={state} onChange={() => {
                        this.setState({
                            state: !state
                        })
                    }} />
                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditRegionComponent))
