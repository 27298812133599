import React from 'react'
import { Card, Col, Row } from 'antd';
import { QueryStatistics } from "../../action/authAction";


class SubscriptionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: "" };
    }

    async componentDidMount() {
        let res = await QueryStatistics();
        this.setState({ data: res.data })

    }


    render() {
        const { data } = this.state;

        return (
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card title="用户数" bordered={false} style={{ textAlign: "center" }}>
                            <h3>{data.user_sum}</h3>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="商家数" bordered={false} style={{ textAlign: "center" }}>
                            <h3>{data.partner_sum}</h3>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="订单数" bordered={false} style={{ textAlign: "center" }}>
                            <h3>{data.order_sum}</h3>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="商品数" bordered={false} style={{ textAlign: "center" }}>
                            <h3>{data.spu_sum}</h3>
                        </Card>
                    </Col>

                     <Col span={6}>
                        <Card title="优惠券数" bordered={false} style={{ textAlign: "center" ,marginTop:"20px"}}>
                            <h3>{data.coupon}</h3>
                        </Card>
                    </Col>


                     <Col span={6}>
                        <Card title="Deal数" bordered={false} style={{ textAlign: "center" ,marginTop:"20px"}}>
                            <h3>0</h3>
                        </Card>
                    </Col>


                    <Col span={6}>
                        <Card title="合作伙伴数" bordered={false} style={{ textAlign: "center" ,marginTop:"20px"}}>
                            <h3>0</h3>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card title="广告数" bordered={false} style={{ textAlign: "center" ,marginTop:"20px"}}>
                            <h3>0</h3>
                        </Card>
                    </Col>


                </Row>
            </div>

        );
    }
}

export default SubscriptionDetail

